/*
Marketing Landing page
*/
// .not-front.node-type-marketing-page #main-wrapper::after {
//   content: none;
// }

.node-type-marketing-page {
  #main-wrapper {
    @include angled-section(#26ac62, -160px, 1.75deg);

    &::after {
      height: 200px;
    }
  }

  .work-with-us-form {
    @include angled-top-section(#f8f8f8, calc(100% - 200px), 1.75deg);
  }

  #main {
    padding-top: 0;
    padding-bottom: 0;
  }

  .node-marketing-page {
    padding: 0;
  }
}

/*
Marketing Landing page banner
*/
.marketing-banner {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  position: relative;
  padding: 260px 0 180px;
  color: #fff;
  text-align: center;

  h1 {
    font-size: 44px;
    line-height: 62px;
    margin-bottom: 80px;
    text-transform: none;
  }
}

.marketing-banner--cta {
  a {
    font-weight: 800;
    display: inline-block;
    color: #fff;
    border: 3px solid #fff;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    padding: 14px 40px;
  }
}

/*
Paragraph: Marketing Content
*/
.paragraphs-item-marketing-content {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  padding: 80px 15px 130px;
  @include angled-top-section(mos-color('white'), 0px);

  h2 {
    text-align: center;

    &+p {
      text-align: center;
      max-width: 780px;
      margin: 0 auto;
    }
  }

  h3 {
    text-transform: none;
    margin-bottom: 30px;
  }

  h4 {
    font-size: 18px;
    line-height: 28px;
    text-transform: initial;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
    color: #626262;
  }

  p strong {
    font-size: 16px;
    line-height: 26px;
    color: #262626;
  }

  ul {
    padding-left: 20px;
    color: #626262;

    li {
      padding-bottom: 4px;
    }
  }

  .container {
    max-width: 880px;
  }

  // green section
  &.has-bg-green {
    @include angled-top-section(mos-color('green'), calc(100% - 200px));
    background: mos-color('green');
    color: #fff;
    text-align: center;

    .container {
      max-width: 780px;
      margin: 0 auto;
    }

    h4 {
      font-weight: 400;
    }
  }

  &.has-bg-white {
    @include angled-top-section(#fff, calc(100% - 200px));
    background: #fff;
  }

  &.has-bg-gray {
    @include angled-top-section(#f8f8f8, calc(100% - 200px));
    background: #f8f8f8;
  }

  &.has-bg-green,
  &.has-bg-white,
  &.has-bg-gray {
    &::before {
      // calculate the height of angled section
      // we know 2 angles and one length
      // Math.tan((1.75)*(Math.PI/180)) * window.width()
      height: calc(0.030552763298588862 * 100vw) !important;
      top: 0px !important;
      transform: translateY(-50%) skewY(1.75deg);
    }
  }

  .flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .flex-item-3 {
      flex: 0 0 100%;

      br {
        display: none;
      }

      @media (min-width: 992px) {
        flex: 0 0 31%;

        br {
          display: inline;
        }
      }
    }
  }
}

// logo grid
.node-marketing-page {

  .paragraphs-item-logo-grid {
    padding: 80px 15px 130px;
    text-align: center;
    @include angled-top-section(#fff, calc(100% - 200px));

    &::before {
      // calculate the height of angled section
      // we know 2 angles and one length
      // Math.tan((1.75)*(Math.PI/180)) * window.width()
      height: calc(0.030552763298588862 * 100vw) !important;
      top: 0px !important;
      transform: translateY(-50%) skewY(1.75deg);
    }

    .field-name-field-title {
      margin-bottom: 10px;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
      font-size: 44px;
      font-weight: 700;
    }

    .field-name-field-body {
      max-width: 640px;
      margin: 0 auto;
    }
  }

  .paragraphs-item-logo-grid .field-name-field-grid {
    max-width: 1160px;
    margin: 70px auto 0;
    padding-bottom: 120px;

    &>.field-items {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;

      &>.field-item {
        align-self: center;
        flex: 0 25%;

        @media (max-width: 1000px) {
          flex: 0 33%;
        }

        @media (max-width: 768px) {
          flex: 0 50%;
        }

        img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }
    }

    .paragraphs-item-logo {
      position: relative;
      overflow: hidden;
      height: 120px;
      margin: 0;
      padding: 20px;

      .field-type-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  }
}
