// Full Nodes
.node-article,
.node-news {
  .field-name-field-post-body-paragraphs {
    > .field-items {
      padding: 25px 0 0;
      > .field-item {
        padding: 50px 0;
      }
    }
    img {
      max-width: 100%;
      height: auto !important;
    }
  }
  .post-date {
    color: #626262;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 25px;
  }
}

// Teaser Nodes
.node-article.node-teaser,
.node-news.node-teaser {
  padding: 50px 0;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  h2 {
    margin-bottom: 10px;
  }
  img {
    max-width: 100%;
    height: auto !important;
  }
  .links {
    display: block;
    margin-top: 30px;
    li {
      margin: 0;
      padding: 0;
    }
    a {
      @extend .border-button;
    }
  }
}

@include mobile {
  .node-article.node-teaser,
  .node-news.node-teaser {
    display: block;
    .field-name-field-banner-image {
      max-height: 200px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      div {
        width: 100%;
        display: block
      }
      img {
        width: 100%;
        height: auto !important;
      }
    }
  }
}


// Paragraph specific.
.paragraphs-item-quote {
  text-align: center;
  .field-name-field-quote {
    padding: 24px 50px 0;
    line-height: 1.1;
    p {
      @extend h2;
    }
  }
  .field-name-field-eyebrow,
  .field-name-field-attribution {
    font-size: 16px;
    font-weight: 700;
  }
}
.paragraphs-item-content-image {
  .row {
    display: flex;
    align-items: center;
  }
}

.paragraphs-item-image {
  text-align: center;
  .field-name-field-attribution {
    padding: 25px 0 0;
  }
}

// Fields that apply to more than one paragraph.
.field-name-field-eyebrow,
.field-name-field-attribution {
  color: #626262;
}
.field-name-field-attribution {
  font-size: 12px;
}
.field-name-field-tags {
  .field-label {
    padding: 0 10px 0 0;
  }
  margin: 25px 0 0;
  .field-items {
    display: flex;
    .field-item {
      padding: 0 10px 0 0;
      font-weight: 700;
    }
  }
}

// Additional article elements.
#comments {
  clear: both;
  padding: 50px 0;
}

// Taxonomy Pages
.page-taxonomy-term {
  .feed-icon {
    display: none;
  }
}
