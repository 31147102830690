/* -------------------- layout/footer -------------------- */


#above-footer {
  background: mos-color('green');
  color: #207846;
  color: darken(mos-color('green'), 13%);
  font-size: 12px;
  padding: 100px 0;
}


@include tablet {}

@include mobile {
  #above-footer {
    padding: 50px 0 100px;
  }

  .col-xs-6 {
    margin-top: 50px;

    &:last-child {
      padding-left: 0;
    }
  }
}



#above-footer-inner {
  .col-md-4 {
    padding-left: 40px;
  }

  .col-md-3 {
    padding-left: 0;
  }
}


@include tablet {
  #above-footer-inner {
    .col-md-3 {
      margin-top: 40px;
      padding-left: 40px;
    }
  }
}

@include screen(992px, 1024px) {
  #above-footer-inner {
    .col-md-3 {
      margin-top: 0;
    }
  }
}

@include mobile {

  .col-md-3,
  .col-md-4 {
    padding-left: 15px;
  }

  #above-footer-inner {
    .col-md-4 {
      padding-left: 30px;
    }

    .col-md-3 {
      padding-left: 30px;
    }

    .col-md-3:last-child {
      padding-left: 0px;
    }
  }
}



// logo

.footer-logo {
  .col-md-10 {
    padding-left: 40px;
  }

  img {
    margin-bottom: 50px;
    width: 162px;
  }

  .logo-underline {
    background: #22854f;
    height: 1px;
    margin-bottom: 40px;
    width: 52px;
  }
}


@include tablet {}

@include mobile {
  .col-md-10 {
    padding-left: 15px;
  }
}



// mos blurb

.footer-about-us-blurb .content {
  color: mos-color('white');
  max-width: 250px;

  p {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4em;
  }

  small {
    color: #207846;
    font-size: 12px;
  }
}



// sitemap

.footer-sitemap,
.footer-contact-info {

  h2 {
    @include mos-font('serif', 400);
    font-size: 14px;
    margin: 0 0 20px;
    text-transform: none;
  }

  ul {
    @include mos-font('sans', 800);
    margin: 0;
    padding: 0;
    text-transform: uppercase;

    li.leaf {
      list-style: none;
      list-style-image: none;
      margin: 6px 0 0;
      padding: 0;

      a,
      a:visited {
        color: #207846;
        font-size: 16px;
        text-decoration: none;

        &:hover {
          color: darken(#207846, 5%);
          text-decoration: none;
        }
      }
    }
  }

}


@include tablet {}

@include mobile {

  .footer-contact-info {
    p {
      font-size: 12px;
    }
  }

  .footer-sitemap,
  .footer-contact-info {

    h2 {
      font-size: 12px;
    }

    ul li.leaf {
      margin-top: 10px;

      a,
      a:visited {
        font-size: 12px;
      }
    }

  }

}


.footer-contact-info .content {

  a,
  a:visited {
    color: inherit;

    &:hover {
      color: darken(#207846, 5%);
      text-decoration: none;
    }
  }
}

// partners
#partners {
  display: flex;
  flex-wrap: wrap;
  float: none;

  >div {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .divider {
    background: #1f6c42;
    opacity: 0.5;
    height: 1px;
    margin: 10px auto;
    top: 0;
    width: 26px;
  }

  .acquia img {
    width: 66px;
  }

  .amazon img {
    width: 48px;
  }

  .google img {
    width: 50px;
  }

  .microsoft img {
    width: 74px;
  }

  .pantheon img {
    width: 100px;
  }

  .partner {
    font-family: arial;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
  }
}

@include mobile {
  #partners {
    >div {
      width: 50%;

      &:nth-child(n+3) {
        padding-top: 30px;
      }
    }
  }
}


// footer
#footer {
  background: #249a59;
  color: #1f6f42;
  padding: 100px 0;

  .mos-copyright {
    margin-top: 100px;

    p {
      font-size: 10px;
    }
  }
}

@include tablet {
  #footer {
    .mos-copyright {
      margin-top: 60px;
    }

  }
}

@include mobile {
  #footer {
    .mos-copyright {
      margin-top: 60px;
    }

  }
}
