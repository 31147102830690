.interior-page-banner.nid-246,
.interior-page-banner.nid-261 {
  max-height: 785px;

  h1 {
    color: #fff;
    font-size: 44px;
    font-weight: 600;
    text-transform: none;
    line-height: 40px;
  }

  .banner-copy {
    max-width: 640px;
    margin-top: 14px;
    padding: 0;
    color: #fff;

    p {
      font-size: 14px;
      line-height: 26px;
    }
  }

  @media screen and (max-width: $mobile) {
    max-height: 82vh;

    h1 {
      margin-left: 40px;
      margin-right: 40px;
      font-size: 30px;
      line-height: 38px;
    }

    .banner-copy {
      margin-left: 25px;
      margin-right: 25px;
    }

    .banner-copy p {
      font-size: 13px;
      line-height: 23px;
    }
  }
}

.page-node-246 {
  color: #000; // For some reason for this page black is black

  #main-wrapper {
    background: #fff;
    @include angled-section(#26ac62, -160px);
  }

  #main {
    padding-top: 80px;
  }

  .interior-page-banner {
    p {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: $tablet) {
    &.not-front .node {
      padding: 0; // I DON'T KNOW WHY THIS IS A PADDING IS SET HERE
    }
  }
}

#paragraph-id-6 {
  @include angled-top-section(#f8f8f8);
  padding-top: 180px;
  padding-bottom: 100px;

  &:before {
    height: 120px;
    bottom: calc(100% - 80px);
  }

  @media screen and (max-width: $tablet + 1) {
    padding-top: 160px;
  }
}

#paragraph-id-21 {
  margin-top: 10px;
  padding-bottom: 80px;

  &:after {
    background: none !important;
  }

  h2 {
    font-size: 44px;
    font-weight: 700;
    text-transform: none;
    margin-top: 80px;

    @media (max-width: 768px) {
      font-size: 32px;
    }
  }
}

#paragraph-id-131 {
  @include angled-top-section(#f8f8f8);
  padding-top: 80px;
  padding-bottom: 120px;
  background: #f8f8f8;

  @media screen and (max-width: $mobile) {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  &:before {
    height: 120px;
    bottom: calc(100% - 80px);
  }

  &:after {
    content: none;
  }
}

#paragraph-id-351 {
  @include angled-top-section(#f8f8f8);
  background: #f8f8f8;
  padding: 40px 0 180px 0;
  margin-top: 200px;

  &::after {
    content: none;
  }
}

#paragraph-id-356 {
  @include angled-top-section(#fff);
  padding: 100px 0 180px 0;

  &::before {
    height: 120px;
    bottom: calc(100% - 80px);
  }

  &::after {
    content: none;
  }
}

.webform-client-form-266 {
  .webform-component-radios {
    .form-radios > .form-item {
      clear: both;
      display: block;
      overflow: hidden;
      margin-bottom: 30px;

      input {
        width: 30px;
        margin-top: 0;
      }

      label {
        position: relative !important;
        margin-left: 0;
        padding-left: 0;
      }
    }
  }

  .submit-wrapper {
    position: relative;
    max-width: 150px;
    background-color: #888;
    margin-top: 10px;
  }

  .form-submit {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 8px 18px;
    border: 0;
    background-color: #888;
    color: #fff;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica,
      arial, sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    z-index: 1;
  }
}

.not-front.page-node-261 {
  #client-logo-section {
    margin-top: -240px;
  }

  #client-logo-list-grid {
    margin-top: 0;
  }

  #client-logo-list-header {
    display: block;

    h1 {
      font-size: 44px;
      text-transform: capitalize;
    }

    h3 {
      font-size: 14px;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica,
        arial, sans-serif;
      color: #000;
    }
  }

  #client-logo-list-header:after {
    transform: skewY(-1.75deg);
  }

  #header:after {
    background: #fff !important;
  }

  #page-wrapper,
  &.parachute-active #page-wrapper {
    background: #fff !important;
  }
}

@media only screen and (min-width: 768px) {
  .not-front.page-node-261 #client-logo-section {
    margin-top: -360px;
  }
}

.interior-page-banner {
  .interior-page-banner-title {
    top: 48%;
  }

  a.work-with-us {
    color: #000;
    font-size: 14px;
    height: 50px;
    border-radius: 25px;
    display: block;
    padding: 12px 38px;
    margin: 0 auto;
    max-width: 230px;
    text-align: left;
    background: url(../images/icon_heartbeat_white@2x.png) no-repeat top 16px
      right 40px #fff;
    background-size: 34px 17px;
  }
}

@media screen and (max-width: $tablet) {
  .interior-page-banner.nid-246 .banner-copy {
    p {
      font-size: 12px;
      line-height: 22px;
      margin-bottom: 18px;
    }

    a.work-with-us {
      height: 44px;
      border-radius: 22px;
      font-size: 13px;
      max-width: 220px;
      background-position: top 13px right 40px;
    }
  }
}
