/* -------------------- layout/content -------------------- */

.front .node p {
  font-size: 24px;
  text-align: left;
}

.front .paragraphs-item-logo-grid p {
  font-size: 14px;
  text-align: center;
}

.front #header:after {
  background: #fff !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 60px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.front {
  .home-page-banner-message {
    position: relative;
    h1 {
      text-transform: none;
      font-size: 42px;
      font-weight: 700;
      line-height: 62px;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: fadeIn;
      animation-delay: 300ms;
      opacity: 0;
      a {
        color: #fff;
        text-decoration: underline;
      }
    }
    .home-banner-ctas {
      position: relative;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: fadeIn;
      animation-delay: 300ms;
      opacity: 0;
      padding-top: 30px;
    }
    a {
      @include mos-font("sans", 700);
      font-size: 18px;
      color: #fff;
      padding: 10px 30px;
      text-decoration: underline;

      &:hover {
        color: #2acc71;
      }
    }
  }
  #block-block-26 {
    margin-top: -300px;
    margin-bottom: -300px;
  }
  .homepage-feature {
    position: relative;
    padding: 0;
    transition: transform 1.5s ease-in-out, opacity 600ms ease-in-out;
    max-width: 1020px;
    margin: 0 auto;
    .col-sm-12,
    .col-sm-6 {
      padding: 0;
    }
    .health-wrapper,
    .life-wrapper {
      padding: 10% 5%;
      height: 510px;
      opacity: 0;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      transition: transform 600ms ease-in-out, opacity 600ms ease-in-out;
      &:before {
        content: "";
        display: block;
        height: 510px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: url("/sites/all/themes/motionstrand/assets/images/feature-overlay-blue.png");
        opacity: 0;
        pointer-events: none;
        transition: all 800ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      h3,
      p,
      a {
        color: #fff !important;
        text-align: center;
        text-transform: none !important;
        font-weight: 700 !important;
        transition: all 800ms cubic-bezier(0.23, 1, 0.32, 1);
      }
      h3 {
        margin-bottom: 30px;
        font-size: 44px;
        line-height: 54px;
        position: relative;
        top: 60px;
      }
      p {
        font-family: $sans;
        font-weight: 500;
        font-size: 14px;
      }
      .desc {
        padding: 0 20px;
        position: relative;
        text-align: center;
        line-height: 26px;
        margin-top: 15px;
        top: 40px;
        width: 100%;
        opacity: 0;
      }
      a {
        position: absolute;
        left: 50%;
        bottom: 23%;
        transform: translateX(-50%);
        img {
          position: relative;
          max-width: 34px;
          margin-left: 17px;
        }
      }
      &.active {
        &:before {
          opacity: 1;
        }
        h3 {
          top: 10px;
        }
        .desc {
          top: 0;
          opacity: 1;
        }
      }
    }
    .life-wrapper {
      &:before {
        background-size: 510px 510px;
        background: url("/sites/all/themes/motionstrand/assets/images/feature-overlay-green.png");
      }
    }
    .health-wrapper {
      top: 100px;
      background: url("/sites/all/themes/motionstrand/assets/images/mos-health-feature.png");
      padding: 8% 5%;
      img {
        top: -3px;
      }
    }
    .life-wrapper {
      background: url("/sites/all/themes/motionstrand/assets/images/mos-life-feature.png");
      top: 140px;
      @include triggerDelay(3);
      img {
        transform: rotate(180deg);
        top: -2px;
      }
    }
    &.in-view {
      .health-wrapper,
      .life-wrapper {
        opacity: 1;
      }
      .health-wrapper {
        transform: translateY(-100px);
      }
      .life-wrapper {
        transform: translateY(-155px);
      }
    }
  }
}
@media screen and (max-width: $tablet) {
  .front {
    .home-page-banner-message {
      h1 {
        padding-left: 40px;
        padding-right: 40px;
        font-size: 24px;
        line-height: 32px;
        br {
          display: none;
        }
      }
      a {
        display: block;
        text-align: center;
        font-size: 16px;
      }
      .home-banner-ctas {
        padding-top: 20px;
      }
    }
    #block-block-26 {
      margin-top: 0;
      margin-bottom: 0;
    }
    .homepage-feature {
      .health-wrapper,
      .life-wrapper {
        opacity: 1;
        height: 375px;
        background-size: 375px;
        &.active:before {
          opacity: 1;
          height: 375px;
          background-size: 375px;
        }
      }
      .health-wrapper {
        transform: translateY(-130px);
      }
      .life-wrapper {
        transform: translateY(-182px);
      }
    }
  }
}

.front .home-page-banner-message {
  p {
    max-width: 640px;
    margin-top: 14px;
    padding: 0;
    color: #fff;
    font-size: 14px;
    line-height: 26px;
    margin: 0 auto 30px;
  }
  a {
    color: #000;
    font-size: 14px;
    height: 50px;
    border-radius: 25px;
    display: block;
    padding: 12px 38px;
    margin: 0 auto;
    max-width: 230px;
    text-align: left;
    background: url(../images/icon_heartbeat_white@2x.png) no-repeat top 16px
      right 40px #fff;
    background-size: 34px 17px;
    line-height: 26px;
    text-decoration: none;

    &:hover {
      color: #000;
    }
  }
}

@media (max-width: $tablet) {
  .front .home-page-banner-message {
    a {
      height: 44px;
      border-radius: 22px;
      font-size: 13px;
      max-width: 220px;
      background-position: top 13px right 40px;
    }
  }
}

.node-type-job-posting {
  .field-name-body {
    p {
      line-height: 1.8em;
    }
    h2 {
      text-align: center;
      margin-bottom: 80px;
    }
    h3 {
      font-size: 16px;
      text-transform: none;
      font-family: "Roboto Slab", georgia, serif;
    }
    p,
    li {
      color: #626262;
    }
    ul li {
      font-size: 16px;
      padding: 10px 0;
    }
  }
}
.page-node-done {
  #header:after {
    bottom: -267px !important;
  }
  #main-wrapper {
    &:after {
      background: mos-color("green") !important;
    }
  }
}
.page-node-88,
.node-type-job-posting {
  #header {
    @include angled-section(#f8f8f8 !important, -260px);
  }
  #main-wrapper {
    background: #f8f8f8;
    @include angled-section(mos-color("white") !important, -275px !important);
    .field-name-body .available-opps {
      font-weight: bold;
      text-align: center;
      margin-top: 80px;
      margin-bottom: 100px;
      cursor: pointer;
      p:after {
        margin: auto;
        position: relative;
        top: 50px;
        content: "";
        display: block;
        width: 50px;
        height: 2px;
        background: mos-color("black");
      }
    }
  }
  #below-content {
    .region-below-content {
      background: #e8e8e8;
    }
    .view-job-posting-forms form {
      max-width: 530px;
      margin: auto;
      .form-item.webform-component-textfield,
      .form-item.webform-component-email {
        background-color: #dedede;
        max-width: 530px;
        margin: 13px auto;
        padding: 0;
        input {
          padding: 13px 25px 13px 25px;
          margin: 0;
          font-size: 16px;
          transition: border 0.5s;
        }
        label {
          font-size: 16px;
          font-weight: normal;
          color: #888;
          padding: 20px 25px 0 25px;
          cursor: text;
        }
      }

      textarea {
        padding-top: 0;
        border: 0;
        margin: 0;
      }
      .form-actions {
        clear: both;
        border-top: 1px solid #c8c8c8;
        input {
          color: #fff;
          font-size: 1em;
          margin: 20px auto;
          width: 50%;
          background: #c8c8c8;
          border: none;
          border-radius: 3px;
          display: block;
        }
      }
      .file-field-wrapper {
        padding: 0;
        .messages.error {
          display: none;
        }
      }
      #edit-submitted-resume-cv-ajax-wrapper {
        padding-right: 13px;
      }
      #edit-submitted-cover-letter-ajax-wrapper {
        padding-left: 13px;
      }
      #edit-submitted-resume-cv-ajax-wrapper,
      #edit-submitted-cover-letter-ajax-wrapper {
        margin: auto;
        .webform-component-file {
          #edit-submitted-resume-cv-remove-button,
          #edit-submitted-cover-letter-remove-button {
            border-bottom: none;
            font-size: 10px;
          }
          label {
            cursor: pointer;
            color: #fff;
            text-align: center;
            width: 100%;
            padding: 20px 40px;
            border-radius: 3px;
            background: #fe4535;
            position: relative !important;
            transition: all 0.25s;
            &:hover {
              background: #d8262e;
            }
            &:before {
              content: "";
              background: url(../images/paperclip.png) no-repeat;
              width: 16px;
              height: 16px;
              display: inline-block;
              position: relative;
              top: 3px;
              left: -10px;
              transition: all 0.5s;
            }
            &:after {
              content: "";
              background: url(../images/checkmark.icon.png) no-repeat;
              width: 16px;
              height: 16px;
              display: inline-block;
              position: relative;
              top: 3px;
              left: -10px;
              opacity: 0;
              transition: all 0.5s;
            }
            &.error {
              font-size: 0;
              background: #d8262e;
              &:before {
                background: url(../images/error.icon.x.png) no-repeat;
                width: 22px;
                height: 22px;
                left: initial;
              }
              &:after {
                background: none;
                width: 0;
              }
            }
            &.file-selected {
              background: mos-color("green");
              &:before {
                left: 0;
                opacity: 0;
              }
              &:after {
                left: 10px;
                opacity: 1;
              }
            }
            span {
              color: #fff;
            }
          }
          .form-managed-file {
            display: none;
          }
        }
      }
    }
    #block-views-job-list-job-list-block {
      margin-top: 100px;
      padding-bottom: 90px;
      background: mos-color("white");
      @include angled-section(
        mos-color("black") !important,
        -375px !important,
        1.75deg
      );
      .view-content {
        max-width: 840px;
        margin: auto;
        text-align: center;
        .col-sm-6 {
          margin-bottom: 100px;
          &:nth-child(1) {
            padding-right: 50px;
          }
          &:nth-child(2) {
            padding-left: 50px;
          }
        }
        p {
          line-height: 1.6em;
          color: #626262;
        }
        a {
          color: #626262;
          font-weight: bold;
          font-size: 16px;
          display: block;
          margin-top: 40px;
          transition: color 0.25s;
          &:hover {
            color: mos-color("green");
          }
        }
      }
    }
    #block-views-job-posting-forms-block-1,
    #block-block-8 {
      margin-top: 100px;
      padding-bottom: 90px;
      background: mos-color("white");
      @include angled-section(
        mos-color("black") !important,
        -375px !important,
        1.75deg
      );
      h2 {
        margin-top: 215px;
        font-size: 60px;
        color: mos-color("black");
        text-align: center;
        cursor: pointer;
        .dots {
          margin-top: -18px;
          .dot {
            display: inline-block;
            margin: 0;
            width: 16px;
            height: 5px;
            background: mos-color("black");
            transition: all 0.15s;
            &.active {
              margin: 0 3px;
              width: 6px;
              height: 6px;
              background: mos-color("black");
              border-radius: 50%;
            }
          }
        }
        &:hover {
          .dots .dot {
            margin: 0 3px;
            width: 6px;
            height: 6px;
            background: mos-color("black");
            border-radius: 50%;
          }
        }
      }
      //Open the form by default by commenting out the display:none, below
      .webform-client-form {
        position: relative;
        overflow: hidden;
        display: none;
        .desc-text {
          margin-top: 20px;
          margin-bottom: 30px;
          display: block;
          text-align: center;
          .remove {
            color: mos-color("green");
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    #block-block-8 {
      color: mos-color("white");
      min-height: 310px;
      background: mos-color("black");
      @include angled-section(mos-color("green") !important, -275px !important);
      h2 {
        margin-top: 250px;
        a {
          color: mos-color("white");
        }
        .dots .dot {
          background: mos-color("white");
        }
        &:hover {
          .dots .dot {
            background: mos-color("white");
          }
        }
      }
    }
  }
}

.page-node-88,
.page-node-97 {
  .field-name-body .field-item {
    .col-sm-6.left-col {
      padding-right: 50px;
    }
    .col-sm-6.right-col {
      padding-left: 50px;
    }
  }
}

.join-our-team {
  clear: both;
  padding: 115px 0 390px;
  background: #171717;
  @include angled-section(mos-color("green") !important);
  @include angled-top-section(mos-color("black"));
  .content {
    position: relative;
    z-index: 1;
  }
  h1 {
    text-align: center;
    cursor: pointer;
    a {
      color: mos-color("white");
      &:after {
        margin: auto;
        position: relative;
        top: 30px;
        content: "";
        display: block;
        width: 26px;
        height: 3px;
        background: mos-color("white");
      }
    }
  }
}

@include mobile {
  .page-node-88,
  .page-node-97 {
    .field-name-body .field-item {
      .col-sm-6.left-col,
      .col-sm-6.right-col {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .view-job-list .col-sm-6 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

.page-node-54,
.page-node-85 {
  .field-name-body p {
    max-width: 760px;
    margin: 0 auto 10px auto;
  }
}

@include tablet {
}

@include mobile {
  .node-type-job-posting {
    #block-views-job-posting-forms-block-1 h2 {
      font-size: 30px !important;
      .dots {
        margin-top: -5px !important;
      }
    }
    #block-block-8 h2 {
      font-size: 30px !important;
      padding: 0 30px;
      .dots {
        margin-top: -5px !important;
      }
    }
  }
  .node-type-job-posting #below-content .view-job-posting-forms form {
    padding: 0 15px;
  }
  .node-type-job-posting
    #below-content
    .view-job-posting-forms
    form
    #edit-submitted-resume-cv-ajax-wrapper {
    padding-right: 0;
  }
  .node-type-job-posting
    #below-content
    .view-job-posting-forms
    form
    #edit-submitted-cover-letter-ajax-wrapper {
    padding-left: 0;
  }
  .front .node p {
    font-size: 18px;
    line-height: 24px;
  }
}

#main {
  padding-top: 100px;
  padding-bottom: 90px;
}

@include tablet {
}

@include mobile {
  #main {
    padding-top: 70px;
    padding-bottom: 20px;

    .col-md-10 {
      padding: 0 30px;
    }
  }
}

.not-front h2 {
  margin-bottom: 24px;
}

@include tablet {
}

@include mobile {
  .not-front .node {
    padding: 0 15px;
  }
}

// thank you page

.page-node-86 {
  #main {
    h1 {
      margin-top: 0;
    }
  }
}

// Work with us Webform Block
.work-with-us-form {
  background: #f8f8f8;
  padding-top: 10px;
  padding-bottom: 180px;
  @include angled-top-section(#f8f8f8);
  > h2 {
    margin-bottom: 60px;
    font-size: 44px;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    position: relative;
    z-index: 1;
    @media screen and (max-width: $tablet + 1) {
      font-size: 28px;
    }
  }
  .content {
    position: relative;
    z-index: 1;
  }
  form > div {
    display: flex;
    flex-wrap: wrap;
    max-width: 680px;
    margin: 0 auto;
    @media screen and (max-width: $tablet + 1) {
      display: block;
    }
  }
  .form-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .form-item input,
  .form-item textarea {
    color: #888;
    padding: 8px 20px;
    font-family: $sans;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    background: #eee;
    border: none;
    border-radius: 0;
    display: block;
    &::placeholder {
      color: #888;
    }
  }
  .form-item input,
  .form-item select {
    height: 50px;
  }
  .form-item textarea {
    height: 70px;
    resize: none;
  }
  .resizable-textarea .grippie {
    border: none;
  }
  .webform-component--body {
    max-width: 640px;
    margin: 0 auto;
    font-family: $sans;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    p {
      font-size: 14px;
    }
    @media screen and (max-width: $tablet + 1) {
      p {
        font-size: 13px;
        line-height: 23px;
      }
    }
    .required {
      color: #888;
      font-size: 12px;
    }
    .required span {
      color: #ee352e;
    }
  }
  .webform-component-select {
    select {
      color: #888;
      font-family: $sans;
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      margin: 0;
      padding: 8px 20px 13px 20px;
      line-height: 36px;
      background-color: #eee;
      background-image: url(../images/icon-chevron-down.svg);
      background-position: 97% center;
      background-repeat: no-repeat;
      background-size: 15px;
      border: none;
      border-radius: 3px;
      display: block;
      appearance: none;
    }
    select:focus {
      outline: none;
    }
    select::-ms-expand {
      display: none; /* hide the default arrow in ie10 and ie11 */
    }
    option {
      font-weight: 500;
    }
  }
  .webform-component {
    flex: 1 100%;
    padding: 0 10px;
    &.webform-component--body {
      padding: 0 30px;
    }
    @media screen and (max-width: $tablet + 1) {
      padding: 0 30px;
    }
  }
  .form-item.flex-40 {
    flex: 1 40%;
  }
  .form-item.flex-50 {
    flex: 1 50%;
  }
  .form-item.flex-60 {
    flex: 1 60%;
  }
  .element-invisible {
    margin: 0;
    padding: 0;
  }
  .captcha {
    padding: 10px;
    @media screen and (max-width: $tablet + 1) {
      padding: 0 30px 10px;
    }
  }
  #edit-submitted-industry {
    max-width: 420px;
  }
  .form-actions {
    padding: 5px 10px;
    flex: 1 auto;
    @media screen and (max-width: $tablet + 1) {
      margin: 0;
      padding: 0 10px;
    }
    .submit-wrapper {
      position: relative;
      max-width: 150px;
      float: right;
      background-color: #888;
      @media screen and (max-width: $tablet + 1) {
        float: none;
        margin: 0 20px;
      }
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 20px;
      display: block;
      width: 33px;
      height: 8px;
      margin-left: 20px;
      background-image: url(../images/icon-submit-button.svg);
      background-repeat: no-repeat;
      background-position: center;
      transform: translateY(-50%);
      z-index: 0;
    }
    .webform-submit {
      position: relative;
      width: 150px;
      margin: 0;
      padding: 8px 18px;
      border: 0;
      background-color: transparent;
      color: #fff;
      font-family: $sans;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      z-index: 1;
    }
  }
}

@media screen and (max-width: $mobile) {
  .messages.error {
    word-wrap: break-word;
  }
}

.page-node-88 {
  .region-below-content {
    .block {
      position: relative;
    }
  }
  .webform-client-form-88 {
    clear: both;
    max-width: 530px;
    margin: auto;
    .form-item.webform-component-textfield,
    .form-item.webform-component-email,
    .form-item.webform-component-phone {
      background-color: #dedede;
      max-width: 530px;
      margin: 13px auto;
      padding: 0;
      input {
        padding: 13px 25px 13px 25px;
        margin: 0;
        font-size: 16px;
        transition: border 0.5s;
      }
      label {
        font-size: 16px;
        font-weight: normal;
        color: #888;
        padding: 20px 25px 0 25px;
        cursor: text;
      }
    }

    textarea {
      padding-top: 0;
      border: 0;
      margin: 0;
    }
    .form-actions {
      clear: both;
      border-top: 1px solid #c8c8c8;
      input {
        color: #fff;
        font-size: 1em;
        margin: 20px auto;
        width: 50%;
        background: #c8c8c8;
        border: none;
        border-radius: 3px;
        display: block;
      }
    }
    .file-field-wrapper {
      padding: 0;
      .messages.error {
        display: none;
      }
    }
    #edit-submitted-resume-cv-ajax-wrapper {
      padding-right: 13px;
    }
    #edit-submitted-cover-letter-ajax-wrapper {
      padding-left: 13px;
    }
    #edit-submitted-resume-cv-ajax-wrapper,
    #edit-submitted-cover-letter-ajax-wrapper {
      margin: auto;
      .webform-component-file {
        #edit-submitted-resume-cv-remove-button,
        #edit-submitted-cover-letter-remove-button {
          border-bottom: none;
          font-size: 10px;
        }
        label {
          cursor: pointer;
          color: #fff;
          text-align: center;
          width: 100%;
          padding: 20px 40px;
          border-radius: 3px;
          background: #fe4535;
          position: relative !important;
          transition: all 0.25s;
          &:hover {
            background: #d8262e;
          }
          &:before {
            content: "";
            background: url(../images/paperclip.png) no-repeat;
            width: 16px;
            height: 16px;
            display: inline-block;
            position: relative;
            top: 3px;
            left: -10px;
            transition: all 0.5s;
          }
          &:after {
            content: "";
            background: url(../images/checkmark.icon.png) no-repeat;
            width: 16px;
            height: 16px;
            display: inline-block;
            position: relative;
            top: 3px;
            left: -10px;
            opacity: 0;
            transition: all 0.5s;
          }
          &.error {
            font-size: 0;
            background: #d8262e;
            &:before {
              background: url(../images/error.icon.x.png) no-repeat;
              width: 22px;
              height: 22px;
              left: initial;
            }
            &:after {
              background: none;
              width: 0;
            }
          }
          &.file-selected {
            background: mos-color("green");
            &:before {
              left: 0;
              opacity: 0;
            }
            &:after {
              left: 10px;
              opacity: 1;
            }
          }
          span {
            color: #fff;
          }
        }
        .form-managed-file {
          display: none;
        }
      }
    }
  }
  .webform-client-form {
    position: relative;
    overflow: hidden;
    display: none;
    .desc-text {
      margin-top: 20px;
      margin-bottom: 30px;
      display: block;
      text-align: center;
      .remove {
        color: mos-color("green");
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  #block-views-job-posting-forms-block-1 {
    display: none;
  }
  #block-block-31 {
    @include angled-section(
      mos-color("black") !important,
      -375px !important,
      1.75deg
    );
  }
}

@include mobile {
  .page-node-88 {
    .webform-client-form-88 {
      padding: 0 15px;
    }
    .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper {
      padding-right: 0;
    }
    .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper {
      padding-left: 0;
    }
    .front .node p {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
