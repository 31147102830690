#above_content {
  position: relative;
  background: #fff;
  padding-bottom: 40px;
}

// md+
@media (min-width: 1200px) {
  #above_content {
    .container {
      width: 1020px;

      & > .row {
        margin-top: -200px;
      }
    }
  }
}

// xs
@media screen and (max-width: 767px) {
  #above_content {
    .region-above-content {
      margin: 0 20px;
    }

    .container {
      &> .row {
        margin-top: -140px;
      }
    }
  }
}

.health-callout {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  height: 380px;

  h3 {
    font-size: 20px;
    color: #fff;
    text-transform: none;
    text-align: center;
    line-height: 32px;
    @include mos-font('sans', 600);
  }

  h6 {
    margin: 0;
    font-size: 12px;
    color: #fff;
    @include mos-font('sans', 600);
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 100;
    // color: #fff;
  }

  p {
    font-size: 14px;
    color: #fff;
    line-height: 24px;
    text-align: center;
    @include mos-font('sans', 600);
    max-width: 220px;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  input {
    color: #fff;
    background: #35a4bd;
    border: 0;
    height: 50px;
    font-size: 14px;
    @include mos-font('sans', 600);

    &::placeholder {
      color: #fff;
    }
  }

  .health-callout__wrap {
    img {
      padding-top: 60px;
      max-width: 220px;
      margin: 0 auto;
    }
  }

  .form-item {
    margin: 0;
  }

  .webform-component--name {
    margin-top: 24px;
  }

  .form-actions {
    max-width: 150px;
    margin: 0 auto;
  }

  input.form-submit {
    margin: 0;
    width: 100%;
    background: #6dc8dc;
    border-radius: 25px;
    padding: 7px 20px;
    @include mos-font('sans', 700);
  }

  .owl-carousel {
    height: 380px;
  }

  .owl-nav {
    position: absolute;
    text-align: right;
    width: 100%;
    bottom: 35px;
    right: 30px;
  }

  .owl-dots {
    display: none;
  }

  .owl-carousel .owl-nav {
    button {
      outline: 0;
    }
    .arrow-left {
      display: block;
      height: 15px;
      width: 9px;
      background: url(../images/arrow-right@2x.png) no-repeat 0 0 transparent;
      background-size: 9px 15px;
    }
    .arrow-right {
      margin-right: 30px;
      display: block;
      height: 15px;
      width: 9px;
      background: url(../images/arrow-left@2x.png) no-repeat 0 0 transparent;
      background-size: 9px 15px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .health-callout {
    .webform-component--title {
      max-width: 220px;
      margin: 0 auto;
    }

    .webform-component--name,
    .webform-component--email-address {
      max-width: 260px;
      margin: 0 auto;
    }
  }
}

.health-callout__award {
  h6, p {
    color: #000;
  }

  .health-callout__wrap {
    height: 380px;
    width: 100%;
  }
}

.health-callout__clients {
  .health-callout__wrap {
    height: 380px;
    width: 100%;
  }

  .owl-carousel .owl-nav {
    .arrow-left {
      background: url(../images/arrow-right-white@2x.png) no-repeat 0 0 transparent;
      background-size: 9px 15px;
    }
    .arrow-right {
      background: url(../images/arrow-left-white@2x.png) no-repeat 0 0 transparent;
      background-size: 9px 15px;
    }
  }
}

.health-callout__newsletter {
  background: #3BB5D0;
  .content {
    padding: 30px;
  }

  h6 {
    position: relative;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: $tablet) {
  #above_content {
    padding-bottom: 0;
  }
  .health-callout {
    height: 280px;

    h3 {
      font-size: 16px;
      line-height: 24px;
    }

    h6 {
      font-size: 10px;
      top: 20px;
      left: 20px;
    }
    p {
      font-size: 12px;
      line-height: 18px;
      padding: 10px 18px;
    }
    img {
      padding: 0 18px;
    }

    .owl-carousel {
      height: 280px;
    }

    .owl-nav {
      bottom: 16px;
      right: 20px;
    }

    .health-callout__wrap {
      height: 280px;

      img {
        padding-top: 40px;
      }
    }

    input {
      margin-bottom: 16px;
      height: 44px;
    }

    .webform-component--name {
      margin-top: 16px;
    }
  }

  .health-callout__newsletter {
    .content {
      padding: 20px;
    }
    h6 {
      top: 0;
      left: 0;
    }
  }
}
