@charset "UTF-8";
/* -------------------- base/overrides -------------------- */
.section {
  margin: 0; }

a:active, a:focus {
  text-decoration: none; }

/* -------------------- base/type -------------------- */
body {
  font-family: "Roboto Slab", georgia, serif;
  font-weight: 400;
  font-style: normal;
  color: #262626;
  background-color: #e8e8e8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

a,
a:visited {
  color: #26ac62;
  text-decoration: none; }
  a:hover,
  a:visited:hover {
    color: #1d824a;
    text-decoration: none; }

h1,
h2,
.paragraphs-item-quote .field-name-field-quote p,
h3,
h4,
h5 {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase; }

h1 {
  font-size: 60px; }


h2,
.paragraphs-item-quote .field-name-field-quote p {
  font-size: 32px; }

h3 {
  font-size: 24px; }

h4 {
  font-size: 16px; }

h5 {
  font-family: "Roboto Slab", georgia, serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px; }

@media screen and (min-width: 767px) and (max-width: 1024px) {
  h1 {
    font-size: 60px; }
  
  h2,
  .paragraphs-item-quote .field-name-field-quote p {
    font-size: 32px; }
  h3 {
    font-size: 24px; }
  h4, h5 {
    font-size: 16px; } }

@media screen and (max-width: 767px) {
  h1 {
    font-size: 30px; }
  
  h2,
  .paragraphs-item-quote .field-name-field-quote p {
    font-size: 24px; }
  h3 {
    font-size: 16px; }
  h4, h5 {
    font-size: 14px; } }

p {
  font-size: 16px; }

@media screen and (max-width: 767px) {
  html body.admin-menu {
    margin-top: 0 !important; }
  #admin-menu {
    display: none; } }

hr {
  background-color: #cdcdcd;
  border: none;
  height: 4px;
  margin: 85px auto 80px;
  width: 135px; }

/* -------------------- components/form -------------------- */
form input,
form textarea {
  background: #dedede;
  border: 2px solid #dedede;
  color: #171717;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
  outline: none;
  padding: 8px 0;
  width: 100%;
  padding: 13px 25px 13px 25px; }

form label {
  font-size: 16px;
  font-weight: normal;
  color: #888;
  padding: 20px 25px 0 25px;
  cursor: text; }

form:-ms-input-placeholder {
  color: #171717;
  font-size: 24px; }

form::-moz-placeholder {
  color: #171717;
  font-size: 24px; }

form::-webkit-input-placeholder {
  color: #171717;
  font-size: 24px; }

form:focus:-ms-input-placeholder {
  color: #aaa; }

form:focus::-moz-placeholder {
  color: #aaa; }

form:focus::-webkit-input-placeholder {
  color: #aaa; }

form .button-primary {
  border: 1px solid #b8b8b8;
  margin-top: 20px;
  padding: 10px 20px;
  width: auto; }

@media screen and (max-width: 767px) {
  form input,
  form textarea {
    font-size: 18px;
    line-height: 24px; }
  form .button-primary {
    width: 100%; } }

#webform-client-form-55 {
  padding: 0 80px; }

@media screen and (max-width: 767px) {
  #webform-client-form-55 {
    padding: 0 15px; } }

.page-node-56.not-front .node {
  padding-bottom: 40px;
  text-align: center; }
  .page-node-56.not-front .node p {
    color: #989898;
    font-size: 25px;
    line-height: 1.25em;
    margin-top: 20px; }

@media screen and (max-width: 767px) {
  .page-node-56.not-front .node p {
    font-size: 16px; } }

.paragraphs-item-multi-icon-cta,
.paragraphs-item-fullwidth-cta,
.paragraphs-item-two-column-cta {
  color: #000;
  font-size: 14px;
  font-weight: 300;
  line-height: 26px;
  font-family: sans-serif !important;
  background-color: #fafafa; }
  .paragraphs-item-multi-icon-cta.not-visible .cta-image,
  .paragraphs-item-multi-icon-cta.not-visible .cta-content,
  .paragraphs-item-fullwidth-cta.not-visible .cta-image,
  .paragraphs-item-fullwidth-cta.not-visible .cta-content,
  .paragraphs-item-two-column-cta.not-visible .cta-image,
  .paragraphs-item-two-column-cta.not-visible .cta-content {
    opacity: 0; }
  .paragraphs-item-multi-icon-cta .cta-content,
  .paragraphs-item-multi-icon-cta .cta-image,
  .paragraphs-item-fullwidth-cta .cta-content,
  .paragraphs-item-fullwidth-cta .cta-image,
  .paragraphs-item-two-column-cta .cta-content,
  .paragraphs-item-two-column-cta .cta-image {
    transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out; }
  .paragraphs-item-multi-icon-cta small,
  .paragraphs-item-fullwidth-cta small,
  .paragraphs-item-two-column-cta small {
    color: #989898;
    font-size: 10px;
    line-height: 16px; }
  .paragraphs-item-multi-icon-cta .field-name-field-title,
  .paragraphs-item-fullwidth-cta .field-name-field-title,
  .paragraphs-item-two-column-cta .field-name-field-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 20px; }
  .paragraphs-item-multi-icon-cta .field-name-field-subtitle,
  .paragraphs-item-fullwidth-cta .field-name-field-subtitle,
  .paragraphs-item-two-column-cta .field-name-field-subtitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 10px; }
  .paragraphs-item-multi-icon-cta .field-name-field-cta a,
  .paragraphs-item-fullwidth-cta .field-name-field-cta a,
  .paragraphs-item-two-column-cta .field-name-field-cta a {
    font-weight: 700;
    color: #fff;
    padding: 15px 40px;
    background-color: #2acc71;
    border-radius: 28px;
    display: inline;
    font-size: 14px;
    transition: background-color 0.2s ease-in-out; }
    .paragraphs-item-multi-icon-cta .field-name-field-cta a:hover,
    .paragraphs-item-fullwidth-cta .field-name-field-cta a:hover,
    .paragraphs-item-two-column-cta .field-name-field-cta a:hover {
      background-color: #26B866; }

.paragraphs-item-fullwidth-cta .cta-content,
.paragraphs-item-two-column-cta .cta-content {
  background-color: #fff;
  z-index: 1;
  position: relative;
  padding: 50px 35px 80px; }

.paragraphs-item-fullwidth-cta .cta-image,
.paragraphs-item-two-column-cta .cta-image {
  min-height: 375px;
  color: #fff;
  background-color: #171717;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center; }

.paragraphs-item-fullwidth-cta .field-name-field-content,
.paragraphs-item-two-column-cta .field-name-field-content {
  margin-bottom: 36px; }

.even > .paragraphs-item-two-column-cta.not-visible .cta-content,
.even > .paragraphs-item-fullwidth-cta.not-visible .cta-content {
  transform: translateX(-100%); }

.odd > .paragraphs-item-two-column-cta.not-visible .cta-content,
.odd > .paragraphs-item-fullwidth-cta.not-visible .cta-content {
  transform: translateX(100%); }

@media only screen and (min-width: 769px) {
  .paragraphs-item-multi-icon-cta .field-name-field-title,
  .paragraphs-item-fullwidth-cta .field-name-field-title,
  .paragraphs-item-two-column-cta .field-name-field-title {
    margin-bottom: 32px;
    font-size: 36px;
    line-height: 46px; }
  .paragraphs-item-multi-icon-cta .field-name-field-subtitle,
  .paragraphs-item-fullwidth-cta .field-name-field-subtitle,
  .paragraphs-item-two-column-cta .field-name-field-subtitle {
    line-height: 36px;
    font-size: 30px;
    margin-bottom: 20px; }
  .paragraphs-item-two-column-cta,
  .paragraphs-item-fullwidth-cta {
    display: flex; }
    .paragraphs-item-two-column-cta > div,
    .paragraphs-item-fullwidth-cta > div {
      flex: 1 0 50%;
      max-width: 50%; }
    .paragraphs-item-two-column-cta .cta-content,
    .paragraphs-item-fullwidth-cta .cta-content {
      padding: 60px;
      max-width: 700px;
      margin: auto; }
    .paragraphs-item-two-column-cta .field-name-field-content,
    .paragraphs-item-fullwidth-cta .field-name-field-content {
      margin-bottom: 50px; }
  .even > .paragraphs-item-two-column-cta .cta-image,
  .even > .paragraphs-item-fullwidth-cta .cta-image {
    order: 1; } }

@media only screen and (min-width: 1200px) {
  .paragraphs-item-multi-icon-cta,
  .paragraphs-item-fullwidth-cta,
  .paragraphs-item-two-column-cta {
    font-size: 16px;
    line-height: 28px; }
    .paragraphs-item-multi-icon-cta small,
    .paragraphs-item-fullwidth-cta small,
    .paragraphs-item-two-column-cta small {
      font-size: 12px;
      line-height: 18px; }
    .paragraphs-item-multi-icon-cta .field-name-field-title,
    .paragraphs-item-fullwidth-cta .field-name-field-title,
    .paragraphs-item-two-column-cta .field-name-field-title {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 42px; }
    .paragraphs-item-multi-icon-cta .field-name-field-cta a,
    .paragraphs-item-fullwidth-cta .field-name-field-cta a,
    .paragraphs-item-two-column-cta .field-name-field-cta a {
      padding: 16px 50px;
      border-radius: 30px;
      font-size: 16px; } }

.paragraphs-item-two-column-cta .cta-wrapper {
  background-color: #fff; }

@media only screen and (min-width: 1200px) {
  .paragraphs-item-two-column-cta .cta-content {
    padding: 180px 120px; }
  .paragraphs-item-two-column-cta > div {
    min-height: 500px; } }

@media only screen and (max-width: 768px) {
  .paragraphs-item-fullwidth-cta {
    background-image: none !important; }
    .paragraphs-item-fullwidth-cta .cta-wrapper {
      background-color: #fff; } }

@media only screen and (min-width: 769px) {
  .paragraphs-item-fullwidth-cta {
    background-color: #171717;
    background-size: cover !important;
    background-repeat: none;
    background-position: center;
    transition: opacity 1.5s ease-in-out; }
    .paragraphs-item-fullwidth-cta.not-visible {
      opacity: 0; }
    .paragraphs-item-fullwidth-cta .cta-image {
      background: none !important; }
    .paragraphs-item-fullwidth-cta > div {
      min-height: 560px; }
    .paragraphs-item-fullwidth-cta .cta-wrapper {
      position: relative; }
    .paragraphs-item-fullwidth-cta .cta-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: auto;
      padding: 35px 50px 50px; } }

@media only screen and (min-width: 1200px) {
  .paragraphs-item-fullwidth-cta > div {
    min-height: 780px; }
  .paragraphs-item-fullwidth-cta .cta-content {
    padding: 40px 60px 70px; } }

@media only screen and (min-width: 769px) {
  .even > .paragraphs-item-fullwidth-cta.not-visible .cta-content {
    transform: translate(-150%, -50%); }
  .odd > .paragraphs-item-fullwidth-cta.not-visible .cta-content {
    transform: translate(50%, -50%); } }

.paragraphs-item-icon {
  text-align: center; }
  .paragraphs-item-icon .field-name-field-icon {
    margin: 67px auto 25px;
    line-height: 144px; }
    .paragraphs-item-icon .field-name-field-icon img {
      min-height: 54px;
      width: auto; }

.paragraphs-items-field-icons .field-items > .field-item {
  opacity: 1;
  transition: opacity 0.75s ease-in-out; }
  .paragraphs-items-field-icons .field-items > .field-item:nth-child(1) {
    transition-delay: 0.5s; }
  .paragraphs-items-field-icons .field-items > .field-item:nth-child(2) {
    transition-delay: 1s; }
  .paragraphs-items-field-icons .field-items > .field-item:nth-child(3) {
    transition-delay: 1.5s; }
  .paragraphs-items-field-icons .field-items > .field-item:nth-child(4) {
    transition-delay: 2s; }
  .paragraphs-items-field-icons .field-items > .field-item:nth-child(5) {
    transition-delay: 2.5s; }

.not-visible .paragraphs-items-field-icons .field-items > .field-item {
  opacity: 0; }

@media only screen and (min-width: 769px) {
  .paragraphs-item-icon .field-name-field-icon {
    margin: 0 auto 25px; } }

@media only screen and (min-width: 1200px) {
  .paragraphs-item-icon .field-name-field-icon {
    margin-bottom: 35px; } }

.paragraphs-item-multi-icon-cta {
  padding: 50px 35px 120px; }
  .paragraphs-item-multi-icon-cta .field-name-field-cta .field-items {
    display: flex; }
  .paragraphs-item-multi-icon-cta .field-name-field-cta .field-item,
  .paragraphs-item-multi-icon-cta .field-name-field-cta a {
    display: block;
    float: left;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    margin-top: 85px; }

@media only screen and (min-width: 769px) {
  .paragraphs-item-multi-icon-cta {
    padding: 120px 80px 150px;
    text-align: center; }
    .paragraphs-item-multi-icon-cta > .content {
      max-width: 864px;
      margin: 0 auto; }
    .paragraphs-item-multi-icon-cta .field-name-field-body {
      margin-bottom: 56px;
      text-align: justify;
      text-align-last: center; }
    .paragraphs-item-multi-icon-cta .field-name-field-icons > .field-items {
      display: flex; }
      .paragraphs-item-multi-icon-cta .field-name-field-icons > .field-items > .field-item {
        flex: 1 0 0px; }
        .paragraphs-item-multi-icon-cta .field-name-field-icons > .field-items > .field-item:nth-last-child(n + 2) {
          margin-right: 42px; }
    .paragraphs-item-multi-icon-cta .field-name-field-cta .field-item,
    .paragraphs-item-multi-icon-cta .field-name-field-cta a {
      margin-top: 65px; } }

@media only screen and (min-width: 1200px) {
  .paragraphs-item-multi-icon-cta {
    padding: 200px 80px; }
    .paragraphs-item-multi-icon-cta > .content {
      max-width: 1100px; }
    .paragraphs-item-multi-icon-cta .field-name-field-body {
      margin-bottom: 86px; }
    .paragraphs-item-multi-icon-cta .field-name-field-icons > .field-items > .field-item:nth-last-child(n + 2) {
      margin-right: 80px; }
    .paragraphs-item-multi-icon-cta .field-name-field-cta .field-item,
    .paragraphs-item-multi-icon-cta .field-name-field-cta a {
      margin-top: 85px; } }

/*
MOS styled accordion
*/
.panel-group-mos .panel + .panel {
  margin: 0; }

.panel-group-mos .panel-mos {
  border: 0;
  border-radius: 0;
  background: transparent;
  border-top: 2px solid #cdcdcd;
  box-shadow: none; }
  .panel-group-mos .panel-mos:last-child {
    border-bottom: 2px solid #cdcdcd; }

.panel-mos > .panel-heading {
  background: transparent;
  border-radius: 0;
  border: 0;
  padding: 20px 0; }
  .panel-mos > .panel-heading h4 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #26ac62; }
  .panel-mos > .panel-heading a {
    display: block; }

.panel-mos .panel-collapse > .panel-body {
  border: 0 !important;
  padding-top: 0; }

.parachute-active #page-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #e8e8e8; }

.parachute-active #page {
  position: absolute;
  left: 0;
  width: 100%; }

.page-full-width {
  padding-left: 0;
  padding-right: 0; }
  .page-full-width .node-page .field-name-body {
    max-width: 945px;
    margin: 0 auto;
    padding-bottom: 100px; }

.field-name-field-page-sections .entity-paragraphs-item {
  margin-left: -15px;
  margin-right: -15px;
  padding: 0;
  position: relative; }
  .field-name-field-page-sections .entity-paragraphs-item:after {
    background: #e8e8e8;
    bottom: -50px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .field-name-field-page-sections .entity-paragraphs-item:after {
    left: 0;
    height: 100px; }

.field-name-field-page-sections .field-name-field-title {
  margin-bottom: 10px;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 44px;
  font-weight: 700; }
  @media screen and (max-width: 768px) {
    .field-name-field-page-sections .field-name-field-title {
      font-size: 32px; } }

.field-name-field-page-sections .field-name-field-body {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px; }
  .field-name-field-page-sections .field-name-field-body p {
    font-size: inherit; }
    @media screen and (max-width: 768px) {
      .field-name-field-page-sections .field-name-field-body p {
        font-size: 13px;
        line-height: 23px; } }

.parachute-disabled .field-name-field-page-sections .entity-paragraphs-item {
  margin-left: 0;
  margin-right: 0; }

.field-name-field-page-sections .paragraphs-item-logo-grid {
  text-align: center; }
  @media screen and (max-width: 768px) {
    .field-name-field-page-sections .paragraphs-item-logo-grid > .content {
      padding: 0 45px; } }
  .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-body {
    max-width: 640px;
    margin: 0 auto; }
  .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos,
  .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid {
    max-width: 1160px;
    margin: 70px auto 0;
    padding-bottom: 120px; }
    @media screen and (max-width: 768px) {
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos,
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid {
        margin-top: 45px;
        margin-left: -30px;
        margin-right: -30px;
        padding-bottom: 50px; } }
    .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos > .field-items,
    .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid > .field-items {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto; }
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos > .field-items > .field-item,
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid > .field-items > .field-item {
        align-self: center;
        flex: 0 25%; }
        @media screen and (max-width: 1000px) {
          .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos > .field-items > .field-item,
          .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid > .field-items > .field-item {
            flex: 0 33%; } }
        @media screen and (max-width: 768px) {
          .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos > .field-items > .field-item,
          .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid > .field-items > .field-item {
            flex: 0 50%; } }
        .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos > .field-items > .field-item img,
        .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid > .field-items > .field-item img {
          width: 100%;
          max-width: 100%;
          height: auto; }
    .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .paragraphs-item-logo,
    .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo {
      position: relative;
      overflow: hidden;
      height: 120px;
      margin: 0;
      padding: 20px; }
      @media screen and (max-width: 600px) {
        .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .paragraphs-item-logo,
        .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo {
          height: 80px; } }
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .paragraphs-item-logo .field-name-field-link a,
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo .field-name-field-link a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-indent: -9999px;
        overflow: hidden; }
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .paragraphs-item-logo.hover-text .field-name-field-hover-text,
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo.hover-text .field-name-field-hover-text {
        position: relative;
        padding-left: 50px;
        color: #888;
        font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        text-align: left; }
        .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .paragraphs-item-logo.hover-text .field-name-field-hover-text .field-item:after,
        .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo.hover-text .field-name-field-hover-text .field-item:after {
          content: '';
          display: inline-block;
          width: 35px;
          height: 10px;
          margin-left: 20px;
          background-image: url(../images/icon-arrow-grey.svg);
          background-repeat: no-repeat; }
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .paragraphs-item-logo.hover-text .field-name-field-logo-image,
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo.hover-text .field-name-field-logo-image {
        transition: margin 500ms; }
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .paragraphs-item-logo.hover-text:hover .field-name-field-logo-image,
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo.hover-text:hover .field-name-field-logo-image {
        margin-top: -40px; }
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .paragraphs-item-logo .field-type-image,
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo .field-type-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; }
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .paragraphs-item-logo.hover-image .field-name-field-logo-image,
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo.hover-image .field-name-field-logo-image {
        transition: opacity 750ms; }
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .paragraphs-item-logo.hover-image .field-name-field-hover-image,
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo.hover-image .field-name-field-hover-image {
        opacity: 0;
        transition: opacity 500ms; }
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .paragraphs-item-logo.hover-image:hover .field-name-field-hover-image,
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo.hover-image:hover .field-name-field-hover-image {
        opacity: 1; }
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .paragraphs-item-logo.hover-image:hover .field-name-field-logo-image,
      .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo.hover-image:hover .field-name-field-logo-image {
        opacity: 0; }
    .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-logos .entity-paragraphs-item:after,
    .field-name-field-page-sections .paragraphs-item-logo-grid .field-name-field-grid .entity-paragraphs-item:after {
      content: none; }

.field-name-field-page-sections .paragraphs-item-content {
  padding: 200px 0 100px; }
  .field-name-field-page-sections .paragraphs-item-content .content {
    max-width: 890px;
    margin: 0 auto; }
    .field-name-field-page-sections .paragraphs-item-content .content > .field {
      flex: 0 1 400px;
      margin: 0 90px 0 0; }
      .field-name-field-page-sections .paragraphs-item-content .content > .field h2, .field-name-field-page-sections .paragraphs-item-content .content > .field .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .field-name-field-page-sections .paragraphs-item-content .content > .field p {
        margin-bottom: 65px;
        font-weight: 700;
        text-transform: none; }
        @media screen and (max-width: 600px) {
          .field-name-field-page-sections .paragraphs-item-content .content > .field h2, .field-name-field-page-sections .paragraphs-item-content .content > .field .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .field-name-field-page-sections .paragraphs-item-content .content > .field p {
            margin-bottom: 50px; } }
      .field-name-field-page-sections .paragraphs-item-content .content > .field h3 {
        margin-bottom: 30px;
        color: #000;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        text-transform: none; }
        @media screen and (max-width: 600px) {
          .field-name-field-page-sections .paragraphs-item-content .content > .field h3 {
            font-size: 15px;
            line-height: 25px; } }
      .field-name-field-page-sections .paragraphs-item-content .content > .field p {
        margin-bottom: 30px;
        color: #626262;
        line-height: 24px;
        font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
        font-size: 14px; }
        @media screen and (max-width: 768px) {
          .field-name-field-page-sections .paragraphs-item-content .content > .field p {
            font-size: 13px; } }
      .field-name-field-page-sections .paragraphs-item-content .content > .field ul {
        padding-left: 17px;
        color: #626262;
        line-height: 24px;
        list-style-type: none; }
      .field-name-field-page-sections .paragraphs-item-content .content > .field ul li:before {
        content: '•';
        float: left;
        margin-left: -17px;
        font-size: 14px;
        color: #626262; }
      .field-name-field-page-sections .paragraphs-item-content .content > .field ul li p {
        margin-bottom: 0; }
      .field-name-field-page-sections .paragraphs-item-content .content > .field:last-child {
        margin-right: 0; }
      .field-name-field-page-sections .paragraphs-item-content .content > .field .flex-wrapper {
        display: flex;
        flex-wrap: wrap; }
        .field-name-field-page-sections .paragraphs-item-content .content > .field .flex-wrapper .flex-item {
          flex: 1 auto; }
  @media screen and (max-width: 768px) {
    .field-name-field-page-sections .paragraphs-item-content {
      padding: 150px 0; }
      .field-name-field-page-sections .paragraphs-item-content .content {
        display: block; }
        .field-name-field-page-sections .paragraphs-item-content .content > .field {
          margin-bottom: 100px;
          padding: 0 45px; }
          .field-name-field-page-sections .paragraphs-item-content .content > .field:last-child {
            margin-bottom: 0; } }

.field-name-field-page-sections .paragraphs-item-two-column-text {
  padding: 200px 0 100px; }
  .field-name-field-page-sections .paragraphs-item-two-column-text .content {
    max-width: 890px;
    margin: 0 auto;
    display: flex; }
    .field-name-field-page-sections .paragraphs-item-two-column-text .content > .field {
      flex: 0 1 400px;
      margin: 0 90px 0 0; }
      .field-name-field-page-sections .paragraphs-item-two-column-text .content > .field h2, .field-name-field-page-sections .paragraphs-item-two-column-text .content > .field .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .field-name-field-page-sections .paragraphs-item-two-column-text .content > .field p {
        margin-bottom: 65px;
        font-weight: 700;
        text-transform: none; }
      .field-name-field-page-sections .paragraphs-item-two-column-text .content > .field p {
        margin-bottom: 30px;
        color: #626262;
        line-height: 24px;
        font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
        font-size: 14px; }
      .field-name-field-page-sections .paragraphs-item-two-column-text .content > .field p strong {
        color: #000;
        font-weight: 500;
        font-size: 16px; }
      .field-name-field-page-sections .paragraphs-item-two-column-text .content > .field:last-child {
        margin-right: 0; }
  @media screen and (max-width: 768px) {
    .field-name-field-page-sections .paragraphs-item-two-column-text {
      padding: 150px 0; }
      .field-name-field-page-sections .paragraphs-item-two-column-text .content {
        display: block; }
        .field-name-field-page-sections .paragraphs-item-two-column-text .content > .field {
          margin-bottom: 100px;
          padding: 0 45px; }
          .field-name-field-page-sections .paragraphs-item-two-column-text .content > .field:last-child {
            margin-bottom: 0; } }

.field-name-field-page-sections .paragraphs-item-featured {
  position: relative;
  padding: 0;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden; }
  .field-name-field-page-sections .paragraphs-item-featured > .content {
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat; }
    @media screen and (max-width: 768px) {
      .field-name-field-page-sections .paragraphs-item-featured > .content {
        background-size: 1000px; } }
  .field-name-field-page-sections .paragraphs-item-featured .field-name-field-body {
    max-width: 640px;
    padding: 293px 0 0;
    margin: 0 auto;
    margin-bottom: -70px;
    color: #fff;
    text-align: center; }
    .field-name-field-page-sections .paragraphs-item-featured .field-name-field-body h2, .field-name-field-page-sections .paragraphs-item-featured .field-name-field-body .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .field-name-field-page-sections .paragraphs-item-featured .field-name-field-body p {
      margin-top: 0;
      font-size: 44px;
      font-weight: 700;
      line-height: 54px;
      text-transform: none; }
    @media screen and (max-width: 768px) {
      .field-name-field-page-sections .paragraphs-item-featured .field-name-field-body {
        padding: 125px 50px 190px 50px;
        transform: none !important; }
        .field-name-field-page-sections .paragraphs-item-featured .field-name-field-body h2, .field-name-field-page-sections .paragraphs-item-featured .field-name-field-body .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .field-name-field-page-sections .paragraphs-item-featured .field-name-field-body p {
          font-size: 32px;
          line-height: 40px; } }
  .field-name-field-page-sections .paragraphs-item-featured .container {
    position: relative;
    width: 100%;
    max-width: 1260px;
    padding: 0 120px;
    z-index: 1; }
    @media screen and (max-width: 767px) {
      .field-name-field-page-sections .paragraphs-item-featured .container {
        padding: 0 45px; } }
  .field-name-field-page-sections .paragraphs-item-featured .featured-group {
    position: relative;
    margin: 310px auto -60px;
    padding-bottom: 0; }
    @media screen and (max-width: 900px) {
      .field-name-field-page-sections .paragraphs-item-featured .featured-group {
        transform: none !important; } }
    @media screen and (max-width: 768px) {
      .field-name-field-page-sections .paragraphs-item-featured .featured-group {
        top: 0;
        margin: 0 auto; } }
    .field-name-field-page-sections .paragraphs-item-featured .featured-group .wrapper {
      display: flex;
      max-width: 1380px;
      height: 700px; }
      @media screen and (max-width: 768px) {
        .field-name-field-page-sections .paragraphs-item-featured .featured-group .wrapper {
          display: block;
          height: initial; } }
    .field-name-field-page-sections .paragraphs-item-featured .featured-group:after {
      background: #f8f8f8;
      bottom: -60px;
      content: '';
      height: 520px;
      overflow: visible;
      position: absolute;
      -webkit-transform: skewY(-1.75deg);
      transform: skewY(-1.75deg);
      width: 100%;
      overflow-x: hidden;
      z-index: 0; }
      @media screen and (max-width: 768px) {
        .field-name-field-page-sections .paragraphs-item-featured .featured-group:after {
          bottom: -10px;
          height: 20px; } }
    .field-name-field-page-sections .paragraphs-item-featured .featured-group .col-one > div,
    .field-name-field-page-sections .paragraphs-item-featured .featured-group .col-two > div {
      background-size: cover; }
    .field-name-field-page-sections .paragraphs-item-featured .featured-group.fourty-sixty .col-one {
      flex: 1 40%; }
    .field-name-field-page-sections .paragraphs-item-featured .featured-group.fourty-sixty .col-two {
      flex: 1 60%; }
    .field-name-field-page-sections .paragraphs-item-featured .featured-group .field-name-field-description {
      height: 398px;
      position: relative;
      z-index: 1;
      background: #000;
      color: #fff;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
      position: relative; }
      .field-name-field-page-sections .paragraphs-item-featured .featured-group .field-name-field-description:after {
        background: #000;
        bottom: -5px;
        content: '';
        height: 300px;
        overflow: visible;
        position: absolute;
        transform: skewY(-1.75deg);
        width: 100%;
        overflow-x: hidden;
        z-index: 0;
        -webkit-backface-visibility: hidden; }
      .field-name-field-page-sections .paragraphs-item-featured .featured-group .field-name-field-description:after {
        height: 20px; }
      @media screen and (max-width: 768px) {
        .field-name-field-page-sections .paragraphs-item-featured .featured-group .field-name-field-description {
          height: auto; } }
      .field-name-field-page-sections .paragraphs-item-featured .featured-group .field-name-field-description .field-items {
        position: absolute;
        top: 54%;
        left: 60px;
        right: 60px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); }
        @media screen and (max-width: 768px) {
          .field-name-field-page-sections .paragraphs-item-featured .featured-group .field-name-field-description .field-items {
            padding: 60px 30px;
            position: relative;
            top: inherit;
            left: inherit;
            right: inherit;
            transform: translateY(0); } }
      .field-name-field-page-sections .paragraphs-item-featured .featured-group .field-name-field-description h3 {
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 32px;
        font-weight: 600;
        line-height: 1.3;
        text-transform: none; }
      .field-name-field-page-sections .paragraphs-item-featured .featured-group .field-name-field-description p {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 24px; }
      .field-name-field-page-sections .paragraphs-item-featured .featured-group .field-name-field-description a {
        color: inherit;
        font-weight: 600; }
      @media screen and (max-width: 768px) {
        .field-name-field-page-sections .paragraphs-item-featured .featured-group .field-name-field-description h3 {
          font-size: 24px; }
        .field-name-field-page-sections .paragraphs-item-featured .featured-group .field-name-field-description p {
          font-size: 13px;
          line-height: 23px; }
        .field-name-field-page-sections .paragraphs-item-featured .featured-group .field-name-field-description p:last-child {
          margin-bottom: 0; } }
    .field-name-field-page-sections .paragraphs-item-featured .featured-group .small-bg {
      height: 50%;
      background-size: cover;
      background-position: center center;
      z-index: 0; }
    .field-name-field-page-sections .paragraphs-item-featured .featured-group .large-bg {
      width: 100%;
      height: 100%; }
      @media screen and (max-width: 768px) {
        .field-name-field-page-sections .paragraphs-item-featured .featured-group .large-bg {
          height: 400px; } }
  .field-name-field-page-sections .paragraphs-item-featured:after {
    background: #fff;
    bottom: -50px !important;
    content: '';
    height: 530px !important;
    overflow: visible;
    position: absolute;
    -webkit-transform: skewY(-1.75deg);
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0; }
    @media screen and (max-width: 768px) {
      .field-name-field-page-sections .paragraphs-item-featured:after {
        bottom: 200px !important; } }

.parachute-disabled .paragraphs-item-featured {
  margin-left: 0;
  margin-right: 0; }
  @media screen and (min-width: 769px) {
    .parachute-disabled .paragraphs-item-featured {
      padding-bottom: 150px; }
      .parachute-disabled .paragraphs-item-featured .featured-group {
        margin-top: 120px; }
        .parachute-disabled .paragraphs-item-featured .featured-group:after {
          height: 70px; } }

.field-name-field-page-sections .paragraphs-item-slideshow {
  padding: 80px 0 240px;
  /* Slider */ }
  @media screen and (max-width: 768px) {
    .field-name-field-page-sections .paragraphs-item-slideshow {
      padding-bottom: 150px; }
      .field-name-field-page-sections .paragraphs-item-slideshow:after {
        bottom: -20px; } }
  .field-name-field-page-sections .paragraphs-item-slideshow .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .field-name-field-page-sections .paragraphs-item-slideshow .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0; }
    .field-name-field-page-sections .paragraphs-item-slideshow .slick-list:focus {
      outline: none; }
    .field-name-field-page-sections .paragraphs-item-slideshow .slick-list.dragging {
      cursor: pointer;
      cursor: hand; }
  .field-name-field-page-sections .paragraphs-item-slideshow .slick-slider .slick-track,
  .field-name-field-page-sections .paragraphs-item-slideshow .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .field-name-field-page-sections .paragraphs-item-slideshow .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .field-name-field-page-sections .paragraphs-item-slideshow .slick-track:before, .field-name-field-page-sections .paragraphs-item-slideshow .slick-track:after {
      content: "";
      display: table; }
    .field-name-field-page-sections .paragraphs-item-slideshow .slick-track:after {
      clear: both; }
    .slick-loading .field-name-field-page-sections .paragraphs-item-slideshow .slick-track {
      visibility: hidden; }
  .field-name-field-page-sections .paragraphs-item-slideshow .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px; }
    [dir="rtl"] .field-name-field-page-sections .paragraphs-item-slideshow .slick-slide {
      float: right; }
    .field-name-field-page-sections .paragraphs-item-slideshow .slick-slide img {
      display: block; }
    .field-name-field-page-sections .paragraphs-item-slideshow .slick-slide.slick-loading img {
      display: none; }
    .field-name-field-page-sections .paragraphs-item-slideshow .slick-slide.dragging img {
      pointer-events: none; }
    .slick-initialized .field-name-field-page-sections .paragraphs-item-slideshow .slick-slide {
      display: block; }
    .slick-loading .field-name-field-page-sections .paragraphs-item-slideshow .slick-slide {
      visibility: hidden; }
    .slick-vertical .field-name-field-page-sections .paragraphs-item-slideshow .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent; }
  .field-name-field-page-sections .paragraphs-item-slideshow .slick-arrow {
    position: absolute;
    top: 50%;
    appearance: none;
    display: block;
    width: 28px;
    height: 28px;
    text-indent: -9999px;
    background-image: url(../images/icon-chevron.svg);
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    overflow: hidden;
    transform: translateY(-50%);
    z-index: 1; }
    .field-name-field-page-sections .paragraphs-item-slideshow .slick-arrow.slick-prev {
      top: 45%;
      left: 120px;
      transform: scaleX(-1); }
    .field-name-field-page-sections .paragraphs-item-slideshow .slick-arrow.slick-next {
      right: 120px; }
    .field-name-field-page-sections .paragraphs-item-slideshow .slick-arrow:focus {
      outline: none; }
    @media screen and (max-width: 768px) {
      .field-name-field-page-sections .paragraphs-item-slideshow .slick-arrow.slick-prev {
        left: 20px; }
      .field-name-field-page-sections .paragraphs-item-slideshow .slick-arrow.slick-next {
        right: 20px; } }
  .field-name-field-page-sections .paragraphs-item-slideshow .slick-arrow.slick-hidden {
    display: none; }

.field-name-field-page-sections .paragraphs-item-testimonial > .content {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 0 80px;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .field-name-field-page-sections .paragraphs-item-testimonial > .content {
      padding: 0 30px; } }

.field-name-field-page-sections .paragraphs-item-testimonial .field-name-field-title {
  margin-bottom: 60px; }

.field-name-field-page-sections .paragraphs-item-testimonial p {
  margin-bottom: 30px;
  font-weight: 500; }

.field-name-field-page-sections .paragraphs-item-testimonial .field-name-field-author p {
  color: #626262;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px; }
  @media screen and (max-width: 768px) {
    .field-name-field-page-sections .paragraphs-item-testimonial .field-name-field-author p {
      font-size: 13px;
      line-height: 23px; } }

.field-name-field-page-sections .paragraphs-item-testimonial:after {
  content: none !important; }

.parachute-active .paragraphs-item-featured .featured-group:after {
  height: 100px;
  transition: bottom 600ms ease-in-out,height 600ms ease-in-out; }

.parachute-active .paragraphs-item-featured .featured-group .large-bg,
.parachute-active .paragraphs-item-featured .featured-group .small-bg,
.parachute-active .paragraphs-item-featured .featured-group .field-name-field-description {
  opacity: 0;
  transition: transform 600ms ease-in-out,opacity 600ms ease-in-out,-webkit-transform 600ms ease-in-out; }

.parachute-active .paragraphs-item-featured .featured-group.in-view:after {
  bottom: -10px;
  height: 120px; }
  @media screen and (max-width: 768px) {
    .parachute-active .paragraphs-item-featured .featured-group.in-view:after {
      bottom: 90px;
      height: 30px; } }

.parachute-active .paragraphs-item-featured .featured-group.in-view .large-bg,
.parachute-active .paragraphs-item-featured .featured-group.in-view .small-bg,
.parachute-active .paragraphs-item-featured .featured-group.in-view .field-name-field-description {
  opacity: 1;
  transform: translateY(-100px); }

.parachute-active .paragraphs-item-featured .featured-group.in-view.fourty-sixty .small-bg {
  transition-delay: 100ms; }

.parachute-active .paragraphs-item-featured .featured-group.in-view.fourty-sixty .large-bg {
  transition-delay: 200ms; }

.parachute-active .paragraphs-item-featured .featured-group.in-view.sixty-fourty .field-name-field-description {
  transition-delay: 200ms; }

.parachute-active .paragraphs-item-featured .featured-group.in-view.sixty-fourty .small-bg {
  transition-delay: 300ms; }

.parachute-active .paragraphs-item-featured .featured-group.in-view.sixty-fourty .large-bg {
  transition-delay: 100ms; }

/* -------------------- layout/menu -------------------- */
a:focus {
  outline: none; }

a::-moz-focus-inner {
  border: 0; }

#svg-menu-wrap {
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 0%;
  overflow: hidden;
  z-index: 200; }

#svg-menu {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 200; }

#offscreen-menu {
  height: 100%;
  margin-top: 114px;
  margin-left: 60px;
  position: relative;
  z-index: 300; }

@media (max-height: 768px) and (min-width: 767px) {
  #offscreen-menu {
    margin-top: 33px; } }

.offscreen-menu-inner,
.offscreen-menu-inner > .row,
.offscreen-menu-inner > .row > div,
.offscreen-menu-inner > .row > div > div {
  position: relative;
  height: 100%; }

.admin-menu #offscreen-menu {
  margin-top: 144px; }

@media screen and (max-width: 783px) {
  #offscreen-menu {
    margin-top: 50px;
    margin-left: 30px; }
  .admin-menu #offscreen-menu {
    margin-top: 80px; } }

@media screen and (max-width: 767px) {
  #offscreen-menu {
    margin-top: 44px; } }

#offscreen-menu-overlay {
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 600ms; }
  #offscreen-menu-overlay.active {
    opacity: 0.4; }

.menu-about-us-blurb {
  color: #989898;
  display: none;
  float: left;
  line-height: 26px;
  margin-top: 8px;
  width: 180px; }
  .menu-about-us-blurb a,
  .menu-about-us-blurb a:visited {
    color: #989898; }
    .menu-about-us-blurb a:hover,
    .menu-about-us-blurb a:visited:hover {
      color: #cfcfcf; }
  .menu-about-us-blurb img {
    margin-bottom: 20px; }
  .menu-about-us-blurb p {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 14px; }
  .menu-about-us-blurb p:first-child {
    margin-bottom: 10px; }

@media screen and (max-width: 783px) {
  .menu-about-us-blurb {
    display: none !important; } }

.mos-main-menu {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  display: none;
  float: left;
  font-size: 36px;
  margin-left: 60px;
  margin-top: -4px;
  text-transform: uppercase; }
  .mos-main-menu a {
    color: #e8e8e8;
    text-decoration: none; }
    .mos-main-menu a:hover {
      color: #b5b5b5;
      text-decoration: none; }
    .mos-main-menu a.active {
      color: #e8e8e8;
      text-decoration: none; }
    .mos-main-menu a.svg-applied {
      display: inline-block;
      text-indent: -9999px; }
      .mos-main-menu a.svg-applied svg {
        float: left;
        fill: #e8e8e8; }
  .mos-main-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-right: 50px; }
  .mos-main-menu li.leaf {
    list-style: none;
    list-style-image: none;
    list-style-type: none;
    margin: 0;
    padding: 4px 0 5px 0;
    text-align: left; }

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .mos-main-menu {
    margin-left: 60px; } }

@media screen and (max-width: 783px) {
  .mos-main-menu {
    font-size: 24px;
    margin-top: 40px;
    margin-left: 70px; }
    .mos-main-menu li.leaf {
      padding-bottom: 10px;
      padding-top: 0;
      text-align: left; }
    .mos-main-menu a.svg-applied {
      text-indent: 0; }
      .mos-main-menu a.svg-applied svg {
        display: none; } }

.mos-sub-menu {
  margin-top: 44px;
  margin-left: 70px;
  display: none;
  float: left;
  clear: left; }
  .mos-sub-menu:before {
    content: "";
    height: 2px;
    width: 35px;
    background: #888;
    display: block;
    position: relative;
    top: -25px;
    left: 0; }
  .mos-sub-menu ul {
    padding-left: 0; }
    .mos-sub-menu ul li {
      list-style-type: none;
      list-style-image: none;
      padding: 0;
      margin: 0;
      margin-bottom: 8px; }
      .mos-sub-menu ul li a {
        color: #ffffff;
        font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 18px;
        font-weight: 500; }
        .mos-sub-menu ul li a:hover {
          color: #fff; }

@media screen and (max-width: 783px) {
  .mos-sub-menu {
    margin-left: 70px; } }

.mos-circa {
  display: none;
  position: fixed;
  bottom: 47px; }
  .mos-circa p {
    color: #989898;
    font-size: 10px;
    font-weight: 700; }

@media screen and (max-width: 783px) {
  .mos-circa {
    display: none !important; } }

.circa-mobile {
  font-size: 14px; }
  .circa-mobile a,
  .circa-mobile a:visited {
    color: #989898 !important; }

@media screen and (min-width: 784px) {
  .circa-mobile {
    display: none !important; } }

@media screen and (max-width: 783px) {
  .circa-mobile {
    display: none;
    padding-top: 0px;
    padding-left: 0;
    position: absolute;
    left: 10px;
    top: 350px;
    text-align: right; }
    .circa-mobile p {
      color: #989898;
      font-size: 12px; }
      .circa-mobile p:last-child {
        font-size: 10px;
        opacity: 0.5; } }

.menu-overlay-click-area {
  position: fixed;
  width: 100%;
  height: 3000px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 600;
  transform: translateX(647px) rotate(7.5deg);
  display: none;
  opacity: 0; }

.mos-menu-open .menu-overlay-click-area {
  opacity: 1;
  display: block; }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .menu-overlay-click-area {
    transform: translateX(431px) rotate(7.5deg); } }

@media screen and (max-width: 768px) {
  .menu-overlay-click-area {
    transform: translateX(114px) rotate(4.8deg); } }

.menu-overlay {
  display: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
  transition: opacity 600ms ease-in-out; }

.mos-menu-open .menu-overlay {
  display: block;
  opacity: 1; }

/* -------------------- layout/header -------------------- */
#header {
  position: relative; }
  #header:after {
    background: #e8e8e8;
    bottom: -260px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }

@media screen and (max-width: 767px) {
  #header:after {
    background-clip: padding-box;
    border-top: 1px solid transparent; } }

.page-node-56 #header {
  position: relative; }
  .page-node-56 #header:after {
    background: #e8e8e8;
    bottom: -260px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }

#mos-logo {
  right: 50px;
  opacity: 1;
  position: fixed;
  top: 50px;
  transform: translateX(0px);
  transition: transform 800ms ease-in-out, opacity 800ms ease-in-out;
  z-index: 400; }
  #mos-logo img {
    width: 130px; }

.admin-menu #mos-logo {
  top: 80px; }

.logo-hide:not(.mos-menu-open) #mos-logo {
  opacity: 0;
  transition: transform 700ms ease-in-out, opacity 400ms ease-in-out; }

.mos-menu-open #mos-logo {
  opacity: 0.6; }

@media screen and (max-width: 783px) {
  .mos-menu-open #mos-logo {
    opacity: 0;
    transform: translateX(0px); } }

@media screen and (max-width: 767px) {
  #mos-logo {
    right: 30px;
    top: 30px; }
    #mos-logo img {
      width: 110px; }
  .admin-menu #mos-logo {
    top: 30px; } }

.menu-icon {
  position: fixed;
  left: 50px;
  top: 54px;
  z-index: 1000; }
  .menu-icon img {
    width: 45px; }
  .menu-icon .menu-icon__open {
    opacity: 1;
    position: absolute;
    transition: opacity 400ms; }
  .menu-icon .menu-icon__close {
    opacity: 0;
    position: absolute;
    top: -6px;
    left: 5px;
    transition: opacity 400ms; }
    .menu-icon .menu-icon__close img {
      width: 35px; }
  .menu-icon.active .menu-icon__open {
    opacity: 0; }
  .menu-icon.active .menu-icon__close {
    opacity: 1; }

.backgroundblendmode.not-android .menu-icon {
  mix-blend-mode: difference; }

.admin-menu .menu-icon {
  top: 80px; }

.menu-icon__wrapper {
  height: 23px !important;
  right: 50px !important; }

@media screen and (max-width: 767px) {
  .admin-menu .menu-icon {
    top: 30px; }
  .menu-icon {
    top: 30px;
    left: 30px; }
  .front.logo-hide:not(.mos-menu-open) .menu-icon,
  .front.logo-hide:not(.mos-menu-close) .menu-icon {
    top: 30px;
    transition: top 0.5s ease-in-out; } }

.menu-icon + .secondary-menu {
  position: fixed;
  top: 52px;
  left: 100px;
  z-index: 1000;
  mix-blend-mode: difference; }
  .menu-icon + .secondary-menu h2, .menu-icon + .secondary-menu .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .menu-icon + .secondary-menu p {
    display: none; }
  .menu-icon + .secondary-menu .menu {
    padding-left: 0;
    list-style-type: none; }
    .menu-icon + .secondary-menu .menu li {
      display: inline-block;
      margin: 0 0 0 45px; }
    .menu-icon + .secondary-menu .menu li a {
      color: #fff;
      font-size: 12px;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
      font-weight: 700;
      text-transform: uppercase; }
  @media screen and (max-width: 600px) {
    .menu-icon + .secondary-menu {
      display: none; } }

.admin-menu .menu-icon + .secondary-menu {
  top: 78px; }

#scroll-icon {
  position: absolute;
  bottom: 80px;
  margin: 0 auto;
  width: 100%; }
  #scroll-icon img {
    display: block;
    margin: 0 auto; }

@media screen and (max-width: 767px) {
  .page-node-246 #scroll-icon {
    display: none; } }

#header-ribbon {
  position: fixed;
  bottom: -100px;
  width: 100%;
  z-index: 2;
  color: #e7f8ed;
  background-color: #2acc71;
  opacity: 1;
  text-align: center;
  padding: 8px 40px;
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  font-weight: 500;
  min-height: 36px;
  transition: bottom 500ms ease; }
  #header-ribbon span {
    color: #e7f8ed;
    font-weight: bold; }
  #header-ribbon span.dismiss {
    display: block;
    position: absolute;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: url(../images/icon-close-ribbon.png) center center no-repeat;
    background-size: contain;
    right: 16px;
    top: 50%;
    transform: translateY(-50%); }

body.show-ribbon #header-ribbon {
  bottom: 0; }

@media screen and (max-width: 767px) {
  #header-ribbon {
    text-align: left;
    padding-left: 16px;
    font-size: 13px; } }

@media screen and (max-width: 600px) {
  #header-ribbon {
    font-size: 11px; } }

/* -------------------- layout/banner -------------------- */
.home-page-banner-image {
  height: calc(100vh - 63px);
  min-height: 622px;
  max-height: 950px;
  position: relative;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  color: #e1e1e1;
  background-position: 0 0 !important; }
  .home-page-banner-image h1 {
    font-size: 50px; }
  .home-page-banner-image .mos-logomark-alt {
    padding-bottom: 40px; }
  .home-page-banner-image .green {
    margin: 18px 0 35px 0;
    font-size: 32px;
    font-weight: bold; }
    .home-page-banner-image .green a {
      color: #fff;
      text-decoration: underline; }
  .home-page-banner-image .border-button, .home-page-banner-image .node-article.node-teaser .links a, .node-article.node-teaser .links .home-page-banner-image a,
  .home-page-banner-image .node-news.node-teaser .links a, .node-news.node-teaser .links .home-page-banner-image a {
    cursor: pointer;
    border-color: #fff;
    color: #fff; }
    .home-page-banner-image .border-button:hover, .home-page-banner-image .node-article.node-teaser .links a:hover, .node-article.node-teaser .links .home-page-banner-image a:hover,
    .home-page-banner-image .node-news.node-teaser .links a:hover, .node-news.node-teaser .links .home-page-banner-image a:hover {
      color: #171717;
      background: #e8e8e8;
      border-color: #e8e8e8; }
    .home-page-banner-image .border-button:first-of-type, .home-page-banner-image .node-article.node-teaser .links a:first-of-type, .node-article.node-teaser .links .home-page-banner-image a:first-of-type,
    .home-page-banner-image .node-news.node-teaser .links a:first-of-type, .node-news.node-teaser .links .home-page-banner-image a:first-of-type {
      margin-right: 45px; }
  .home-page-banner-image video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    background-size: cover; }
  .home-page-banner-image #acquia-foundation {
    position: absolute;
    bottom: 110px;
    right: 48px;
    max-width: 255px; }

@media screen and (max-width: 767px) {
  .home-page-banner-image {
    min-height: 0; }
    .home-page-banner-image h1 {
      font-size: 40px; }
    .home-page-banner-image .green {
      font-size: 16px; }
    .home-page-banner-image .mos-logomark-alt {
      padding-bottom: 20px;
      width: 80px; }
    .home-page-banner-image #acquia-foundation {
      bottom: 70px;
      right: 30px;
      width: 21px; } }

@media screen and (max-width: 499px) {
  .home-page-banner-image h1 {
    font-size: 32px; }
  .home-page-banner-image .border-button, .home-page-banner-image .node-article.node-teaser .links a, .node-article.node-teaser .links .home-page-banner-image a,
  .home-page-banner-image .node-news.node-teaser .links a, .node-news.node-teaser .links .home-page-banner-image a {
    display: block;
    max-width: 300px;
    margin: 20px auto !important; }
    .home-page-banner-image .border-button:first-of-type, .home-page-banner-image .node-article.node-teaser .links a:first-of-type, .node-article.node-teaser .links .home-page-banner-image a:first-of-type,
    .home-page-banner-image .node-news.node-teaser .links a:first-of-type, .node-news.node-teaser .links .home-page-banner-image a:first-of-type {
      margin-right: inherit; } }

.home-page-banner-message {
  color: #f8f8f8;
  position: absolute;
  text-align: center;
  top: 40%;
  transform: translate(0%, -50%);
  width: 100%; }

@media screen and (max-width: 424px) {
  .home-page-banner-message {
    top: 40%; }
  .home-page-banner-image h1 {
    font-size: 24px; } }

.scroll-animation {
  background: url(/sites/all/themes/motionstrand/assets/images/down-arrow-frames-9.png) left center;
  width: 4px;
  height: 60px;
  animation: play 2s steps(60) infinite;
  margin: 0 auto; }

@keyframes play {
  100% {
    background-position: -240px; } }

.interior-page-banner {
  color: #f8f8f8;
  height: calc(100vh - 63px);
  min-height: 622px;
  max-height: 950px;
  position: relative;
  width: 100%;
  z-index: 0;
  overflow: hidden; }
  .interior-page-banner video {
    position: relative;
    top: 49%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover; }

.interior-page-banner-title {
  position: absolute;
  text-align: center;
  top: 54%;
  transform: translate(0, -50%);
  width: 100%;
  opacity: 0;
  transition: opacity 600ms; }
  .interior-page-banner-title h1 {
    margin: 0;
    line-height: 1em; }
  .interior-page-banner-title .banner-subtitle {
    font-weight: bold;
    margin-top: 20px;
    font-size: 16px;
    display: block; }
  .interior-page-banner-title .banner-copy {
    max-width: 600px;
    margin: 20px auto;
    padding: 0 15px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; }
    .interior-page-banner-title .banner-copy p {
      margin-bottom: 20px; }
    .interior-page-banner-title .banner-copy a {
      color: inherit;
      font-weight: 700; }

.mobile .interior-page-banner-title,
.parachute-active .interior-page-banner-title {
  opacity: 1; }

@media screen and (max-width: 768px) {
  .interior-page-banner {
    height: calc(100vh - 50px);
    min-height: 0; }
  .interior-page-banner-title {
    top: 50%; } }

#block-block-21,
#taxonomy-term-page-banner {
  background: url(/sites/all/themes/motionstrand/assets/images/banner-news.jpg) #777 center center no-repeat;
  background-size: cover;
  color: #f8f8f8;
  height: calc(100vh - 63px);
  min-height: 622px;
  max-height: 950px;
  position: relative;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  #block-block-21 h1,
  #taxonomy-term-page-banner h1 {
    margin: 0;
    line-height: 1em; }
  #block-block-21 .banner-subtitle,
  #taxonomy-term-page-banner .banner-subtitle {
    font-weight: bold;
    margin-top: 20px;
    font-size: 16px;
    display: block; }

.view-thank-you-interior-page-banner {
  height: 600px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0; }
  .view-thank-you-interior-page-banner .thank-you-alt-logo {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%); }

@media screen and (min-width: 1200px) {
  .views-field-field-banner-image img {
    width: 100%; } }

.page-node-56 #banner {
  max-height: 780px; }

#banner-map {
  height: 600px;
  width: 100%; }

@media screen and (max-width: 767px) {
  #banner-map {
    height: 540px; } }

#map {
  height: 100%;
  width: 100%; }

.gm-style p {
  font-family: "Roboto Slab", georgia, serif;
  font-weight: 700;
  font-style: normal;
  color: #989898;
  font-size: 14px;
  line-height: 1.2em;
  text-align: center; }

.gm-style h4 {
  line-height: 1.2em;
  margin: 0 0 14px 0;
  text-align: center; }

.interior-page-banner.nid-291 .banner-copy {
  max-width: 945px;
  padding: 0; }

.interior-page-banner.nid-291 .interior-page-banner-title {
  top: 35%; }

/* -------------------- layout/content -------------------- */
.front .node p {
  font-size: 24px;
  text-align: left; }

.front .paragraphs-item-logo-grid p {
  font-size: 14px;
  text-align: center; }

.front #header:after {
  background: #fff !important; }

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 60px, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.front .home-page-banner-message {
  position: relative; }
  .front .home-page-banner-message h1 {
    text-transform: none;
    font-size: 42px;
    font-weight: 700;
    line-height: 62px;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeIn;
    animation-delay: 300ms;
    opacity: 0; }
    .front .home-page-banner-message h1 a {
      color: #fff;
      text-decoration: underline; }
  .front .home-page-banner-message .home-banner-ctas {
    position: relative;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeIn;
    animation-delay: 300ms;
    opacity: 0;
    padding-top: 30px; }
  .front .home-page-banner-message a {
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    color: #fff;
    padding: 10px 30px;
    text-decoration: underline; }
    .front .home-page-banner-message a:hover {
      color: #2acc71; }

.front #block-block-26 {
  margin-top: -300px;
  margin-bottom: -300px; }

.front .homepage-feature {
  position: relative;
  padding: 0;
  transition: transform 1.5s ease-in-out, opacity 600ms ease-in-out;
  max-width: 1020px;
  margin: 0 auto; }
  .front .homepage-feature .col-sm-12,
  .front .homepage-feature .col-sm-6 {
    padding: 0; }
  .front .homepage-feature .health-wrapper,
  .front .homepage-feature .life-wrapper {
    padding: 10% 5%;
    height: 510px;
    opacity: 0;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 600ms ease-in-out, opacity 600ms ease-in-out; }
    .front .homepage-feature .health-wrapper:before,
    .front .homepage-feature .life-wrapper:before {
      content: "";
      display: block;
      height: 510px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: url("/sites/all/themes/motionstrand/assets/images/feature-overlay-blue.png");
      opacity: 0;
      pointer-events: none;
      transition: all 800ms cubic-bezier(0.23, 1, 0.32, 1); }
    .front .homepage-feature .health-wrapper h3,
    .front .homepage-feature .health-wrapper p,
    .front .homepage-feature .health-wrapper a,
    .front .homepage-feature .life-wrapper h3,
    .front .homepage-feature .life-wrapper p,
    .front .homepage-feature .life-wrapper a {
      color: #fff !important;
      text-align: center;
      text-transform: none !important;
      font-weight: 700 !important;
      transition: all 800ms cubic-bezier(0.23, 1, 0.32, 1); }
    .front .homepage-feature .health-wrapper h3,
    .front .homepage-feature .life-wrapper h3 {
      margin-bottom: 30px;
      font-size: 44px;
      line-height: 54px;
      position: relative;
      top: 60px; }
    .front .homepage-feature .health-wrapper p,
    .front .homepage-feature .life-wrapper p {
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
      font-weight: 500;
      font-size: 14px; }
    .front .homepage-feature .health-wrapper .desc,
    .front .homepage-feature .life-wrapper .desc {
      padding: 0 20px;
      position: relative;
      text-align: center;
      line-height: 26px;
      margin-top: 15px;
      top: 40px;
      width: 100%;
      opacity: 0; }
    .front .homepage-feature .health-wrapper a,
    .front .homepage-feature .life-wrapper a {
      position: absolute;
      left: 50%;
      bottom: 23%;
      transform: translateX(-50%); }
      .front .homepage-feature .health-wrapper a img,
      .front .homepage-feature .life-wrapper a img {
        position: relative;
        max-width: 34px;
        margin-left: 17px; }
    .front .homepage-feature .health-wrapper.active:before,
    .front .homepage-feature .life-wrapper.active:before {
      opacity: 1; }
    .front .homepage-feature .health-wrapper.active h3,
    .front .homepage-feature .life-wrapper.active h3 {
      top: 10px; }
    .front .homepage-feature .health-wrapper.active .desc,
    .front .homepage-feature .life-wrapper.active .desc {
      top: 0;
      opacity: 1; }
  .front .homepage-feature .life-wrapper:before {
    background-size: 510px 510px;
    background: url("/sites/all/themes/motionstrand/assets/images/feature-overlay-green.png"); }
  .front .homepage-feature .health-wrapper {
    top: 100px;
    background: url("/sites/all/themes/motionstrand/assets/images/mos-health-feature.png");
    padding: 8% 5%; }
    .front .homepage-feature .health-wrapper img {
      top: -3px; }
  .front .homepage-feature .life-wrapper {
    background: url("/sites/all/themes/motionstrand/assets/images/mos-life-feature.png");
    top: 140px;
    transition-delay: 300ms; }
    .front .homepage-feature .life-wrapper img {
      transform: rotate(180deg);
      top: -2px; }
  .front .homepage-feature.in-view .health-wrapper,
  .front .homepage-feature.in-view .life-wrapper {
    opacity: 1; }
  .front .homepage-feature.in-view .health-wrapper {
    transform: translateY(-100px); }
  .front .homepage-feature.in-view .life-wrapper {
    transform: translateY(-155px); }

@media screen and (max-width: 767px) {
  .front .home-page-banner-message h1 {
    padding-left: 40px;
    padding-right: 40px;
    font-size: 24px;
    line-height: 32px; }
    .front .home-page-banner-message h1 br {
      display: none; }
  .front .home-page-banner-message a {
    display: block;
    text-align: center;
    font-size: 16px; }
  .front .home-page-banner-message .home-banner-ctas {
    padding-top: 20px; }
  .front #block-block-26 {
    margin-top: 0;
    margin-bottom: 0; }
  .front .homepage-feature .health-wrapper,
  .front .homepage-feature .life-wrapper {
    opacity: 1;
    height: 375px;
    background-size: 375px; }
    .front .homepage-feature .health-wrapper.active:before,
    .front .homepage-feature .life-wrapper.active:before {
      opacity: 1;
      height: 375px;
      background-size: 375px; }
  .front .homepage-feature .health-wrapper {
    transform: translateY(-130px); }
  .front .homepage-feature .life-wrapper {
    transform: translateY(-182px); } }

.front .home-page-banner-message p {
  max-width: 640px;
  margin-top: 14px;
  padding: 0;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  margin: 0 auto 30px; }

.front .home-page-banner-message a {
  color: #000;
  font-size: 14px;
  height: 50px;
  border-radius: 25px;
  display: block;
  padding: 12px 38px;
  margin: 0 auto;
  max-width: 230px;
  text-align: left;
  background: url(../images/icon_heartbeat_white@2x.png) no-repeat top 16px right 40px #fff;
  background-size: 34px 17px;
  line-height: 26px;
  text-decoration: none; }
  .front .home-page-banner-message a:hover {
    color: #000; }

@media (max-width: 767px) {
  .front .home-page-banner-message a {
    height: 44px;
    border-radius: 22px;
    font-size: 13px;
    max-width: 220px;
    background-position: top 13px right 40px; } }

.node-type-job-posting .field-name-body p {
  line-height: 1.8em; }

.node-type-job-posting .field-name-body h2, .node-type-job-posting .field-name-body .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-type-job-posting .field-name-body p {
  text-align: center;
  margin-bottom: 80px; }

.node-type-job-posting .field-name-body h3 {
  font-size: 16px;
  text-transform: none;
  font-family: "Roboto Slab", georgia, serif; }

.node-type-job-posting .field-name-body p,
.node-type-job-posting .field-name-body li {
  color: #626262; }

.node-type-job-posting .field-name-body ul li {
  font-size: 16px;
  padding: 10px 0; }

.page-node-done #header:after {
  bottom: -267px !important; }

.page-node-done #main-wrapper:after {
  background: #26ac62 !important; }

.page-node-88 #header,
.node-type-job-posting #header {
  position: relative; }
  .page-node-88 #header:after,
  .node-type-job-posting #header:after {
    background: #f8f8f8 !important;
    bottom: -260px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }

.page-node-88 #main-wrapper,
.node-type-job-posting #main-wrapper {
  background: #f8f8f8;
  position: relative; }
  .page-node-88 #main-wrapper:after,
  .node-type-job-posting #main-wrapper:after {
    background: #e8e8e8 !important;
    bottom: -275px !important;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .page-node-88 #main-wrapper .field-name-body .available-opps,
  .node-type-job-posting #main-wrapper .field-name-body .available-opps {
    font-weight: bold;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 100px;
    cursor: pointer; }
    .page-node-88 #main-wrapper .field-name-body .available-opps p:after,
    .node-type-job-posting #main-wrapper .field-name-body .available-opps p:after {
      margin: auto;
      position: relative;
      top: 50px;
      content: "";
      display: block;
      width: 50px;
      height: 2px;
      background: #171717; }

.page-node-88 #below-content .region-below-content,
.node-type-job-posting #below-content .region-below-content {
  background: #e8e8e8; }

.page-node-88 #below-content .view-job-posting-forms form,
.node-type-job-posting #below-content .view-job-posting-forms form {
  max-width: 530px;
  margin: auto; }
  .page-node-88 #below-content .view-job-posting-forms form .form-item.webform-component-textfield,
  .page-node-88 #below-content .view-job-posting-forms form .form-item.webform-component-email,
  .node-type-job-posting #below-content .view-job-posting-forms form .form-item.webform-component-textfield,
  .node-type-job-posting #below-content .view-job-posting-forms form .form-item.webform-component-email {
    background-color: #dedede;
    max-width: 530px;
    margin: 13px auto;
    padding: 0; }
    .page-node-88 #below-content .view-job-posting-forms form .form-item.webform-component-textfield input,
    .page-node-88 #below-content .view-job-posting-forms form .form-item.webform-component-email input,
    .node-type-job-posting #below-content .view-job-posting-forms form .form-item.webform-component-textfield input,
    .node-type-job-posting #below-content .view-job-posting-forms form .form-item.webform-component-email input {
      padding: 13px 25px 13px 25px;
      margin: 0;
      font-size: 16px;
      transition: border 0.5s; }
    .page-node-88 #below-content .view-job-posting-forms form .form-item.webform-component-textfield label,
    .page-node-88 #below-content .view-job-posting-forms form .form-item.webform-component-email label,
    .node-type-job-posting #below-content .view-job-posting-forms form .form-item.webform-component-textfield label,
    .node-type-job-posting #below-content .view-job-posting-forms form .form-item.webform-component-email label {
      font-size: 16px;
      font-weight: normal;
      color: #888;
      padding: 20px 25px 0 25px;
      cursor: text; }
  .page-node-88 #below-content .view-job-posting-forms form textarea,
  .node-type-job-posting #below-content .view-job-posting-forms form textarea {
    padding-top: 0;
    border: 0;
    margin: 0; }
  .page-node-88 #below-content .view-job-posting-forms form .form-actions,
  .node-type-job-posting #below-content .view-job-posting-forms form .form-actions {
    clear: both;
    border-top: 1px solid #c8c8c8; }
    .page-node-88 #below-content .view-job-posting-forms form .form-actions input,
    .node-type-job-posting #below-content .view-job-posting-forms form .form-actions input {
      color: #fff;
      font-size: 1em;
      margin: 20px auto;
      width: 50%;
      background: #c8c8c8;
      border: none;
      border-radius: 3px;
      display: block; }
  .page-node-88 #below-content .view-job-posting-forms form .file-field-wrapper,
  .node-type-job-posting #below-content .view-job-posting-forms form .file-field-wrapper {
    padding: 0; }
    .page-node-88 #below-content .view-job-posting-forms form .file-field-wrapper .messages.error,
    .node-type-job-posting #below-content .view-job-posting-forms form .file-field-wrapper .messages.error {
      display: none; }
  .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper,
  .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper {
    padding-right: 13px; }
  .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper,
  .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper {
    padding-left: 13px; }
  .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper,
  .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper,
  .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper,
  .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper {
    margin: auto; }
    .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file #edit-submitted-resume-cv-remove-button,
    .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file #edit-submitted-cover-letter-remove-button,
    .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file #edit-submitted-resume-cv-remove-button,
    .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file #edit-submitted-cover-letter-remove-button,
    .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file #edit-submitted-resume-cv-remove-button,
    .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file #edit-submitted-cover-letter-remove-button,
    .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file #edit-submitted-resume-cv-remove-button,
    .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file #edit-submitted-cover-letter-remove-button {
      border-bottom: none;
      font-size: 10px; }
    .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label,
    .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label,
    .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label,
    .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label {
      cursor: pointer;
      color: #fff;
      text-align: center;
      width: 100%;
      padding: 20px 40px;
      border-radius: 3px;
      background: #fe4535;
      position: relative !important;
      transition: all 0.25s; }
      .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label:hover,
      .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label:hover,
      .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label:hover,
      .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label:hover {
        background: #d8262e; }
      .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label:before,
      .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label:before,
      .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label:before,
      .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label:before {
        content: "";
        background: url(../images/paperclip.png) no-repeat;
        width: 16px;
        height: 16px;
        display: inline-block;
        position: relative;
        top: 3px;
        left: -10px;
        transition: all 0.5s; }
      .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label:after,
      .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label:after,
      .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label:after,
      .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label:after {
        content: "";
        background: url(../images/checkmark.icon.png) no-repeat;
        width: 16px;
        height: 16px;
        display: inline-block;
        position: relative;
        top: 3px;
        left: -10px;
        opacity: 0;
        transition: all 0.5s; }
      .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.error,
      .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.error,
      .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.error,
      .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.error {
        font-size: 0;
        background: #d8262e; }
        .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.error:before,
        .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.error:before,
        .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.error:before,
        .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.error:before {
          background: url(../images/error.icon.x.png) no-repeat;
          width: 22px;
          height: 22px;
          left: initial; }
        .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.error:after,
        .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.error:after,
        .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.error:after,
        .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.error:after {
          background: none;
          width: 0; }
      .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.file-selected,
      .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.file-selected,
      .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.file-selected,
      .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.file-selected {
        background: #26ac62; }
        .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.file-selected:before,
        .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.file-selected:before,
        .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.file-selected:before,
        .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.file-selected:before {
          left: 0;
          opacity: 0; }
        .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.file-selected:after,
        .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.file-selected:after,
        .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.file-selected:after,
        .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.file-selected:after {
          left: 10px;
          opacity: 1; }
      .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label span,
      .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label span,
      .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label span,
      .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label span {
        color: #fff; }
    .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file .form-managed-file,
    .page-node-88 #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file .form-managed-file,
    .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper .webform-component-file .form-managed-file,
    .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper .webform-component-file .form-managed-file {
      display: none; }

.page-node-88 #below-content #block-views-job-list-job-list-block,
.node-type-job-posting #below-content #block-views-job-list-job-list-block {
  margin-top: 100px;
  padding-bottom: 90px;
  background: #e8e8e8;
  position: relative; }
  .page-node-88 #below-content #block-views-job-list-job-list-block:after,
  .node-type-job-posting #below-content #block-views-job-list-job-list-block:after {
    background: #171717 !important;
    bottom: -375px !important;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .page-node-88 #below-content #block-views-job-list-job-list-block .view-content,
  .node-type-job-posting #below-content #block-views-job-list-job-list-block .view-content {
    max-width: 840px;
    margin: auto;
    text-align: center; }
    .page-node-88 #below-content #block-views-job-list-job-list-block .view-content .col-sm-6,
    .node-type-job-posting #below-content #block-views-job-list-job-list-block .view-content .col-sm-6 {
      margin-bottom: 100px; }
      .page-node-88 #below-content #block-views-job-list-job-list-block .view-content .col-sm-6:nth-child(1),
      .node-type-job-posting #below-content #block-views-job-list-job-list-block .view-content .col-sm-6:nth-child(1) {
        padding-right: 50px; }
      .page-node-88 #below-content #block-views-job-list-job-list-block .view-content .col-sm-6:nth-child(2),
      .node-type-job-posting #below-content #block-views-job-list-job-list-block .view-content .col-sm-6:nth-child(2) {
        padding-left: 50px; }
    .page-node-88 #below-content #block-views-job-list-job-list-block .view-content p,
    .node-type-job-posting #below-content #block-views-job-list-job-list-block .view-content p {
      line-height: 1.6em;
      color: #626262; }
    .page-node-88 #below-content #block-views-job-list-job-list-block .view-content a,
    .node-type-job-posting #below-content #block-views-job-list-job-list-block .view-content a {
      color: #626262;
      font-weight: bold;
      font-size: 16px;
      display: block;
      margin-top: 40px;
      transition: color 0.25s; }
      .page-node-88 #below-content #block-views-job-list-job-list-block .view-content a:hover,
      .node-type-job-posting #below-content #block-views-job-list-job-list-block .view-content a:hover {
        color: #26ac62; }

.page-node-88 #below-content #block-views-job-posting-forms-block-1,
.page-node-88 #below-content #block-block-8,
.node-type-job-posting #below-content #block-views-job-posting-forms-block-1,
.node-type-job-posting #below-content #block-block-8 {
  margin-top: 100px;
  padding-bottom: 90px;
  background: #e8e8e8;
  position: relative; }
  .page-node-88 #below-content #block-views-job-posting-forms-block-1:after,
  .page-node-88 #below-content #block-block-8:after,
  .node-type-job-posting #below-content #block-views-job-posting-forms-block-1:after,
  .node-type-job-posting #below-content #block-block-8:after {
    background: #171717 !important;
    bottom: -375px !important;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .page-node-88 #below-content #block-views-job-posting-forms-block-1 h2, .page-node-88 #below-content #block-views-job-posting-forms-block-1 .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-views-job-posting-forms-block-1 p,
  .page-node-88 #below-content #block-block-8 h2,
  .page-node-88 #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p,
  .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-block-8 p,
  .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 h2,
  .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 .paragraphs-item-quote .field-name-field-quote p,
  .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 p,
  .node-type-job-posting #below-content #block-block-8 h2,
  .node-type-job-posting #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p,
  .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-block-8 p {
    margin-top: 215px;
    font-size: 60px;
    color: #171717;
    text-align: center;
    cursor: pointer; }
    .page-node-88 #below-content #block-views-job-posting-forms-block-1 h2 .dots, .page-node-88 #below-content #block-views-job-posting-forms-block-1 .paragraphs-item-quote .field-name-field-quote p .dots, .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-views-job-posting-forms-block-1 p .dots,
    .page-node-88 #below-content #block-block-8 h2 .dots,
    .page-node-88 #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p .dots,
    .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-block-8 p .dots,
    .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 h2 .dots,
    .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 .paragraphs-item-quote .field-name-field-quote p .dots,
    .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 p .dots,
    .node-type-job-posting #below-content #block-block-8 h2 .dots,
    .node-type-job-posting #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p .dots,
    .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-block-8 p .dots {
      margin-top: -18px; }
      .page-node-88 #below-content #block-views-job-posting-forms-block-1 h2 .dots .dot, .page-node-88 #below-content #block-views-job-posting-forms-block-1 .paragraphs-item-quote .field-name-field-quote p .dots .dot, .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-views-job-posting-forms-block-1 p .dots .dot,
      .page-node-88 #below-content #block-block-8 h2 .dots .dot,
      .page-node-88 #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p .dots .dot,
      .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-block-8 p .dots .dot,
      .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 h2 .dots .dot,
      .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 .paragraphs-item-quote .field-name-field-quote p .dots .dot,
      .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 p .dots .dot,
      .node-type-job-posting #below-content #block-block-8 h2 .dots .dot,
      .node-type-job-posting #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p .dots .dot,
      .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-block-8 p .dots .dot {
        display: inline-block;
        margin: 0;
        width: 16px;
        height: 5px;
        background: #171717;
        transition: all 0.15s; }
        .page-node-88 #below-content #block-views-job-posting-forms-block-1 h2 .dots .dot.active, .page-node-88 #below-content #block-views-job-posting-forms-block-1 .paragraphs-item-quote .field-name-field-quote p .dots .dot.active, .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-views-job-posting-forms-block-1 p .dots .dot.active,
        .page-node-88 #below-content #block-block-8 h2 .dots .dot.active,
        .page-node-88 #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p .dots .dot.active,
        .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-block-8 p .dots .dot.active,
        .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 h2 .dots .dot.active,
        .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 .paragraphs-item-quote .field-name-field-quote p .dots .dot.active,
        .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 p .dots .dot.active,
        .node-type-job-posting #below-content #block-block-8 h2 .dots .dot.active,
        .node-type-job-posting #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p .dots .dot.active,
        .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-block-8 p .dots .dot.active {
          margin: 0 3px;
          width: 6px;
          height: 6px;
          background: #171717;
          border-radius: 50%; }
    .page-node-88 #below-content #block-views-job-posting-forms-block-1 h2:hover .dots .dot, .page-node-88 #below-content #block-views-job-posting-forms-block-1 .paragraphs-item-quote .field-name-field-quote p:hover .dots .dot, .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-views-job-posting-forms-block-1 p:hover .dots .dot,
    .page-node-88 #below-content #block-block-8 h2:hover .dots .dot,
    .page-node-88 #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p:hover .dots .dot,
    .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-block-8 p:hover .dots .dot,
    .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 h2:hover .dots .dot,
    .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 .paragraphs-item-quote .field-name-field-quote p:hover .dots .dot,
    .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 p:hover .dots .dot,
    .node-type-job-posting #below-content #block-block-8 h2:hover .dots .dot,
    .node-type-job-posting #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p:hover .dots .dot,
    .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-block-8 p:hover .dots .dot {
      margin: 0 3px;
      width: 6px;
      height: 6px;
      background: #171717;
      border-radius: 50%; }
  .page-node-88 #below-content #block-views-job-posting-forms-block-1 .webform-client-form,
  .page-node-88 #below-content #block-block-8 .webform-client-form,
  .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 .webform-client-form,
  .node-type-job-posting #below-content #block-block-8 .webform-client-form {
    position: relative;
    overflow: hidden;
    display: none; }
    .page-node-88 #below-content #block-views-job-posting-forms-block-1 .webform-client-form .desc-text,
    .page-node-88 #below-content #block-block-8 .webform-client-form .desc-text,
    .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 .webform-client-form .desc-text,
    .node-type-job-posting #below-content #block-block-8 .webform-client-form .desc-text {
      margin-top: 20px;
      margin-bottom: 30px;
      display: block;
      text-align: center; }
      .page-node-88 #below-content #block-views-job-posting-forms-block-1 .webform-client-form .desc-text .remove,
      .page-node-88 #below-content #block-block-8 .webform-client-form .desc-text .remove,
      .node-type-job-posting #below-content #block-views-job-posting-forms-block-1 .webform-client-form .desc-text .remove,
      .node-type-job-posting #below-content #block-block-8 .webform-client-form .desc-text .remove {
        color: #26ac62;
        text-decoration: underline;
        cursor: pointer; }

.page-node-88 #below-content #block-block-8,
.node-type-job-posting #below-content #block-block-8 {
  color: #e8e8e8;
  min-height: 310px;
  background: #171717;
  position: relative; }
  .page-node-88 #below-content #block-block-8:after,
  .node-type-job-posting #below-content #block-block-8:after {
    background: #26ac62 !important;
    bottom: -275px !important;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  
  .page-node-88 #below-content #block-block-8 h2,
  .page-node-88 #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p,
  .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-block-8 p,
  .node-type-job-posting #below-content #block-block-8 h2,
  .node-type-job-posting #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p,
  .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-block-8 p {
    margin-top: 250px; }
    .page-node-88 #below-content #block-block-8 h2 a, .page-node-88 #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p a, .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-block-8 p a,
    .node-type-job-posting #below-content #block-block-8 h2 a,
    .node-type-job-posting #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p a,
    .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-block-8 p a {
      color: #e8e8e8; }
    
    .page-node-88 #below-content #block-block-8 h2 .dots .dot,
    .page-node-88 #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p .dots .dot,
    .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-block-8 p .dots .dot,
    .node-type-job-posting #below-content #block-block-8 h2 .dots .dot,
    .node-type-job-posting #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p .dots .dot,
    .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-block-8 p .dots .dot {
      background: #e8e8e8; }
    
    .page-node-88 #below-content #block-block-8 h2:hover .dots .dot,
    .page-node-88 #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p:hover .dots .dot,
    .paragraphs-item-quote .field-name-field-quote .page-node-88 #below-content #block-block-8 p:hover .dots .dot,
    .node-type-job-posting #below-content #block-block-8 h2:hover .dots .dot,
    .node-type-job-posting #below-content #block-block-8 .paragraphs-item-quote .field-name-field-quote p:hover .dots .dot,
    .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #below-content #block-block-8 p:hover .dots .dot {
      background: #e8e8e8; }

.page-node-88 .field-name-body .field-item .col-sm-6.left-col,
.page-node-97 .field-name-body .field-item .col-sm-6.left-col {
  padding-right: 50px; }

.page-node-88 .field-name-body .field-item .col-sm-6.right-col,
.page-node-97 .field-name-body .field-item .col-sm-6.right-col {
  padding-left: 50px; }

.join-our-team {
  clear: both;
  padding: 115px 0 390px;
  background: #171717;
  position: relative;
  position: relative; }
  .join-our-team:after {
    background: #26ac62 !important;
    bottom: -200px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .join-our-team:before {
    background: #171717;
    left: 0;
    bottom: calc(100% - 200px);
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .join-our-team .content {
    position: relative;
    z-index: 1; }
  .join-our-team h1 {
    text-align: center;
    cursor: pointer; }
    .join-our-team h1 a {
      color: #e8e8e8; }
      .join-our-team h1 a:after {
        margin: auto;
        position: relative;
        top: 30px;
        content: "";
        display: block;
        width: 26px;
        height: 3px;
        background: #e8e8e8; }

@media screen and (max-width: 767px) {
  .page-node-88 .field-name-body .field-item .col-sm-6.left-col,
  .page-node-88 .field-name-body .field-item .col-sm-6.right-col,
  .page-node-97 .field-name-body .field-item .col-sm-6.left-col,
  .page-node-97 .field-name-body .field-item .col-sm-6.right-col {
    padding-right: 0;
    padding-left: 0; }
  .page-node-88 .view-job-list .col-sm-6,
  .page-node-97 .view-job-list .col-sm-6 {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

.page-node-54 .field-name-body p,
.page-node-85 .field-name-body p {
  max-width: 760px;
  margin: 0 auto 10px auto; }

@media screen and (max-width: 767px) {
  .node-type-job-posting #block-views-job-posting-forms-block-1 h2, .node-type-job-posting #block-views-job-posting-forms-block-1 .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #block-views-job-posting-forms-block-1 p {
    font-size: 30px !important; }
    .node-type-job-posting #block-views-job-posting-forms-block-1 h2 .dots, .node-type-job-posting #block-views-job-posting-forms-block-1 .paragraphs-item-quote .field-name-field-quote p .dots, .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #block-views-job-posting-forms-block-1 p .dots {
      margin-top: -5px !important; }
  .node-type-job-posting #block-block-8 h2, .node-type-job-posting #block-block-8 .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #block-block-8 p {
    font-size: 30px !important;
    padding: 0 30px; }
    .node-type-job-posting #block-block-8 h2 .dots, .node-type-job-posting #block-block-8 .paragraphs-item-quote .field-name-field-quote p .dots, .paragraphs-item-quote .field-name-field-quote .node-type-job-posting #block-block-8 p .dots {
      margin-top: -5px !important; }
  .node-type-job-posting #below-content .view-job-posting-forms form {
    padding: 0 15px; }
  .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-resume-cv-ajax-wrapper {
    padding-right: 0; }
  .node-type-job-posting #below-content .view-job-posting-forms form #edit-submitted-cover-letter-ajax-wrapper {
    padding-left: 0; }
  .front .node p {
    font-size: 18px;
    line-height: 24px; } }

#main {
  padding-top: 100px;
  padding-bottom: 90px; }

@media screen and (max-width: 767px) {
  #main {
    padding-top: 70px;
    padding-bottom: 20px; }
    #main .col-md-10 {
      padding: 0 30px; } }

.not-front h2, .not-front .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .not-front p {
  margin-bottom: 24px; }

@media screen and (max-width: 767px) {
  .not-front .node {
    padding: 0 15px; } }

.page-node-86 #main h1 {
  margin-top: 0; }

.work-with-us-form {
  background: #f8f8f8;
  padding-top: 10px;
  padding-bottom: 180px;
  position: relative; }
  .work-with-us-form:before {
    background: #f8f8f8;
    left: 0;
    bottom: calc(100% - 200px);
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .work-with-us-form > h2, .paragraphs-item-quote .field-name-field-quote .work-with-us-form > p {
    margin-bottom: 60px;
    font-size: 44px;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    position: relative;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      .work-with-us-form > h2, .paragraphs-item-quote .field-name-field-quote .work-with-us-form > p {
        font-size: 28px; } }
  .work-with-us-form .content {
    position: relative;
    z-index: 1; }
  .work-with-us-form form > div {
    display: flex;
    flex-wrap: wrap;
    max-width: 680px;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      .work-with-us-form form > div {
        display: block; } }
  .work-with-us-form .form-item {
    margin-top: 10px;
    margin-bottom: 10px; }
  .work-with-us-form .form-item input,
  .work-with-us-form .form-item textarea {
    color: #888;
    padding: 8px 20px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    background: #eee;
    border: none;
    border-radius: 0;
    display: block; }
    .work-with-us-form .form-item input::placeholder,
    .work-with-us-form .form-item textarea::placeholder {
      color: #888; }
  .work-with-us-form .form-item input,
  .work-with-us-form .form-item select {
    height: 50px; }
  .work-with-us-form .form-item textarea {
    height: 70px;
    resize: none; }
  .work-with-us-form .resizable-textarea .grippie {
    border: none; }
  .work-with-us-form .webform-component--body {
    max-width: 640px;
    margin: 0 auto;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: 500;
    line-height: 26px;
    text-align: center; }
    .work-with-us-form .webform-component--body p {
      font-size: 14px; }
    @media screen and (max-width: 768px) {
      .work-with-us-form .webform-component--body p {
        font-size: 13px;
        line-height: 23px; } }
    .work-with-us-form .webform-component--body .required {
      color: #888;
      font-size: 12px; }
    .work-with-us-form .webform-component--body .required span {
      color: #ee352e; }
  .work-with-us-form .webform-component-select select {
    color: #888;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    margin: 0;
    padding: 8px 20px 13px 20px;
    line-height: 36px;
    background-color: #eee;
    background-image: url(../images/icon-chevron-down.svg);
    background-position: 97% center;
    background-repeat: no-repeat;
    background-size: 15px;
    border: none;
    border-radius: 3px;
    display: block;
    appearance: none; }
  .work-with-us-form .webform-component-select select:focus {
    outline: none; }
  .work-with-us-form .webform-component-select select::-ms-expand {
    display: none;
    /* hide the default arrow in ie10 and ie11 */ }
  .work-with-us-form .webform-component-select option {
    font-weight: 500; }
  .work-with-us-form .webform-component {
    flex: 1 100%;
    padding: 0 10px; }
    .work-with-us-form .webform-component.webform-component--body {
      padding: 0 30px; }
    @media screen and (max-width: 768px) {
      .work-with-us-form .webform-component {
        padding: 0 30px; } }
  .work-with-us-form .form-item.flex-40 {
    flex: 1 40%; }
  .work-with-us-form .form-item.flex-50 {
    flex: 1 50%; }
  .work-with-us-form .form-item.flex-60 {
    flex: 1 60%; }
  .work-with-us-form .element-invisible {
    margin: 0;
    padding: 0; }
  .work-with-us-form .captcha {
    padding: 10px; }
    @media screen and (max-width: 768px) {
      .work-with-us-form .captcha {
        padding: 0 30px 10px; } }
  .work-with-us-form #edit-submitted-industry {
    max-width: 420px; }
  .work-with-us-form .form-actions {
    padding: 5px 10px;
    flex: 1 auto; }
    @media screen and (max-width: 768px) {
      .work-with-us-form .form-actions {
        margin: 0;
        padding: 0 10px; } }
    .work-with-us-form .form-actions .submit-wrapper {
      position: relative;
      max-width: 150px;
      float: right;
      background-color: #888; }
      @media screen and (max-width: 768px) {
        .work-with-us-form .form-actions .submit-wrapper {
          float: none;
          margin: 0 20px; } }
    .work-with-us-form .form-actions .icon {
      position: absolute;
      top: 50%;
      right: 20px;
      display: block;
      width: 33px;
      height: 8px;
      margin-left: 20px;
      background-image: url(../images/icon-submit-button.svg);
      background-repeat: no-repeat;
      background-position: center;
      transform: translateY(-50%);
      z-index: 0; }
    .work-with-us-form .form-actions .webform-submit {
      position: relative;
      width: 150px;
      margin: 0;
      padding: 8px 18px;
      border: 0;
      background-color: transparent;
      color: #fff;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      z-index: 1; }

@media screen and (max-width: 600px) {
  .messages.error {
    word-wrap: break-word; } }

.page-node-88 .region-below-content .block {
  position: relative; }

.page-node-88 .webform-client-form-88 {
  clear: both;
  max-width: 530px;
  margin: auto; }
  .page-node-88 .webform-client-form-88 .form-item.webform-component-textfield,
  .page-node-88 .webform-client-form-88 .form-item.webform-component-email,
  .page-node-88 .webform-client-form-88 .form-item.webform-component-phone {
    background-color: #dedede;
    max-width: 530px;
    margin: 13px auto;
    padding: 0; }
    .page-node-88 .webform-client-form-88 .form-item.webform-component-textfield input,
    .page-node-88 .webform-client-form-88 .form-item.webform-component-email input,
    .page-node-88 .webform-client-form-88 .form-item.webform-component-phone input {
      padding: 13px 25px 13px 25px;
      margin: 0;
      font-size: 16px;
      transition: border 0.5s; }
    .page-node-88 .webform-client-form-88 .form-item.webform-component-textfield label,
    .page-node-88 .webform-client-form-88 .form-item.webform-component-email label,
    .page-node-88 .webform-client-form-88 .form-item.webform-component-phone label {
      font-size: 16px;
      font-weight: normal;
      color: #888;
      padding: 20px 25px 0 25px;
      cursor: text; }
  .page-node-88 .webform-client-form-88 textarea {
    padding-top: 0;
    border: 0;
    margin: 0; }
  .page-node-88 .webform-client-form-88 .form-actions {
    clear: both;
    border-top: 1px solid #c8c8c8; }
    .page-node-88 .webform-client-form-88 .form-actions input {
      color: #fff;
      font-size: 1em;
      margin: 20px auto;
      width: 50%;
      background: #c8c8c8;
      border: none;
      border-radius: 3px;
      display: block; }
  .page-node-88 .webform-client-form-88 .file-field-wrapper {
    padding: 0; }
    .page-node-88 .webform-client-form-88 .file-field-wrapper .messages.error {
      display: none; }
  .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper {
    padding-right: 13px; }
  .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper {
    padding-left: 13px; }
  .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper,
  .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper {
    margin: auto; }
    .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file #edit-submitted-resume-cv-remove-button,
    .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file #edit-submitted-cover-letter-remove-button,
    .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file #edit-submitted-resume-cv-remove-button,
    .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file #edit-submitted-cover-letter-remove-button {
      border-bottom: none;
      font-size: 10px; }
    .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label,
    .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label {
      cursor: pointer;
      color: #fff;
      text-align: center;
      width: 100%;
      padding: 20px 40px;
      border-radius: 3px;
      background: #fe4535;
      position: relative !important;
      transition: all 0.25s; }
      .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label:hover,
      .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label:hover {
        background: #d8262e; }
      .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label:before,
      .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label:before {
        content: "";
        background: url(../images/paperclip.png) no-repeat;
        width: 16px;
        height: 16px;
        display: inline-block;
        position: relative;
        top: 3px;
        left: -10px;
        transition: all 0.5s; }
      .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label:after,
      .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label:after {
        content: "";
        background: url(../images/checkmark.icon.png) no-repeat;
        width: 16px;
        height: 16px;
        display: inline-block;
        position: relative;
        top: 3px;
        left: -10px;
        opacity: 0;
        transition: all 0.5s; }
      .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.error,
      .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.error {
        font-size: 0;
        background: #d8262e; }
        .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.error:before,
        .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.error:before {
          background: url(../images/error.icon.x.png) no-repeat;
          width: 22px;
          height: 22px;
          left: initial; }
        .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.error:after,
        .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.error:after {
          background: none;
          width: 0; }
      .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.file-selected,
      .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.file-selected {
        background: #26ac62; }
        .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.file-selected:before,
        .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.file-selected:before {
          left: 0;
          opacity: 0; }
        .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label.file-selected:after,
        .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label.file-selected:after {
          left: 10px;
          opacity: 1; }
      .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file label span,
      .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file label span {
        color: #fff; }
    .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper .webform-component-file .form-managed-file,
    .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper .webform-component-file .form-managed-file {
      display: none; }

.page-node-88 .webform-client-form {
  position: relative;
  overflow: hidden;
  display: none; }
  .page-node-88 .webform-client-form .desc-text {
    margin-top: 20px;
    margin-bottom: 30px;
    display: block;
    text-align: center; }
    .page-node-88 .webform-client-form .desc-text .remove {
      color: #26ac62;
      text-decoration: underline;
      cursor: pointer; }

.page-node-88 #block-views-job-posting-forms-block-1 {
  display: none; }

.page-node-88 #block-block-31 {
  position: relative; }
  .page-node-88 #block-block-31:after {
    background: #171717 !important;
    bottom: -375px !important;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }

@media screen and (max-width: 767px) {
  .page-node-88 .webform-client-form-88 {
    padding: 0 15px; }
  .page-node-88 .webform-client-form-88 #edit-submitted-resume-cv-ajax-wrapper {
    padding-right: 0; }
  .page-node-88 .webform-client-form-88 #edit-submitted-cover-letter-ajax-wrapper {
    padding-left: 0; }
  .page-node-88 .front .node p {
    font-size: 18px;
    line-height: 24px; } }

/* -------------------- layout/featured work -------------------- */
#featured-work-header {
  padding: 64px 0 136px;
  text-align: center; }
  #featured-work-header h3 {
    font-family: "Roboto Slab", georgia, serif;
    font-weight: 400;
    font-style: normal;
    color: #989898;
    margin-top: 20px;
    text-transform: none; }

@media screen and (max-width: 767px) {
  #featured-work-header {
    padding: 54px 0 50px; }
    #featured-work-header h1 {
      margin-bottom: 8px; }
    #featured-work-header h3 {
      margin-top: 8px; } }

.featured-work {
  padding-top: 280px;
  padding-bottom: 380px;
  position: relative; }
  .featured-work:first-child {
    padding-top: 0; }
  .featured-work .lrg {
    overflow: hidden;
    position: relative; }
    .featured-work .lrg .work-desc {
      color: #e8e8e8;
      padding: 0 50px;
      position: absolute;
      text-align: center;
      top: 42%;
      width: 100%;
      transition: top 800ms cubic-bezier(0.23, 1, 0.32, 1); }
      .featured-work .lrg .work-desc .desc {
        opacity: 0;
        transition: opacity 800ms cubic-bezier(0.23, 1, 0.32, 1); }
      .featured-work .lrg .work-desc.active .desc {
        opacity: 1; }
  .featured-work .rec,
  .featured-work .sm,
  .featured-work .sm-logo {
    position: relative; }

.featured-work__wrapper {
  position: relative; }

.featured-work__collage {
  position: relative;
  z-index: 1; }

@media screen and (max-width: 767px) {
  .featured-work__collage {
    padding-top: 40px; } }

.featured-work__collage-wrapper {
  margin: 0 auto;
  max-width: 1380px;
  padding: 0 120px; }

@media screen and (max-width: 767px) {
  .featured-work__collage-wrapper {
    padding: 0 30px; } }

.parachute-active .featured-work__collage {
  top: 100px; }

.parachute-active .lrg,
.parachute-active .rec,
.parachute-active .sm,
.parachute-active .sm-logo {
  opacity: 0;
  top: 100px;
  transform: translateY(0);
  transition: transform 600ms ease-in-out, opacity 600ms ease-in-out; }

.parachute-active .featured-work.in-view .lrg,
.parachute-active .featured-work.in-view .rec,
.parachute-active .featured-work.in-view .sm,
.parachute-active .featured-work.in-view .sm-logo {
  opacity: 1;
  transform: translateY(-100px); }

.parachute-active #featured-work-1.in-view .rec {
  transition-delay: 100ms; }

.parachute-active #featured-work-1.in-view .sm {
  transition-delay: 200ms; }

.parachute-active #featured-work-1.in-view .sm-logo {
  transition-delay: 300ms; }

.parachute-active #featured-work-2.in-view .lrg {
  transition-delay: 100ms; }

.parachute-active #featured-work-2.in-view .rec {
  transition-delay: 200ms; }

.parachute-active #featured-work-2.in-view .sm-logo {
  transition-delay: 300ms; }

.parachute-active #featured-work-3.in-view .lrg {
  transition-delay: 100ms; }

.parachute-active #featured-work-3.in-view .sm {
  transition-delay: 200ms; }

.parachute-active #featured-work-3.in-view .sm-logo {
  transition-delay: 300ms; }

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .featured-work {
    padding-bottom: 400px; } }

@media screen and (max-width: 767px) {
  .featured-work {
    padding-bottom: 220px; }
    .featured-work:last-child {
      padding-bottom: 280px; }
    .featured-work .lrg {
      top: 0; }
    .featured-work .lrg.mobile-active .title {
      display: none; }
    .featured-work .lrg.mobile-active .desc {
      display: block; }
    .featured-work .lrg .work-desc {
      height: 100%;
      position: absolute;
      top: 0;
      transform: none;
      width: 100%; }
      .featured-work .lrg .work-desc .title {
        position: absolute;
        left: 0;
        text-align: center;
        top: 49%;
        width: 100%;
        transition: opacity 800ms cubic-bezier(0.23, 1, 0.32, 1); }
      .featured-work .lrg .work-desc .desc {
        padding: 0 20px;
        position: absolute;
        left: 0;
        text-align: center;
        top: 50%;
        width: 100%;
        transition: opacity 800ms cubic-bezier(0.23, 1, 0.32, 1); }
        .featured-work .lrg .work-desc .desc p {
          font-size: 12px; }
        .featured-work .lrg .work-desc .desc.active {
          opacity: 1; }
      .featured-work .lrg .work-desc.active .title {
        opacity: 0; }
      .featured-work .lrg .work-desc.active .desc {
        opacity: 1; }
  .parachute-active .featured-work .lrg {
    top: 100px; } }

.featured-work__alt-description {
  color: #e8e8e8;
  margin: 80px 0 60px;
  position: absolute;
  text-align: center;
  top: 560px;
  width: 100%;
  z-index: 2; }
  .featured-work__alt-description h3,
  .featured-work__alt-description h4 {
    font-family: "Roboto Slab", georgia, serif;
    font-weight: 700;
    font-style: normal;
    text-transform: none; }
  .featured-work__alt-description a,
  .featured-work__alt-description a:visited {
    color: #e8e8e8;
    text-decoration: none; }
    .featured-work__alt-description a:hover,
    .featured-work__alt-description a:visited:hover {
      color: #c2c2c2; }

@media screen and (max-width: 767px) {
  .featured-work__alt-description {
    padding: 0 15px;
    margin: 40px 0 60px; } }

.parachute-active .featured-work__alt-description {
  top: 660px; }

.featured-work__background {
  height: 700px;
  position: absolute;
  top: 50%;
  width: 100%; }

@media screen and (max-width: 767px) {
  .featured-work__background {
    height: 500px; } }

#featured-work-1 .col-md-6 {
  float: left;
  padding: 0; }

#featured-work-1 .lrg,
#featured-work-1 .rec,
#featured-work-1 .rec-alt {
  width: 100%; }

#featured-work-1 .sm,
#featured-work-1 .sm-logo {
  float: left;
  width: 50%; }

#featured-work-1 img {
  width: 100%; }

#featured-work-1 .rec-tablet {
  display: none; }

#featured-work-1 {
  position: relative; }
  #featured-work-1:after {
    background: #e8e8e8;
    bottom: -260px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }

@media screen and (min-width: 768px) and (max-width: 991px) {
  #featured-work-1 .sm,
  #featured-work-1 .sm-logo {
    width: 100%; } }

@media screen and (min-width: 767px) and (max-width: 1024px) {
  #featured-work-1 .col-sm-8,
  #featured-work-1 .col-sm-4 {
    float: left;
    padding: 0; }
  #featured-work-1 .col-sm-12 {
    padding: 0; } }

@media screen and (min-width: 992px) and (max-width: 1024px) {
  #featured-work-1 .sm,
  #featured-work-1 .sm-logo {
    width: 50%; } }

@media screen and (max-width: 767px) {
  #featured-work-1 {
    position: relative; }
    #featured-work-1:after {
      background: #e8e8e8;
      bottom: -280px;
      content: '';
      height: 300px;
      overflow: visible;
      position: absolute;
      transform: skewY(1.75deg);
      width: 100%;
      overflow-x: hidden;
      z-index: 0;
      -webkit-backface-visibility: hidden; } }

#featured-work-2 .col-md-3,
#featured-work-2 .col-md-6 {
  padding: 0; }

#featured-work-2 img {
  width: 100%; }

#featured-work-2 .sm-alt {
  padding: 0; }
  #featured-work-2 .sm-alt .sm,
  #featured-work-2 .sm-alt .sm-logo {
    float: left;
    width: 50%; }

#featured-work-2 {
  position: relative; }
  #featured-work-2:after {
    background: #e8e8e8;
    bottom: -260px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }

@media screen and (max-width: 767px) {
  #featured-work-2 {
    position: relative;
    padding-top: 70px; }
    #featured-work-2:after {
      background: #e8e8e8;
      bottom: -280px;
      content: '';
      height: 300px;
      overflow: visible;
      position: absolute;
      transform: skewY(-1.75deg);
      width: 100%;
      overflow-x: hidden;
      z-index: 0;
      -webkit-backface-visibility: hidden; } }

#featured-work-3 .col-md-6 {
  float: right;
  padding: 0; }

#featured-work-3 .lrg,
#featured-work-3 .rec,
#featured-work-3 .rec-alt {
  width: 100%; }

#featured-work-3 .sm,
#featured-work-3 .sm-logo {
  float: left;
  width: 50%; }

#featured-work-3 img {
  width: 100%; }

#featured-work-3 {
  position: relative; }
  #featured-work-3:after {
    background: #e8e8e8;
    bottom: -260px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }

@media screen and (min-width: 767px) and (max-width: 1024px) {
  #featured-work-3 .col-sm-8,
  #featured-work-3 .col-sm-4 {
    float: right; }
  #featured-work-3 .col-sm-12 {
    padding: 0; }
  #featured-work-3 .sm,
  #featured-work-3 .sm-logo {
    width: 100%; } }

@media screen and (min-width: 992px) and (max-width: 1024px) {
  #featured-work-3 .sm,
  #featured-work-3 .sm-logo {
    width: 50%; } }

@media screen and (max-width: 767px) {
  #featured-work-3 {
    position: relative;
    padding-top: 70px; }
    #featured-work-3:after {
      background: #e8e8e8;
      bottom: -220px;
      content: '';
      height: 300px;
      overflow: visible;
      position: absolute;
      transform: skewY(1.75deg);
      width: 100%;
      overflow-x: hidden;
      z-index: 0;
      -webkit-backface-visibility: hidden; } }

@media screen and (max-width: 991px) {
  .featured-work__background {
    height: 840px; } }

@media screen and (max-width: 767px) {
  .featured-work__background {
    height: 680px; } }

/* -------------------- layout/client logos -------------------- */
#client-logo-list-header {
  padding: 110px 0 220px;
  position: relative; }
  #client-logo-list-header:after {
    background: #171717;
    bottom: -245px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  #client-logo-list-header h1 {
    margin-top: 0;
    margin-bottom: 18px;
    text-align: center;
    font-size: 44px;
    font-weight: 700;
    text-transform: none; }
  #client-logo-list-header h3 {
    color: #000000;
    text-align: center;
    text-transform: none;
    font-size: 14px;
    font-weight: 500; }

@media screen and (max-width: 767px) {
  #client-logo-list-header {
    position: relative;
    padding: 0 0 120px; }
    #client-logo-list-header:after {
      background: #171717;
      bottom: -245px;
      content: '';
      height: 300px;
      overflow: visible;
      position: absolute;
      transform: skewY(1.75deg);
      width: 100%;
      overflow-x: hidden;
      z-index: 0;
      -webkit-backface-visibility: hidden; }
    #client-logo-list-header h1 {
      font-size: 24px;
      margin-bottom: 10px; }
    #client-logo-list-header h3 {
      font-size: 12px;
      margin-top: 10px; } }

.not-front #client-logo-list-header {
  display: none; }

.not-front #main-wrapper {
  position: relative; }
  .not-front #main-wrapper:after {
    background: #171717;
    bottom: -400px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }

@media screen and (max-width: 767px) {
  .not-front #main-wrapper:after {
    bottom: -370px; } }

#client-logo-list-grid {
  background: #171717;
  padding: 100px 0 200px; }
  #client-logo-list-grid .client-logo-list-grid-inner {
    padding-left: 140px;
    padding-right: 140px; }
  #client-logo-list-grid .client-logo-list-grid-inner > .row > div {
    padding-left: 0;
    padding-right: 0; }
    #client-logo-list-grid .client-logo-list-grid-inner > .row > div img {
      opacity: 0.6;
      transition: opacity 300ms; }
      #client-logo-list-grid .client-logo-list-grid-inner > .row > div img:hover {
        opacity: 1; }
  #client-logo-list-grid img {
    width: 100%; }
  #client-logo-list-grid .show-more img {
    display: block;
    height: 23px;
    margin: 40px auto 0;
    transform: rotate(0);
    width: 23px;
    transition: rotate 1000ms cubic-bezier(0.23, 1, 0.32, 1); }
    #client-logo-list-grid .show-more img.active {
      transform: rotate(45deg); }

#client-logo-list-grid {
  position: relative; }
  #client-logo-list-grid:after {
    background: #26ac62;
    bottom: -240px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }

@media screen and (min-width: 767px) and (max-width: 1024px) {
  #client-logo-list-grid .client-logo-list-grid-inner {
    padding-left: 30px;
    padding-right: 30px; } }

@media screen and (max-width: 767px) {
  #client-logo-list-grid {
    padding: 0 0 160px; }
    #client-logo-list-grid .client-logo-list-grid-inner {
      padding-left: 30px;
      padding-right: 30px; }
    #client-logo-list-grid .col-xs-6 {
      margin-top: 0; } }

.not-front #client-logo-list-grid {
  margin-top: 160px; }

@media screen and (max-width: 767px) {
  .not-front #client-logo-list-grid {
    margin-top: 130px; } }

.interior-page-banner-title .about-us-subtitle {
  font-weight: bold;
  margin-top: 23px;
  font-size: 16px;
  display: block; }

/* -------------------- layout/about us -------------------- */
.field-name-body .col-sm-6 p:last-child {
  color: #626262; }

.field-name-body .meet-the-team {
  font-weight: bold;
  text-align: center;
  margin-top: 80px;
  cursor: pointer;
  margin-bottom: 110px; }
  .field-name-body .meet-the-team p:after {
    margin: auto;
    position: relative;
    top: 50px;
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background: #171717; }

#block-views-about-us-team-block {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e8e8e8+0,171717+2,171717+100,171717+100 */
  background: #171717;
  /* Old browsers */
  background: -moz-linear-gradient(top, #e8e8e8 0%, #171717 2%, #171717 100%, #171717 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e8e8e8 0%, #171717 2%, #171717 100%, #171717 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e8e8e8 0%, #171717 2%, #171717 100%, #171717 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e8e8', endColorstr='#171717',GradientType=0 );
  /* IE6-9 */
  transform: skewY(-1.75deg);
  backface-visibility: hidden;
  outline: 1px solid transparent;
  overflow: hidden; }
  #block-views-about-us-team-block .views-row {
    position: relative;
    height: 365px;
    overflow: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    outline: 1px solid transparent; }
    #block-views-about-us-team-block .views-row .image {
      position: absolute;
      top: -10%;
      left: -10%;
      height: 120%;
      width: 120%;
      transform: skewY(1.75deg);
      backface-visibility: hidden; }
      #block-views-about-us-team-block .views-row .image .image-inner {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
        #block-views-about-us-team-block .views-row .image .image-inner img {
          width: 100%;
          height: auto !important;
          backface-visibility: hidden;
          transform: translateZ(0);
          image-rendering: pixelated; }
    #block-views-about-us-team-block .views-row .copy {
      position: absolute;
      top: -3%;
      left: -3%;
      padding: 20px;
      height: 106%;
      width: 106%;
      background: #000;
      background: rgba(8, 8, 8, 0.62);
      text-align: center;
      color: #fff;
      opacity: 0;
      transition: opacity 300ms ease; }
      #block-views-about-us-team-block .views-row .copy .copy-inner {
        opacity: 0;
        position: relative;
        top: 50%;
        transform: skewY(1.75deg) translateY(-50%);
        transition: opacity 300ms ease;
        transition-delay: 200ms;
        cursor: default; }
      #block-views-about-us-team-block .views-row .copy h4 {
        font-family: "Roboto Slab", georgia, serif;
        text-transform: none; }
    #block-views-about-us-team-block .views-row:hover .copy, #block-views-about-us-team-block .views-row:active .copy, #block-views-about-us-team-block .views-row:focus .copy {
      opacity: 1; }
      #block-views-about-us-team-block .views-row:hover .copy .copy-inner, #block-views-about-us-team-block .views-row:active .copy .copy-inner, #block-views-about-us-team-block .views-row:focus .copy .copy-inner {
        opacity: 1; }
    #block-views-about-us-team-block .views-row:last-of-type a {
      width: 100%;
      height: 100%; }
    #block-views-about-us-team-block .views-row:last-of-type .copy-inner {
      cursor: pointer; }
      #block-views-about-us-team-block .views-row:last-of-type .copy-inner .name:after {
        content: '?'; }
  #block-views-about-us-team-block .view-footer {
    position: relative;
    top: 23px;
    clear: both;
    padding: 230px 0;
    transform: skewY(1.75deg);
    background: #171717; }
    #block-views-about-us-team-block .view-footer h1 {
      text-align: center;
      cursor: pointer; }
      #block-views-about-us-team-block .view-footer h1 a {
        color: #e8e8e8; }
        #block-views-about-us-team-block .view-footer h1 a:after {
          margin: auto;
          position: relative;
          top: 30px;
          content: '';
          display: block;
          width: 26px;
          height: 3px;
          background: #e8e8e8; }

.is-android #block-views-about-us-team-block {
  transform: none; }
  .is-android #block-views-about-us-team-block .views-row .image {
    transform: none; }
  .is-android #block-views-about-us-team-block .views-row .copy .copy-inner {
    transform: translateY(-50%); }
  .is-android #block-views-about-us-team-block .view-footer {
    transform: none; }

.page-node-97 #above-footer {
  margin-top: -100px;
  padding-top: 200px; }

@media screen and (max-width: 1250px) {
  #block-views-about-us-team-block .views-row .image .image-inner img {
    width: auto;
    height: 375px !important;
    position: relative;
    left: 50%;
    transform: translateX(-50%); } }

@media screen and (max-width: 767px) {
  #block-views-about-us-team-block .views-row {
    margin-top: 0;
    /*.copy {
      top:0;
      left:0;
      height:100%;
    }*/ }
    #block-views-about-us-team-block .views-row .image .image-inner img {
      height: 400px !important; } }

@media screen and (max-width: 425px) {
  #block-views-about-us-team-block .views-row {
    height: 275px !important; }
    #block-views-about-us-team-block .views-row .image .image-inner img {
      height: 300px !important; } }

/* IE10+ specific styles go here */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #block-views-about-us-team-block {
    transform: none; }
    #block-views-about-us-team-block .views-row .image {
      transform: none; }
    #block-views-about-us-team-block .views-row .copy .copy-inner {
      transform: none;
      top: 37%; } }

#block-views-our-work-block,
#block-views-our-work-archive {
  padding-bottom: 250px; }
  #block-views-our-work-block > h2, .paragraphs-item-quote .field-name-field-quote #block-views-our-work-block > p,
  #block-views-our-work-archive > h2,
  .paragraphs-item-quote .field-name-field-quote #block-views-our-work-archive > p {
    position: relative;
    padding-top: 60px;
    padding-bottom: 90px;
    text-align: center; }
  #block-views-our-work-block .content,
  #block-views-our-work-archive .content {
    transform: translateY(-10px); }
  #block-views-our-work-block .view,
  #block-views-our-work-archive .view {
    position: relative;
    transform: translateY(-350px);
    margin: 0 auto 0;
    width: 100%;
    max-width: 1380px;
    padding: 0 120px; }
  #block-views-our-work-block .view-content,
  #block-views-our-work-archive .view-content {
    position: relative;
    background: #171717;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    overflow: hidden;
    transform: skewY(-1.75deg);
    z-index: 0; }
  #block-views-our-work-block .text-blocks,
  #block-views-our-work-archive .text-blocks {
    position: absolute;
    top: 0;
    max-width: 1020px;
    margin: 0 auto;
    z-index: 1; }
    #block-views-our-work-block .text-blocks .copy,
    #block-views-our-work-archive .text-blocks .copy {
      width: 33%;
      height: 100%;
      text-align: center;
      cursor: pointer; }
      #block-views-our-work-block .text-blocks .copy .copy-inner,
      #block-views-our-work-archive .text-blocks .copy .copy-inner {
        opacity: 0;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity 300ms ease;
        transition-delay: 200ms;
        padding: 0 18%;
        color: #f8f8f8; }
      #block-views-our-work-block .text-blocks .copy h2, #block-views-our-work-block .text-blocks .copy .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote #block-views-our-work-block .text-blocks .copy p,
      #block-views-our-work-archive .text-blocks .copy h2,
      #block-views-our-work-archive .text-blocks .copy .paragraphs-item-quote .field-name-field-quote p,
      .paragraphs-item-quote .field-name-field-quote #block-views-our-work-archive .text-blocks .copy p {
        margin-bottom: 60px;
        line-height: 1em; }
        #block-views-our-work-block .text-blocks .copy h2 a, #block-views-our-work-block .text-blocks .copy .paragraphs-item-quote .field-name-field-quote p a, .paragraphs-item-quote .field-name-field-quote #block-views-our-work-block .text-blocks .copy p a,
        #block-views-our-work-archive .text-blocks .copy h2 a,
        #block-views-our-work-archive .text-blocks .copy .paragraphs-item-quote .field-name-field-quote p a,
        .paragraphs-item-quote .field-name-field-quote #block-views-our-work-archive .text-blocks .copy p a {
          color: #f8f8f8; }
      #block-views-our-work-block .text-blocks .copy h4,
      #block-views-our-work-archive .text-blocks .copy h4 {
        font-family: "Roboto Slab", georgia, serif;
        font-size: 14px;
        font-weight: 600;
        text-transform: none; }
      #block-views-our-work-block .text-blocks .copy:before,
      #block-views-our-work-archive .text-blocks .copy:before {
        content: '';
        position: absolute;
        top: -2.5%;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        background: #080808;
        transform: skewY(-1.75deg);
        transition: opacity 300ms ease; }
      @media screen and (max-width: 990px) {
        #block-views-our-work-block .text-blocks .copy:before,
        #block-views-our-work-archive .text-blocks .copy:before {
          top: -1.35%; } }
      #block-views-our-work-block .text-blocks .copy:hover:before,
      #block-views-our-work-archive .text-blocks .copy:hover:before {
        opacity: 0.62; }
      #block-views-our-work-block .text-blocks .copy:hover .copy-inner,
      #block-views-our-work-archive .text-blocks .copy:hover .copy-inner {
        opacity: 1; }
  #block-views-our-work-block .views-row,
  #block-views-our-work-archive .views-row {
    position: relative;
    height: 360px;
    padding: 0;
    overflow: hidden;
    background: #e8e8e8;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    outline: 1px solid transparent; }
    #block-views-our-work-block .views-row .image,
    #block-views-our-work-archive .views-row .image {
      position: absolute;
      top: -10%;
      left: -10%;
      height: 120%;
      width: 120%;
      backface-visibility: hidden; }
      #block-views-our-work-block .views-row .image .image-inner,
      #block-views-our-work-archive .views-row .image .image-inner {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
        #block-views-our-work-block .views-row .image .image-inner img,
        #block-views-our-work-archive .views-row .image .image-inner img {
          width: 100%;
          height: auto !important;
          backface-visibility: hidden;
          transform: translateZ(0); }
    #block-views-our-work-block .views-row .copy,
    #block-views-our-work-archive .views-row .copy {
      position: absolute;
      top: -3%;
      left: -3%;
      padding: 0;
      height: 106%;
      width: 106%;
      background: #000;
      background: rgba(8, 8, 8, 0.62);
      text-align: center;
      color: #fff;
      opacity: 0;
      transition: opacity 300ms ease; }
      #block-views-our-work-block .views-row .copy .copy-inner,
      #block-views-our-work-archive .views-row .copy .copy-inner {
        opacity: 0;
        position: relative;
        max-width: 80%;
        margin: 0 auto;
        top: 47%;
        transform: skewY(1.75deg) translateY(-50%);
        transition: opacity 300ms ease;
        transition-delay: 200ms;
        cursor: default; }
      #block-views-our-work-block .views-row .copy h2, #block-views-our-work-block .views-row .copy .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote #block-views-our-work-block .views-row .copy p,
      #block-views-our-work-archive .views-row .copy h2,
      #block-views-our-work-archive .views-row .copy .paragraphs-item-quote .field-name-field-quote p,
      .paragraphs-item-quote .field-name-field-quote #block-views-our-work-archive .views-row .copy p {
        line-height: 1.25em; }
        #block-views-our-work-block .views-row .copy h2 a, #block-views-our-work-block .views-row .copy .paragraphs-item-quote .field-name-field-quote p a, .paragraphs-item-quote .field-name-field-quote #block-views-our-work-block .views-row .copy p a,
        #block-views-our-work-archive .views-row .copy h2 a,
        #block-views-our-work-archive .views-row .copy .paragraphs-item-quote .field-name-field-quote p a,
        .paragraphs-item-quote .field-name-field-quote #block-views-our-work-archive .views-row .copy p a {
          color: #f8f8f8; }
      #block-views-our-work-block .views-row .copy h4,
      #block-views-our-work-archive .views-row .copy h4 {
        font-family: "Roboto Slab", georgia, serif;
        font-size: 14px;
        font-weight: 600;
        text-transform: none; }
    #block-views-our-work-block .views-row .link-overlay,
    #block-views-our-work-archive .views-row .link-overlay {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%; }
    #block-views-our-work-block .views-row:hover .copy, #block-views-our-work-block .views-row:active .copy, #block-views-our-work-block .views-row:focus .copy,
    #block-views-our-work-archive .views-row:hover .copy,
    #block-views-our-work-archive .views-row:active .copy,
    #block-views-our-work-archive .views-row:focus .copy {
      opacity: 1; }
      #block-views-our-work-block .views-row:hover .copy .copy-inner, #block-views-our-work-block .views-row:active .copy .copy-inner, #block-views-our-work-block .views-row:focus .copy .copy-inner,
      #block-views-our-work-archive .views-row:hover .copy .copy-inner,
      #block-views-our-work-archive .views-row:active .copy .copy-inner,
      #block-views-our-work-archive .views-row:focus .copy .copy-inner {
        opacity: 1; }
    #block-views-our-work-block .views-row:last-of-type a,
    #block-views-our-work-archive .views-row:last-of-type a {
      width: 100%;
      height: 100%; }
    #block-views-our-work-block .views-row:last-of-type .copy-inner,
    #block-views-our-work-archive .views-row:last-of-type .copy-inner {
      cursor: pointer; }
      #block-views-our-work-block .views-row:last-of-type .copy-inner .name:after,
      #block-views-our-work-archive .views-row:last-of-type .copy-inner .name:after {
        content: '?'; }

.parachute-active #block-views-our-work-block > h2, .parachute-active .paragraphs-item-quote .field-name-field-quote #block-views-our-work-block > p, .paragraphs-item-quote .field-name-field-quote .parachute-active #block-views-our-work-block > p,
.parachute-active #block-views-our-work-archive > h2,
.parachute-active .paragraphs-item-quote .field-name-field-quote #block-views-our-work-archive > p,
.paragraphs-item-quote .field-name-field-quote .parachute-active #block-views-our-work-archive > p {
  top: 40px; }

#block-views-our-work-block {
  position: relative; }
  #block-views-our-work-block .view {
    transition: transform 1000ms ease-in-out; }

.move-work #block-views-our-work-block .view {
  transform: translateY(0); }

#block-views-our-work-archive {
  position: relative;
  position: relative;
  background-color: #e8e8e8; }
  #block-views-our-work-archive:after {
    background: #171717;
    bottom: -200px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  #block-views-our-work-archive:before {
    background: #e8e8e8;
    left: 0;
    bottom: calc(100% - 200px);
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  #block-views-our-work-archive .view-footer {
    position: relative;
    top: 23px;
    clear: both;
    padding: 230px 0;
    background: #171717; }
    #block-views-our-work-archive .view-footer h1 {
      text-align: center;
      cursor: pointer; }
      #block-views-our-work-archive .view-footer h1 a {
        color: #e8e8e8; }
        #block-views-our-work-archive .view-footer h1 a:after {
          margin: auto;
          position: relative;
          top: 30px;
          content: '';
          display: block;
          width: 26px;
          height: 3px;
          background: #e8e8e8; }

.page-node-106 {
  background: #f8f8f8; }
  .page-node-106 .interior-page-banner-title {
    color: #f8f8f8; }
  .page-node-106 #scroll-icon {
    bottom: 160px; }
  .page-node-106 #page-wrapper {
    background: #f8f8f8; }
  .page-node-106 #header:after {
    background: #f8f8f8; }
  .page-node-106 #main-wrapper:after {
    content: none; }
  .page-node-106 #main-wrapper #main {
    padding-top: 0;
    padding-bottom: 0; }

@media screen and (max-width: 767px) {
  #block-views-our-work-block > h2, .paragraphs-item-quote .field-name-field-quote #block-views-our-work-block > p,
  #block-views-our-work-archive > h2,
  .paragraphs-item-quote .field-name-field-quote #block-views-our-work-archive > p {
    padding-top: 20px;
    padding-bottom: 10px; }
  #block-views-our-work-block .view,
  #block-views-our-work-archive .view {
    top: 50px;
    padding: 0 30px;
    transform: translateY(-200px); }
  #block-views-our-work-block .views-row,
  #block-views-our-work-archive .views-row {
    height: auto;
    margin-top: 0; }
    #block-views-our-work-block .views-row .image,
    #block-views-our-work-archive .views-row .image {
      top: 0; }
      #block-views-our-work-block .views-row .image .image-inner,
      #block-views-our-work-archive .views-row .image .image-inner {
        height: 100%; }
        #block-views-our-work-block .views-row .image .image-inner img,
        #block-views-our-work-archive .views-row .image .image-inner img {
          position: relative;
          left: 50%;
          width: auto;
          height: calc(50vw + 5px) !important;
          transform: translateX(-50%); }
    #block-views-our-work-block .views-row .copy,
    #block-views-our-work-block .views-row .copy-inner,
    #block-views-our-work-archive .views-row .copy,
    #block-views-our-work-archive .views-row .copy-inner {
      padding: 0 !important; }
    #block-views-our-work-block .views-row .copy,
    #block-views-our-work-archive .views-row .copy {
      background: rgba(8, 8, 8, 0.25); }
      #block-views-our-work-block .views-row .copy h2, #block-views-our-work-block .views-row .copy .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote #block-views-our-work-block .views-row .copy p,
      #block-views-our-work-archive .views-row .copy h2,
      #block-views-our-work-archive .views-row .copy .paragraphs-item-quote .field-name-field-quote p,
      .paragraphs-item-quote .field-name-field-quote #block-views-our-work-archive .views-row .copy p {
        font-size: 18px; }
      #block-views-our-work-block .views-row .copy h4,
      #block-views-our-work-archive .views-row .copy h4 {
        font-size: 12px; }
  .page-node-106 #scroll-icon {
    bottom: 80px; } }

.mobile .text-blocks {
  display: none; }

/* IE10+ specific styles go here */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #block-views-our-work-block .views-row {
    background: transparent; }
  #block-views-our-work-block .view-content {
    transform: none; }
  #block-views-our-work-block .text-blocks .copy .copy-inner {
    transform: none;
    top: 30%; }
  #block-views-our-work-block .text-blocks .copy:before {
    transform: none;
    top: 0; }
  #block-views-our-work-archive .views-row {
    background: transparent; }
  #block-views-our-work-archive .view-content {
    transform: none; }
  #block-views-our-work-archive .text-blocks .copy:before {
    transform: none;
    top: 0; } }

/* Edge specific styles go here */
@supports (-ms-ime-align: auto) {
  #block-views-our-work-block .views-row {
    background: transparent; }
  #block-views-our-work-block .view-content {
    transform: none; }
  #block-views-our-work-block .text-blocks .copy:before {
    transform: none;
    top: 0; }
  #block-views-our-work-archive .views-row {
    background: transparent; }
  #block-views-our-work-archive .view-content {
    transform: none; }
  #block-views-our-work-archive .text-blocks .copy:before {
    transform: none;
    top: 0; } }

/* -------------------- Project page -------------------- */
.border-button, .node-article.node-teaser .links a,
.node-news.node-teaser .links a,
.border-button:visited,
.node-article.node-teaser .links a:visited,
.node-news.node-teaser .links a:visited {
  display: inline-block;
  margin: 0 auto;
  padding: 12px 38px;
  border: 3px solid #888;
  font-size: 14px;
  color: #888;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  -webkit-transition: color 300ms ease, border-color 300ms ease, background-color 300ms ease;
  -moz-transition: color 300ms ease, border-color 300ms ease, background-color 300ms ease;
  -ms-transition: color 300ms ease, border-color 300ms ease, background-color 300ms ease;
  -o-transition: color 300ms ease, border-color 300ms ease, background-color 300ms ease;
  transition: color 300ms ease, border-color 300ms ease, background-color 300ms ease; }
  .border-button:hover, .node-article.node-teaser .links a:hover,
  .node-news.node-teaser .links a:hover,
  .border-button:visited:hover,
  .node-article.node-teaser .links a:visited:hover,
  .node-news.node-teaser .links a:visited:hover {
    color: #aaa;
    border-color: #aaa; }

.node-type-project .interior-page-banner {
  min-height: 680px;
  max-height: 950px; }

.node-type-project .interior-page-banner-title {
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .node-type-project .interior-page-banner-title .about-us-subtitle {
    line-height: 26px; }
  .node-type-project .interior-page-banner-title h1,
  .node-type-project .interior-page-banner-title .about-us-subtitle {
    max-width: 790px;
    margin-left: auto;
    margin-right: auto;
    color: #f8f8f8; }

.node-type-project .project-container {
  padding-left: 0;
  padding-right: 0; }

.node-type-project .node-project {
  padding: 0; }

.node-type-project .krumo-root {
  position: relative;
  z-index: 2;
  width: 90%;
  top: 10%;
  left: 50%;
  transform: translateX(-50%); }

.node-type-project #site-messages {
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%); }

.node-type-project #main {
  padding-top: 0; }

.node-type-project #main-wrapper:after {
  display: none; }

.node-type-project .above-footer-wrap {
  padding-top: 150px; }

.node-project .field-name-field-client-logo-image {
  min-height: 625px;
  background: #f8f8f8;
  transform: skewY(-1.75deg);
  position: relative;
  margin-top: -41px; }
  .node-project .field-name-field-client-logo-image .field-items {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) skewY(1.75deg);
    width: 100%; }
    .node-project .field-name-field-client-logo-image .field-items .field-item {
      display: inline-block;
      max-width: 500px;
      padding: 0 20px; }
      .node-project .field-name-field-client-logo-image .field-items .field-item img {
        max-width: 100%;
        height: auto !important;
        max-height: 380px;
        backface-visibility: hidden; }
    .node-project .field-name-field-client-logo-image .field-items .field-item:first-child:nth-last-child(1) {
      width: 100%; }
    .node-project .field-name-field-client-logo-image .field-items .field-item:first-child:nth-last-child(2),
    .node-project .field-name-field-client-logo-image .field-items .field-item:first-child:nth-last-child(2) ~ .field-item {
      width: 50%; }

.node-project .field-name-body .col-sm-6.left-col {
  padding-right: 50px;
  margin-top: 100px;
  margin-bottom: 190px; }

.node-project .field-name-body .col-sm-6.right-col {
  padding-left: 50px;
  margin-top: 100px;
  margin-bottom: 190px; }

.node-project .field-name-body h2, .node-project .field-name-body .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-project .field-name-body p {
  margin-bottom: 70px; }

.node-project .field-name-body p {
  font-size: 14px;
  line-height: 1.65em; }
  .node-project .field-name-body p strong {
    font-size: 16px; }

.node-project #project-details {
  background-size: cover;
  background-position: center;
  position: relative; }
  .node-project #project-details:after {
    background: grey;
    bottom: 60px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .node-project #project-details > .container {
    width: 100%;
    max-width: 1260px;
    padding: 0 120px; }
  .node-project #project-details .container.no-padding {
    padding: 0; }
  .node-project #project-details .field-name-field-title-subtitle {
    max-width: 800px;
    margin: 0 auto;
    color: #f8f8f8;
    padding: 293px 0 0;
    margin-bottom: -70px;
    text-align: center; }
    .node-project #project-details .field-name-field-title-subtitle h2, .node-project #project-details .field-name-field-title-subtitle .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-project #project-details .field-name-field-title-subtitle p {
      margin-bottom: 20px;
      font-size: 60px;
      font-weight: 800;
      color: #f8f8f8; }
    .node-project #project-details .field-name-field-title-subtitle p {
      font-weight: bold; }
  .node-project #project-details #project-group {
    margin: 310px auto -60px;
    padding: 0;
    position: relative;
    z-index: 2; }
    .node-project #project-details #project-group:after {
      background: grey;
      bottom: -200px;
      content: '';
      height: 300px;
      overflow: visible;
      position: absolute;
      transform: skewY(-1.75deg);
      width: 100%;
      overflow-x: hidden;
      z-index: 0;
      -webkit-backface-visibility: hidden; }
    .node-project #project-details #project-group #pg-wrapper {
      max-width: 1380px;
      height: 700px; }
    .node-project #project-details #project-group #large-bg {
      height: 100%;
      background-size: cover;
      background-position: center center;
      padding: 0; }
    .node-project #project-details #project-group #stack {
      height: 100%;
      padding: 0; }
      .node-project #project-details #project-group #stack #brand-color {
        height: 398px;
        position: relative;
        z-index: 1;
        position: relative; }
        .node-project #project-details #project-group #stack #brand-color:after {
          background: grey;
          bottom: -5px;
          content: '';
          height: 300px;
          overflow: visible;
          position: absolute;
          transform: skewY(-1.75deg);
          width: 100%;
          overflow-x: hidden;
          z-index: 0;
          -webkit-backface-visibility: hidden; }
        .node-project #project-details #project-group #stack #brand-color:after {
          height: 15px; }
        .node-project #project-details #project-group #stack #brand-color .field-name-field-description {
          position: absolute;
          top: 54%;
          left: 60px;
          right: 60px;
          transform: translateY(-50%); }
          .node-project #project-details #project-group #stack #brand-color .field-name-field-description h3, .node-project #project-details #project-group #stack #brand-color .field-name-field-description p {
            margin: 0;
            padding: 0;
            color: #f8f8f8; }
          .node-project #project-details #project-group #stack #brand-color .field-name-field-description h3 {
            margin-bottom: 37px;
            font-size: 32px; }
          .node-project #project-details #project-group #stack #brand-color .field-name-field-description p {
            font-size: 14px;
            line-height: 24px; }
      .node-project #project-details #project-group #stack #small-bg {
        height: 50%;
        background-size: cover;
        background-position: center center;
        z-index: 0; }
        @media screen and (max-width: 768px) {
          .node-project #project-details #project-group #stack #small-bg {
            display: none; } }
    .node-project #project-details #project-group.sixty-fourty #stack {
      float: right; }

.node-project #project-brand {
  margin-top: -335px;
  min-height: 380px;
  z-index: 1;
  position: relative; }
  .node-project #project-brand:after {
    background: #f8f8f8;
    bottom: -240px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .node-project #project-brand:after {
    z-index: 10; }
  .node-project #project-brand .font-blurb {
    margin: -20px auto 50px;
    display: block;
    max-width: 826px;
    width: 100%; }
  .node-project #project-brand #font-previews {
    max-width: 890px;
    margin: 145px auto 120px; }
    .node-project #project-brand #font-previews span {
      display: inline-block;
      float: left; }
      .node-project #project-brand #font-previews span img {
        display: block;
        margin: auto;
        max-width: 380px;
        height: auto;
        width: 100%; }
    .node-project #project-brand #font-previews span:first-child:nth-last-child(1) {
      width: 100%;
      padding: 0 15px; }
    .node-project #project-brand #font-previews span:first-child:nth-last-child(2),
    .node-project #project-brand #font-previews span:first-child:nth-last-child(2) ~ span {
      width: 50%;
      padding: 0 15px; }
  .node-project #project-brand .font-preview-angle {
    position: relative; }
    .node-project #project-brand .font-preview-angle:after {
      background: #f8f8f8;
      bottom: -180px;
      content: '';
      height: 300px;
      overflow: visible;
      position: absolute;
      transform: skewY(-1.75deg);
      width: 100%;
      overflow-x: hidden;
      z-index: 1;
      -webkit-backface-visibility: hidden; }
    .node-project #project-brand .font-preview-angle:after {
      position: fixed;
      bottom: auto;
      left: 0;
      height: 150px;
      margin-top: 50px; }
      @media screen and (max-width: 768px) {
        .node-project #project-brand .font-preview-angle:after {
          position: absolute;
          width: 100vw;
          margin-left: -24px; } }
  .node-project #project-brand #brand-colors {
    margin-top: 150px; }
    .node-project #project-brand #brand-colors .color {
      height: calc(25vw + 100px);
      width: 25%;
      max-height: 400px;
      display: inline-block;
      position: relative;
      color: #fff;
      text-align: center; }
      .node-project #project-brand #brand-colors .color.secondary-color .color-text-wrap {
        top: 49%; }
      .node-project #project-brand #brand-colors .color.third-color .color-text-wrap {
        top: 48%; }
      .node-project #project-brand #brand-colors .color.fourth-color .color-text-wrap {
        top: 47%; }
      .node-project #project-brand #brand-colors .color .color-text-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        .node-project #project-brand #brand-colors .color .color-text-wrap .hex {
          font-weight: bold; }

.node-project .field-name-field-image-and-summary {
  padding-top: 60px; }

.node-project .field-name-field-chart-data {
  position: relative; }
  .node-project .field-name-field-chart-data .chartKey {
    position: absolute;
    max-width: 250px;
    margin: 50px 0 0 120px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 14px;
    font-weight: 800; }
    .node-project .field-name-field-chart-data .chartKey .keyItem {
      padding-bottom: 5px;
      line-height: 1.5em;
      text-transform: uppercase; }
      .node-project .field-name-field-chart-data .chartKey .keyItem span {
        content: '';
        display: block;
        float: left;
        width: 18px;
        height: 8px;
        margin: 6px 0 6px -30px;
        border-radius: 10px; }
  .node-project .field-name-field-chart-data #chartLegend {
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: bold;
    position: absolute;
    left: 0;
    bottom: 330px;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    line-height: 3em;
    padding: 20px 0;
    color: #FFFFFF; }

.white-section-wrapper {
  background: #f8f8f8;
  position: relative;
  padding-top: 105px;
  padding-bottom: 285px;
  z-index: 1;
  position: relative; }
  .white-section-wrapper:after {
    background: #ebeced;
    bottom: -200px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .white-section-wrapper:after {
    left: 0; }
  .white-section-wrapper .container {
    max-width: 1050px;
    padding-left: 30px;
    padding-right: 30px; }
  .white-section-wrapper .field-type-text-long {
    text-align: center;
    margin-top: -100px; }
    .white-section-wrapper .field-type-text-long h2, .white-section-wrapper .field-type-text-long .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .white-section-wrapper .field-type-text-long p {
      padding-top: 50px;
      margin-bottom: 70px; }
    .white-section-wrapper .field-type-text-long h3 {
      font-family: "Roboto Slab", georgia, serif;
      font-size: 16px;
      text-transform: none;
      margin-bottom: 30px; }
    .white-section-wrapper .field-type-text-long p {
      color: #626262;
      font-size: 14px; }
  .white-section-wrapper img {
    max-width: 1170px;
    width: 100%;
    height: auto;
    margin: 32px auto 20px; }
  .white-section-wrapper .field-name-field-summary {
    padding-left: 10px;
    padding-right: 10px; }
  .white-section-wrapper .field-collection-container,
  .white-section-wrapper .field-collection-view {
    padding: 0;
    margin: 0;
    border: none; }
  .white-section-wrapper .field-collection-container .field-item {
    margin-bottom: 0px; }
  .white-section-wrapper .field-name-field-white-section-images .field-items:last-child {
    margin-bottom: 200px; }

.grey-section-wrapper {
  padding-top: 20px;
  padding-bottom: 290px;
  background: #ebeced;
  z-index: 1;
  position: relative; }
  .grey-section-wrapper h3 {
    font-family: "Roboto Slab", georgia, serif;
    font-size: 16px;
    text-transform: none; }
  .grey-section-wrapper p {
    color: #626262;
    font-size: 14px; }
  .grey-section-wrapper h2, .grey-section-wrapper .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .grey-section-wrapper p, .grey-section-wrapper h3, .grey-section-wrapper p {
    text-align: center; }
  .grey-section-wrapper:after {
    background: #262626;
    bottom: -200px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .grey-section-wrapper:after {
    left: 0; }
  .grey-section-wrapper .field-name-field-grey-section-summary {
    max-width: 840px;
    margin: 0 auto; }
  .grey-section-wrapper .field-name-field-grey-section-images .field-item {
    width: 33%;
    padding: 50px;
    float: left; }
    .grey-section-wrapper .field-name-field-grey-section-images .field-item img {
      width: 100%;
      height: auto;
      margin: 0 auto; }
  .grey-section-wrapper .field-name-field-grey-section-images-alt .field-item {
    width: 50%;
    padding: 24px 28px;
    float: left; }
    .grey-section-wrapper .field-name-field-grey-section-images-alt .field-item img {
      width: 100%;
      height: auto;
      margin: 75px auto; }

[about="/our-work/howard-leight"] .grey-section-wrapper {
  position: relative; }
  [about="/our-work/howard-leight"] .grey-section-wrapper h2, [about="/our-work/howard-leight"] .grey-section-wrapper .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote [about="/our-work/howard-leight"] .grey-section-wrapper p {
    margin-top: 28px;
    margin-bottom: 70px; }
    [about="/our-work/howard-leight"] .grey-section-wrapper h2 span, [about="/our-work/howard-leight"] .grey-section-wrapper .paragraphs-item-quote .field-name-field-quote p span, .paragraphs-item-quote .field-name-field-quote [about="/our-work/howard-leight"] .grey-section-wrapper p span {
      text-transform: none; }
  [about="/our-work/howard-leight"] .grey-section-wrapper h3 {
    font-size: 16px;
    text-transform: none;
    margin-bottom: 30px; }
  [about="/our-work/howard-leight"] .grey-section-wrapper p {
    margin-bottom: 70px; }
  [about="/our-work/howard-leight"] .grey-section-wrapper .field-name-field-grey-section-images-alt .field-item img {
    margin: 5px auto; }
  [about="/our-work/howard-leight"] .grey-section-wrapper:after {
    background: #171717;
    bottom: -200px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }

.black-section-wrapper {
  background: #262626;
  z-index: 1;
  position: relative; }
  .black-section-wrapper:after {
    background: #262626;
    bottom: -40px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .black-section-wrapper:after {
    height: 150px; }
  .black-section-wrapper .field-item {
    display: inline-block; }
  .black-section-wrapper img {
    width: 100%;
    height: auto;
    margin: 130px auto 135px; }
  .black-section-wrapper .field-name-field-black-section-images .field-items:last-child {
    margin-bottom: -20px;
    text-align: center; }

[about="/our-work/howard-leight"] .black-section-wrapper {
  background: #171717; }
  [about="/our-work/howard-leight"] .black-section-wrapper img {
    margin-bottom: 260px; }
  [about="/our-work/howard-leight"] .black-section-wrapper:after {
    background: #171717; }

[about="/our-work/avanir-pharmaceuticals"] .black-section-wrapper img {
  margin-bottom: 100px; }

.chart-intro-wrapper {
  padding-top: 43px;
  padding-bottom: 119px;
  background: #f8f8f8;
  z-index: 20;
  position: relative;
  position: relative; }
  .chart-intro-wrapper:after {
    background: #f8f8f8;
    bottom: -40px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .chart-intro-wrapper:before {
    background: #f8f8f8;
    left: 0;
    bottom: calc(100% - 200px);
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .chart-intro-wrapper .col-sm-8 {
    float: none;
    position: relative;
    z-index: 2;
    padding-left: 40px;
    padding-right: 30px; }
  .chart-intro-wrapper .field-name-field-chart-intro {
    max-width: 850px;
    margin: 0 auto;
    padding-bottom: 25px; }
    .chart-intro-wrapper .field-name-field-chart-intro h2, .chart-intro-wrapper .field-name-field-chart-intro .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .chart-intro-wrapper .field-name-field-chart-intro p {
      margin-bottom: 70px; }
    .chart-intro-wrapper .field-name-field-chart-intro h3 {
      margin-bottom: 30px;
      font-family: "Roboto Slab", georgia, serif;
      font-size: 16px;
      text-transform: none; }
    .chart-intro-wrapper .field-name-field-chart-intro p {
      color: #626262;
      margin-bottom: 12px;
      font-size: 14px; }
      .chart-intro-wrapper .field-name-field-chart-intro p strong {
        font-size: 16px;
        color: #262626; }
  .chart-intro-wrapper .border-button, .chart-intro-wrapper .node-article.node-teaser .links a, .node-article.node-teaser .links .chart-intro-wrapper a,
  .chart-intro-wrapper .node-news.node-teaser .links a, .node-news.node-teaser .links .chart-intro-wrapper a {
    padding-left: 62px;
    padding-right: 62px; }
    .chart-intro-wrapper .border-button:hover, .chart-intro-wrapper .node-article.node-teaser .links a:hover, .node-article.node-teaser .links .chart-intro-wrapper a:hover,
    .chart-intro-wrapper .node-news.node-teaser .links a:hover, .node-news.node-teaser .links .chart-intro-wrapper a:hover {
      background-color: #626262;
      border-color: #626262;
      color: #fff; }

.chart-section-wrapper {
  position: relative;
  padding-top: 100px; }

.case-study-social-wrapper {
  background-size: cover;
  background-position: center;
  margin-top: -60px;
  position: relative;
  z-index: 20; }
  .case-study-social-wrapper .container {
    padding-left: 30px;
    padding-right: 30px; }
  .case-study-social-wrapper.has-social-block .case-study-wrapper {
    padding: 180px 10px 140px; }
  .case-study-social-wrapper.no-social-block {
    margin-top: -220px;
    padding-top: 100px; }
    .case-study-social-wrapper.no-social-block .case-study-wrapper {
      padding: 400px 10px 180px; }
  .case-study-social-wrapper .field-collection-container {
    margin: 0;
    padding: 0;
    border: none; }
  .case-study-social-wrapper .field-name-field-social-block {
    padding: 0 0 50px;
    margin: -155px 0 0; }
    .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item {
      width: 50%;
      float: left;
      position: relative;
      overflow: hidden;
      color: #fff;
      opacity: 0;
      transform: translateY(100px);
      transition: transform 600ms ease-in-out,opacity 600ms ease-in-out,-webkit-transform 600ms ease-in-out; }
      .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item .field-collection-view {
        padding: 0;
        margin: 0;
        border: none; }
      .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item .entity-field-collection-item {
        height: 500px;
        background-position: center center;
        background-size: cover;
        background-color: #060606; }
        .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item .entity-field-collection-item.instagram .social-logo {
          background: url(../images/instagram.corner.png) center center no-repeat; }
      .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item .shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+26,0.65+100 */
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 0.65) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 0.65) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 0.65) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
        /* IE6-9 */ }
      .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item .social-description {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 10px 50px 50px;
        font-weight: 800;
        font-size: 14px;
        color: #f8f8f8;
        transform: translateY(-100%);
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: center; }
        .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item .social-description p {
          font-size: 14px; }
      .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item a {
        color: #fff;
        display: block;
        position: absolute;
        z-index: 21;
        bottom: 0;
        right: 0;
        opacity: 0.7;
        transition: opacity 500ms ease; }
        .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item a:hover {
          opacity: 1; }
      .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item .social-logo {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 89px;
        height: 89px;
        z-index: 22;
        background: url(../images/facebook.corner.png) center center no-repeat; }
      .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item p {
        margin: 10px 0;
        padding: 0; }
      .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item:nth-child(2) {
        transition-delay: 200ms; }
  .case-study-social-wrapper .case-study-wrapper {
    text-align: center;
    color: #f8f8f8; }
    .case-study-social-wrapper .case-study-wrapper h2, .case-study-social-wrapper .case-study-wrapper .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .case-study-social-wrapper .case-study-wrapper p {
      font-size: 60px; }
    .case-study-social-wrapper .case-study-wrapper p {
      font-weight: 600; }
    .case-study-social-wrapper .case-study-wrapper .border-button, .case-study-social-wrapper .case-study-wrapper .node-article.node-teaser .links a, .node-article.node-teaser .links .case-study-social-wrapper .case-study-wrapper a,
    .case-study-social-wrapper .case-study-wrapper .node-news.node-teaser .links a, .node-news.node-teaser .links .case-study-social-wrapper .case-study-wrapper a {
      margin-top: 38px;
      color: #f8f8f8;
      border-color: #f8f8f8; }
      .case-study-social-wrapper .case-study-wrapper .border-button:hover, .case-study-social-wrapper .case-study-wrapper .node-article.node-teaser .links a:hover, .node-article.node-teaser .links .case-study-social-wrapper .case-study-wrapper a:hover,
      .case-study-social-wrapper .case-study-wrapper .node-news.node-teaser .links a:hover, .node-news.node-teaser .links .case-study-social-wrapper .case-study-wrapper a:hover {
        color: #171717;
        background: #f8f8f8; }

.case-study-social-wrapper.in-view .field-name-field-social-block .field-item {
  opacity: 1;
  transform: translateY(0); }

.parachute-active .case-study-social-wrapper .field-name-field-social-block {
  margin-top: 0px; }

[about="/our-work/avanir-pharmaceuticals"] .case-study-wrapper {
  padding: 125px 0 195px; }

.love-our-work-wrapper {
  padding: 180px 0 0;
  background: #171717;
  text-align: center;
  position: relative;
  position: relative; }
  .love-our-work-wrapper:after {
    background: #171717;
    bottom: -200px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .love-our-work-wrapper:before {
    background: #171717;
    left: 0;
    bottom: calc(100% - 200px);
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .love-our-work-wrapper h1, .love-our-work-wrapper .about-us-subtitle, .love-our-work-wrapper a {
    position: relative;
    z-index: 1;
    color: #f8f8f8; }
  .love-our-work-wrapper .about-us-subtitle {
    font-weight: 800;
    margin: 25px 0 45px; }
  .love-our-work-wrapper .border-button, .love-our-work-wrapper .node-article.node-teaser .links a, .node-article.node-teaser .links .love-our-work-wrapper a,
  .love-our-work-wrapper .node-news.node-teaser .links a, .node-news.node-teaser .links .love-our-work-wrapper a {
    color: #d8d8d8;
    border-color: #d8d8d8; }
    .love-our-work-wrapper .border-button:hover, .love-our-work-wrapper .node-article.node-teaser .links a:hover, .node-article.node-teaser .links .love-our-work-wrapper a:hover,
    .love-our-work-wrapper .node-news.node-teaser .links a:hover, .node-news.node-teaser .links .love-our-work-wrapper a:hover {
      color: #171717;
      background: #d8d8d8;
      border-color: #d8d8d8; }
  .love-our-work-wrapper:after {
    left: 0; }

.project-nav-wrapper {
  position: relative;
  margin-top: -100px;
  background: #171717;
  -webkit-transform: skewY(-1.75deg);
  transform: skewY(-1.75deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
  overflow: hidden;
  z-index: 20; }
  .project-nav-wrapper a {
    -webkit-transform: skewY(1.75deg);
    transform: skewY(1.75deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: block;
    font-size: 32px; }
    .project-nav-wrapper a:before, .project-nav-wrapper a:after {
      content: " ";
      display: table; }
    .project-nav-wrapper a:after {
      clear: both; }
  .project-nav-wrapper .back-nav,
  .project-nav-wrapper .next-nav {
    background-size: cover;
    background-position: center center;
    height: calc(50vw - 20px);
    width: 50%;
    max-height: 700px;
    padding: 20px;
    cursor: pointer;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center; }
    .project-nav-wrapper .back-nav a,
    .project-nav-wrapper .next-nav a {
      background-size: cover;
      background-position: center center;
      color: #f8f8f8;
      text-align: center;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
      font-weight: 800;
      font-style: normal;
      text-transform: uppercase; }
  .project-nav-wrapper .back-nav {
    background-image: url(../images/mos_banner_ourwork.jpg); }

.parachute-active .interior-page-banner-title {
  left: 0; }

.parachute-active .node-project #project-details #project-group:after {
  height: 100px;
  transition: bottom 600ms ease-in-out,height 600ms ease-in-out; }

.parachute-active .node-project #project-details #project-group #large-bg,
.parachute-active .node-project #project-details #project-group #small-bg,
.parachute-active .node-project #project-details #project-group #brand-color {
  opacity: 0;
  transition: transform 600ms ease-in-out,opacity 600ms ease-in-out,-webkit-transform 600ms ease-in-out; }

.parachute-active .node-project #project-details #project-group.in-view:after {
  bottom: 10px;
  height: 100px; }

.parachute-active .node-project #project-details #project-group.in-view #large-bg,
.parachute-active .node-project #project-details #project-group.in-view #small-bg,
.parachute-active .node-project #project-details #project-group.in-view #brand-color {
  opacity: 1;
  transform: translateY(-100px); }

.parachute-active .node-project #project-details #project-group.in-view.fourty-sixty #small-bg {
  transition-delay: 100ms; }

.parachute-active .node-project #project-details #project-group.in-view.fourty-sixty #large-bg {
  transition-delay: 200ms; }

.parachute-active .node-project #project-details #project-group.in-view.sixty-fourty #brand-color {
  transition-delay: 200ms; }

.parachute-active .node-project #project-details #project-group.in-view.sixty-fourty #small-bg {
  transition-delay: 300ms; }

.parachute-active .node-project #project-details #project-group.in-view.sixty-fourty #large-bg {
  transition-delay: 100ms; }

.parachute-active .node-project .white-section-wrapper .container {
  padding-top: 0px;
  margin-bottom: -150px; }
  .parachute-active .node-project .white-section-wrapper .container .field-name-field-summary {
    padding-bottom: 75px; }
  .parachute-active .node-project .white-section-wrapper .container .field-name-field-image {
    opacity: 0;
    transform: translateY(100px);
    transition: transform 600ms ease-in-out,opacity 600ms ease-in-out,-webkit-transform 600ms ease-in-out; }
  .parachute-active .node-project .white-section-wrapper .container .in-view .field-name-field-image {
    opacity: 1;
    transform: translateY(0px); }

.parachute-active .node-project .grey-section-wrapper .container {
  margin-bottom: -70px; }
  .parachute-active .node-project .grey-section-wrapper .container .field-item img {
    opacity: 0;
    transform: translateY(100px);
    transition: transform 600ms ease-in-out,opacity 600ms ease-in-out,-webkit-transform 600ms ease-in-out; }
  .parachute-active .node-project .grey-section-wrapper .container .field-item:nth-child(3n + 1) img {
    transition-delay: 100ms; }
  .parachute-active .node-project .grey-section-wrapper .container .field-item:nth-child(3n + 2) img {
    transition-delay: 200ms; }
  .parachute-active .node-project .grey-section-wrapper .container .field-item:nth-child(3n + 3) img {
    transition-delay: 300ms; }
  .parachute-active .node-project .grey-section-wrapper .container .in-view.field-item img {
    opacity: 1;
    transform: translateY(0px); }

.parachute-active .node-project .black-section-wrapper .container .field-item img {
  opacity: 0;
  transform: translateY(100px);
  transition: transform 600ms ease-in-out,opacity 600ms ease-in-out,-webkit-transform 600ms ease-in-out; }

.parachute-active .node-project .black-section-wrapper .container .in-view.field-item img {
  opacity: 1;
  transform: translateY(0px); }

.mobile #project-details #project-group:after {
  height: 60px;
  bottom: -50px; }

.mobile .white-section-wrapper,
.mobile .grey-section-wrapper,
.mobile .black-section-wrapper {
  padding-top: 0;
  padding-bottom: 200px; }

.mobile .white-section-wrapper .field-type-text-long {
  margin-top: 0; }

.mobile .black-section-wrapper {
  padding-bottom: 100px; }

.mobile .case-study-social-wrapper .field-name-field-social-block {
  margin-top: -300px; }
  .mobile .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item {
    opacity: 1; }

@media screen and (min-width: 769px) {
  .mobile .node-project #project-details .field-name-field-title-subtitle {
    padding: 200px 0;
    margin-bottom: 0; }
  .mobile .node-project #project-details:after {
    bottom: -206px;
    height: 512px; }
  .mobile .node-project #project-details #project-group {
    margin: 0 auto; }
  .mobile .node-project #project-brand {
    margin-top: 0 !important; }
    .mobile .node-project #project-brand .font-blurb {
      margin: 200px auto 50px; }
  .mobile .node-project#node-136 #project-brand {
    min-height: 200px; }
  .mobile .node-project .case-study-social-wrapper.no-social-block .case-study-wrapper {
    padding: 110px 10px 260px; }
  .mobile .node-project .case-study-social-wrapper.has-social-block .case-study-wrapper {
    padding: 110px 10px 260px; }
  [about="/our-work/avanir-pharmaceuticals"] .black-section-wrapper img {
    margin-bottom: 260px; } }

@media screen and (max-width: 900px) {
  .node-project #project-details {
    height: 890px; }
    .node-project #project-details:after {
      top: 800px;
      bottom: auto;
      height: 800px; }
    .node-project #project-details .field-name-field-title-subtitle {
      max-width: 690px;
      padding-top: 195px;
      transform: none !important; }
      .node-project #project-details .field-name-field-title-subtitle h2, .node-project #project-details .field-name-field-title-subtitle .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-project #project-details .field-name-field-title-subtitle p {
        margin-top: 0; }
    .node-project #project-details #project-group {
      margin: 0 auto;
      top: 230px;
      transform: none !important; }
      .node-project #project-details #project-group:after {
        left: 0;
        bottom: -10px;
        height: 20px; }
      .node-project #project-details #project-group #stack {
        height: auto !important; }
      .node-project #project-details #project-group #brand-color .field-name-field-description {
        top: 50%; }
      .node-project #project-details #project-group #large-bg {
        max-height: 520px; }
      .node-project #project-details #project-group .col-sm-5,
      .node-project #project-details #project-group .col-sm-7 {
        width: 100%; }
  .node-project #project-brand {
    margin-top: 0px;
    padding-top: 800px; }
  .case-study-social-wrapper.no-social-block .case-study-wrapper {
    padding: 100px 10px 300px; }
  .case-study-social-wrapper.has-social-block .case-study-wrapper {
    padding: 180px 10px 200px; }
  .case-study-social-wrapper .field-name-field-case-study-text h2, .case-study-social-wrapper .field-name-field-case-study-text .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .case-study-social-wrapper .field-name-field-case-study-text p {
    margin-top: 0; } }

@media screen and (max-width: 767px) {
  .node-type-project #header:after {
    content: none; }
  .node-type-project .interior-page-banner {
    min-height: 0;
    height: calc(100vh - 140px); }
  .node-type-project .interior-page-banner-title {
    top: 50%;
    padding: 0 40px; }
    .node-type-project .interior-page-banner-title h1 {
      margin-bottom: 15px;
      font-size: 28px;
      line-height: 1.25em; }
    .node-type-project .interior-page-banner-title .about-us-subtitle {
      margin-top: 0;
      font-size: 14px;
      line-height: 1.55em; }
  .node-type-project h2, .node-type-project .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-type-project p {
    font-size: 28px; }
  .node-type-project #main-wrapper p {
    font-size: 14px; }
  .node-project .field-name-field-client-logo-image {
    min-height: initial;
    margin-top: -20px;
    padding-bottom: 15px; }
    .node-project .field-name-field-client-logo-image .field-items {
      position: relative;
      top: inherit;
      transform: translateY(5px) skewY(1.75deg); }
      .node-project .field-name-field-client-logo-image .field-items .field-item {
        display: block;
        margin-top: 0px;
        width: 100% !important;
        float: none;
        padding: 165px 65px; }
        .node-project .field-name-field-client-logo-image .field-items .field-item img {
          max-width: 100%;
          height: auto !important; }
        .node-project .field-name-field-client-logo-image .field-items .field-item:first-of-type {
          padding: 165px 65px 0 65px; }
        .node-project .field-name-field-client-logo-image .field-items .field-item:last-of-type {
          padding: 165px 65px 0 65px;
          padding-bottom: 165px; }
  .node-project .field-name-body {
    padding-left: 25px !important;
    padding-right: 25px !important; }
    .node-project .field-name-body .col-sm-6.left-col {
      margin-bottom: 100px; }
    .node-project .field-name-body .col-sm-6.right-col {
      margin-bottom: 150px; }
  .node-project .field-type-text-with-summary {
    line-height: 24px; }
    .node-project .field-type-text-with-summary strong {
      font-size: 16px; }
  .node-project #project-details {
    height: 665px; }
    .node-project #project-details:after {
      top: 650px; }
    .node-project #project-details > .container {
      padding: 0 30px; }
    .node-project #project-details .field-name-field-title-subtitle {
      padding: 125px 40px 208px 40px; }
      .node-project #project-details .field-name-field-title-subtitle h2, .node-project #project-details .field-name-field-title-subtitle .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-project #project-details .field-name-field-title-subtitle p {
        font-size: 28px; }
    .node-project #project-details #project-group {
      top: 0;
      margin: 0 auto; }
      .node-project #project-details #project-group:after {
        height: 20px;
        bottom: -10px; }
      .node-project #project-details #project-group #pg-wrapper {
        height: initial; }
        .node-project #project-details #project-group #pg-wrapper #brand-color {
          height: auto; }
          .node-project #project-details #project-group #pg-wrapper #brand-color .field-name-field-description {
            padding: 60px 30px;
            position: relative;
            top: inherit;
            left: inherit;
            right: inherit;
            transform: translateY(0); }
            .node-project #project-details #project-group #pg-wrapper #brand-color .field-name-field-description h3 {
              font-size: 28px; }
          .node-project #project-details #project-group #pg-wrapper #brand-color:after {
            height: 25px;
            bottom: -11px; }
        .node-project #project-details #project-group #pg-wrapper #small-bg {
          display: none;
          height: 200px; }
        .node-project #project-details #project-group #pg-wrapper #large-bg {
          height: 500px; }
  .node-project #project-brand {
    min-height: 0;
    margin-top: 0;
    padding-top: 0; }
    .node-project #project-brand .font-blurb {
      margin-top: 0;
      padding: 0 15px; }
    .node-project #project-brand #font-previews {
      margin: 40px auto; }
      .node-project #project-brand #font-previews span {
        display: block;
        margin-top: 0px;
        width: 100% !important;
        float: none; }
        .node-project #project-brand #font-previews span:first-child {
          padding: 40px 15px !important; }
    .node-project #project-brand .font-preview-angle:after {
      height: 75px;
      bottom: -60px;
      width: 100vw;
      margin-left: -15px; }
    .node-project #project-brand #brand-colors {
      margin-top: 40px;
      max-width: 100vw; }
      .node-project #project-brand #brand-colors .color {
        width: 50%;
        height: 50vw; }
        .node-project #project-brand #brand-colors .color.third-color, .node-project #project-brand #brand-colors .color.fourth-color {
          height: calc(50vw + 40px);
          margin-top: -5px; }
          .node-project #project-brand #brand-colors .color.third-color .color-text-wrap, .node-project #project-brand #brand-colors .color.fourth-color .color-text-wrap {
            top: 40%; }
  .node-project .field-name-body .col-sm-6.left-col, .node-project .field-name-body .col-sm-6.right-col {
    padding-right: 0;
    padding-left: 0; }
  .node-project .white-section-wrapper {
    padding-top: 35px;
    padding-bottom: 205px; }
    .node-project .white-section-wrapper .field-type-text-long h3, .node-project .white-section-wrapper .field-type-text-long p {
      line-height: 1.65em; }
    .node-project .white-section-wrapper .field-name-field-image-and-summary {
      padding-top: 0; }
      .node-project .white-section-wrapper .field-name-field-image-and-summary > .field-items > .field-item {
        padding: 20px 0;
        margin-bottom: -10px; }
        .node-project .white-section-wrapper .field-name-field-image-and-summary > .field-items > .field-item h2, .node-project .white-section-wrapper .field-name-field-image-and-summary > .field-items > .field-item .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-project .white-section-wrapper .field-name-field-image-and-summary > .field-items > .field-item p {
          margin-bottom: 30px; }
        .node-project .white-section-wrapper .field-name-field-image-and-summary > .field-items > .field-item:first-of-type h2, .node-project .white-section-wrapper .field-name-field-image-and-summary > .field-items > .field-item:first-of-type .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-project .white-section-wrapper .field-name-field-image-and-summary > .field-items > .field-item:first-of-type p {
          padding-top: 0; }
    .node-project .white-section-wrapper img {
      margin: 10px auto; }
  .node-project .grey-section-wrapper {
    padding-top: 0 !important;
    padding-bottom: 205px; }
    .node-project .grey-section-wrapper .field-name-field-grey-section-summary h3, .node-project .grey-section-wrapper .field-name-field-grey-section-summary p {
      line-height: 1.65em; }
    .node-project .grey-section-wrapper .field-name-field-grey-section-images .field-item,
    .node-project .grey-section-wrapper .field-name-field-grey-section-images-alt .field-item {
      width: 50%;
      padding: 0 15px; }
    .node-project .grey-section-wrapper .field-item img {
      margin: 16px auto; }
  .node-project .grey-section-wrapper + .chart-intro-wrapper {
    margin-top: -60px; }
  .node-project [about="/our-work/howard-leight"] .grey-section-wrapper .field-name-field-grey-section-images-alt .field-item img {
    margin: 16px auto; }
  .node-project .black-section-wrapper {
    padding-bottom: 120px; }
    .node-project .black-section-wrapper:after {
      display: none; }
    .node-project .black-section-wrapper .field-item {
      width: 80%;
      padding: 0; }
      .node-project .black-section-wrapper .field-item img {
        margin: 10px auto; }
  .node-project .chart-intro-wrapper {
    padding-top: 35px;
    padding-bottom: 80px; }
    .node-project .chart-intro-wrapper:before {
      height: 240px; }
    .node-project .chart-intro-wrapper:after {
      bottom: -10px; }
    .node-project .chart-intro-wrapper p, .node-project .chart-intro-wrapper h3 {
      line-height: 1.65em; }
  .node-project .field-name-field-chart-data .chartKey {
    position: relative;
    margin: 0 0 0 60px; }
  .node-project .case-study-social-wrapper {
    margin-top: 0; }
    .node-project .case-study-social-wrapper .container {
      padding-left: 30px;
      padding-right: 30px; }
    .node-project .case-study-social-wrapper .case-study-wrapper h2, .node-project .case-study-social-wrapper .case-study-wrapper .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-project .case-study-social-wrapper .case-study-wrapper p {
      font-size: 30px; }
    .node-project .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item .social-description {
      padding: 0 25px 30px; }
    .node-project .case-study-social-wrapper.no-social-block .case-study-wrapper {
      padding: 0 0 160px; }
  .node-project #mosChart + .case-study-social-wrapper {
    margin-top: -220px; }
    .node-project #mosChart + .case-study-social-wrapper.has-social-block {
      margin-top: 0; }
  .node-project .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item {
    width: 100%;
    float: none;
    opacity: 1;
    display: none; }
    .node-project .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item:first-child {
      display: block; }
    .node-project .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item .entity-field-collection-item {
      height: 400px; }
  .node-project#node-136 #project-brand {
    margin-top: calc(700px - 40%) !important; }
  .parachute-active .grey-section-wrapper .field-name-field-grey-section-images .field-item {
    margin-top: -6%;
    padding: 0 2%; }
    .parachute-active .grey-section-wrapper .field-name-field-grey-section-images .field-item img {
      margin: 0 auto; }
  .project-nav-wrapper {
    position: relative;
    margin-top: -10px;
    z-index: 21; }
    .project-nav-wrapper .back-nav a,
    .project-nav-wrapper .next-nav a {
      font-size: 18px;
      line-height: 1.25em; }
  .love-our-work-wrapper {
    padding: 100px 0 50px; }
    .love-our-work-wrapper:before {
      height: 230px; }
    .love-our-work-wrapper:after {
      height: 230px;
      bottom: -80px; } }

@media screen and (max-width: 450px) {
  .node-project #project-details #project-group #pg-wrapper #large-bg {
    height: 325px; }
  .node-project#node-131 .font-blurb {
    margin-top: 80px; } }

.node-article .field-name-field-post-body-paragraphs > .field-items,
.node-news .field-name-field-post-body-paragraphs > .field-items {
  padding: 25px 0 0; }
  .node-article .field-name-field-post-body-paragraphs > .field-items > .field-item,
  .node-news .field-name-field-post-body-paragraphs > .field-items > .field-item {
    padding: 50px 0; }

.node-article .field-name-field-post-body-paragraphs img,
.node-news .field-name-field-post-body-paragraphs img {
  max-width: 100%;
  height: auto !important; }

.node-article .post-date,
.node-news .post-date {
  color: #626262;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 25px; }

.node-article.node-teaser,
.node-news.node-teaser {
  padding: 50px 0;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center; }
  .node-article.node-teaser h2, .node-article.node-teaser .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-article.node-teaser p,
  .node-news.node-teaser h2,
  .node-news.node-teaser .paragraphs-item-quote .field-name-field-quote p,
  .paragraphs-item-quote .field-name-field-quote .node-news.node-teaser p {
    margin-bottom: 10px; }
  .node-article.node-teaser img,
  .node-news.node-teaser img {
    max-width: 100%;
    height: auto !important; }
  .node-article.node-teaser .links,
  .node-news.node-teaser .links {
    display: block;
    margin-top: 30px; }
    .node-article.node-teaser .links li,
    .node-news.node-teaser .links li {
      margin: 0;
      padding: 0; }

@media screen and (max-width: 767px) {
  .node-article.node-teaser,
  .node-news.node-teaser {
    display: block; }
    .node-article.node-teaser .field-name-field-banner-image,
    .node-news.node-teaser .field-name-field-banner-image {
      max-height: 200px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; }
      .node-article.node-teaser .field-name-field-banner-image div,
      .node-news.node-teaser .field-name-field-banner-image div {
        width: 100%;
        display: block; }
      .node-article.node-teaser .field-name-field-banner-image img,
      .node-news.node-teaser .field-name-field-banner-image img {
        width: 100%;
        height: auto !important; } }

.paragraphs-item-quote {
  text-align: center; }
  .paragraphs-item-quote .field-name-field-quote {
    padding: 24px 50px 0;
    line-height: 1.1; }
  .paragraphs-item-quote .field-name-field-eyebrow,
  .paragraphs-item-quote .field-name-field-attribution {
    font-size: 16px;
    font-weight: 700; }

.paragraphs-item-content-image .row {
  display: flex;
  align-items: center; }

.paragraphs-item-image {
  text-align: center; }
  .paragraphs-item-image .field-name-field-attribution {
    padding: 25px 0 0; }

.field-name-field-eyebrow,
.field-name-field-attribution {
  color: #626262; }

.field-name-field-attribution {
  font-size: 12px; }

.field-name-field-tags {
  margin: 25px 0 0; }
  .field-name-field-tags .field-label {
    padding: 0 10px 0 0; }
  .field-name-field-tags .field-items {
    display: flex; }
    .field-name-field-tags .field-items .field-item {
      padding: 0 10px 0 0;
      font-weight: 700; }

#comments {
  clear: both;
  padding: 50px 0; }

.page-taxonomy-term .feed-icon {
  display: none; }

/* -------------------- layout/footer -------------------- */
#above-footer {
  background: #26ac62;
  color: #207846;
  color: #1a7643;
  font-size: 12px;
  padding: 100px 0; }

@media screen and (max-width: 767px) {
  #above-footer {
    padding: 50px 0 100px; }
  .col-xs-6 {
    margin-top: 50px; }
    .col-xs-6:last-child {
      padding-left: 0; } }

#above-footer-inner .col-md-4 {
  padding-left: 40px; }

#above-footer-inner .col-md-3 {
  padding-left: 0; }

@media screen and (min-width: 767px) and (max-width: 1024px) {
  #above-footer-inner .col-md-3 {
    margin-top: 40px;
    padding-left: 40px; } }

@media screen and (min-width: 992px) and (max-width: 1024px) {
  #above-footer-inner .col-md-3 {
    margin-top: 0; } }

@media screen and (max-width: 767px) {
  .col-md-3,
  .col-md-4 {
    padding-left: 15px; }
  #above-footer-inner .col-md-4 {
    padding-left: 30px; }
  #above-footer-inner .col-md-3 {
    padding-left: 30px; }
  #above-footer-inner .col-md-3:last-child {
    padding-left: 0px; } }

.footer-logo .col-md-10 {
  padding-left: 40px; }

.footer-logo img {
  margin-bottom: 50px;
  width: 162px; }

.footer-logo .logo-underline {
  background: #22854f;
  height: 1px;
  margin-bottom: 40px;
  width: 52px; }

@media screen and (max-width: 767px) {
  .col-md-10 {
    padding-left: 15px; } }

.footer-about-us-blurb .content {
  color: #e8e8e8;
  max-width: 250px; }
  .footer-about-us-blurb .content p {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4em; }
  .footer-about-us-blurb .content small {
    color: #207846;
    font-size: 12px; }

.footer-sitemap h2, .footer-sitemap .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .footer-sitemap p,
.footer-contact-info h2,
.footer-contact-info .paragraphs-item-quote .field-name-field-quote p,
.paragraphs-item-quote .field-name-field-quote .footer-contact-info p {
  font-family: "Roboto Slab", georgia, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  margin: 0 0 20px;
  text-transform: none; }

.footer-sitemap ul,
.footer-contact-info ul {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  margin: 0;
  padding: 0;
  text-transform: uppercase; }
  .footer-sitemap ul li.leaf,
  .footer-contact-info ul li.leaf {
    list-style: none;
    list-style-image: none;
    margin: 6px 0 0;
    padding: 0; }
    .footer-sitemap ul li.leaf a,
    .footer-sitemap ul li.leaf a:visited,
    .footer-contact-info ul li.leaf a,
    .footer-contact-info ul li.leaf a:visited {
      color: #207846;
      font-size: 16px;
      text-decoration: none; }
      .footer-sitemap ul li.leaf a:hover,
      .footer-sitemap ul li.leaf a:visited:hover,
      .footer-contact-info ul li.leaf a:hover,
      .footer-contact-info ul li.leaf a:visited:hover {
        color: #1b643a;
        text-decoration: none; }

@media screen and (max-width: 767px) {
  .footer-contact-info p {
    font-size: 12px; }
  .footer-sitemap h2, .footer-sitemap .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .footer-sitemap p,
  .footer-contact-info h2,
  .footer-contact-info .paragraphs-item-quote .field-name-field-quote p,
  .paragraphs-item-quote .field-name-field-quote .footer-contact-info p {
    font-size: 12px; }
  .footer-sitemap ul li.leaf,
  .footer-contact-info ul li.leaf {
    margin-top: 10px; }
    .footer-sitemap ul li.leaf a,
    .footer-sitemap ul li.leaf a:visited,
    .footer-contact-info ul li.leaf a,
    .footer-contact-info ul li.leaf a:visited {
      font-size: 12px; } }

.footer-contact-info .content a,
.footer-contact-info .content a:visited {
  color: inherit; }
  .footer-contact-info .content a:hover,
  .footer-contact-info .content a:visited:hover {
    color: #1b643a;
    text-decoration: none; }

#partners {
  display: flex;
  flex-wrap: wrap;
  float: none; }
  #partners > div {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column; }
  #partners .divider {
    background: #1f6c42;
    opacity: 0.5;
    height: 1px;
    margin: 10px auto;
    top: 0;
    width: 26px; }
  #partners .acquia img {
    width: 66px; }
  #partners .amazon img {
    width: 48px; }
  #partners .google img {
    width: 50px; }
  #partners .microsoft img {
    width: 74px; }
  #partners .pantheon img {
    width: 100px; }
  #partners .partner {
    font-family: arial;
    font-weight: 400;
    font-style: normal;
    font-size: 12px; }

@media screen and (max-width: 767px) {
  #partners > div {
    width: 50%; }
    #partners > div:nth-child(n+3) {
      padding-top: 30px; } }

#footer {
  background: #249a59;
  color: #1f6f42;
  padding: 100px 0; }
  #footer .mos-copyright {
    margin-top: 100px; }
    #footer .mos-copyright p {
      font-size: 10px; }

@media screen and (min-width: 767px) and (max-width: 1024px) {
  #footer .mos-copyright {
    margin-top: 60px; } }

@media screen and (max-width: 767px) {
  #footer .mos-copyright {
    margin-top: 60px; } }

.node-type-news #page-wrapper,
.node-type-news #header:after {
  background: #fff; }

.node-type-news #main-wrapper:after {
  content: none; }

.node-type-news .field-name-field-paragraphs .field-item:first-child .entity-paragraphs-item {
  padding-top: 0; }

.node-type-news .field-name-field-paragraphs .field-item:last-child .entity-paragraphs-item {
  padding-bottom: 0; }

.node-type-news #scroll-icon {
  display: none; }

.node-type-news #above-footer {
  position: relative; }
  .node-type-news #above-footer:after {
    background: #26ac62;
    bottom: auto;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .node-type-news #above-footer:after {
    top: -60px; }
  .node-type-news #above-footer .above-footer-wrap {
    position: relative;
    z-index: 1; }

@media screen and (max-width: 768px) {
  .node-type-news .node-news {
    padding-left: 30px;
    padding-right: 30px; } }

.news-page-banner {
  color: #fff;
  position: relative;
  min-height: 440px;
  text-align: center; }
  .news-page-banner .news-page-header {
    margin: 0 auto;
    padding: 50px 0;
    max-width: 855px; }
    .news-page-banner .news-page-header h1 {
      font-size: 48px; }
  .news-page-banner .news-page-subheader {
    padding-top: 60px; }
    .news-page-banner .news-page-subheader span {
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
      font-weight: 800;
      font-style: normal;
      font-size: 18px;
      text-transform: uppercase; }

@media screen and (max-width: 768px) {
  .news-page-banner .news-page-header {
    padding-left: 40px;
    padding-right: 40px; }
  .news-page-banner .news-page-header h1 {
    font-size: 28px; } }

.paragraphs-item--news-body,
.paragraphs-item-news-slideshow,
.paragraphs-item-news-quote,
.paragraphs-item-news-divider {
  padding: 65px 0 55px;
  max-width: 855px; }

@media screen and (max-width: 768px) {
  .paragraphs-item--news-body,
  .paragraphs-item-news-slideshow,
  .paragraphs-item-news-quote,
  .paragraphs-item-news-divider {
    padding: 40px 0; } }

.paragraphs-item--news-body {
  font-family: "Roboto Slab", georgia, serif;
  font-weight: 400;
  font-style: normal;
  color: #626262; }
  .paragraphs-item--news-body h1, .paragraphs-item--news-body h2, .paragraphs-item--news-body .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .paragraphs-item--news-body p, .paragraphs-item--news-body h3, .paragraphs-item--news-body h4, .paragraphs-item--news-body h5, .paragraphs-item--news-body h6 {
    font-family: "Roboto Slab", georgia, serif;
    font-weight: 700;
    font-style: normal;
    color: #262626;
    text-transform: none; }
  .paragraphs-item--news-body h1 {
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 32px;
    text-align: center;
    text-transform: uppercase; }
  .paragraphs-item--news-body h2, .paragraphs-item--news-body .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .paragraphs-item--news-body p {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px; }
  .paragraphs-item--news-body h3 {
    font-size: 18px;
    margin-bottom: 6px; }
  .paragraphs-item--news-body h4, .paragraphs-item--news-body h5, .paragraphs-item--news-body h6, .paragraphs-item--news-body p {
    font-size: 16px;
    margin-bottom: 6px; }
  .paragraphs-item--news-body p {
    line-height: 24px;
    margin-bottom: 28px; }
    .paragraphs-item--news-body p:last-child {
      margin-bottom: 0; }

@media screen and (max-width: 768px) {
  .paragraphs-item--news-body {
    width: 100%; }
    .paragraphs-item--news-body h2, .paragraphs-item--news-body .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .paragraphs-item--news-body p {
      font-size: 18px;
      line-height: 24px; }
    .paragraphs-item--news-body p {
      font-size: 14px;
      line-height: 24px; } }

.paragraphs-item-news-slideshow {
  max-width: 920px;
  width: 100%; }
  .paragraphs-item-news-slideshow .field-name-field-news-slideshow-image .field-item {
    display: none; }
  .paragraphs-item-news-slideshow .field-name-field-news-slideshow-image .field-item:first-child {
    display: block; }
  .paragraphs-item-news-slideshow .field-name-field-news-slideshow-image .owl-dots {
    position: absolute;
    bottom: 40px;
    margin: 0 auto;
    width: 100%; }
    .paragraphs-item-news-slideshow .field-name-field-news-slideshow-image .owl-dots .owl-dot {
      outline: 0; }
    .paragraphs-item-news-slideshow .field-name-field-news-slideshow-image .owl-dots .owl-dot.active span {
      background: #fefefe;
      opacity: 1; }
    .paragraphs-item-news-slideshow .field-name-field-news-slideshow-image .owl-dots span {
      height: 8px;
      width: 8px;
      background: #fefefe;
      opacity: 0.35; }
  .paragraphs-item-news-slideshow .field-name-field-news-slideshow-caption {
    color: #a8a8a8;
    font-size: 12px;
    margin-top: 28px;
    text-align: center; }

@media screen and (max-width: 768px) {
  .paragraphs-item-news-slideshow > .content.row {
    margin-left: -30px;
    margin-right: -30px; }
  .paragraphs-item-news-slideshow .col-sm-12 {
    padding: 0; }
  .paragraphs-item-news-slideshow .owl-carousel {
    width: 100vw; }
  .paragraphs-item-news-slideshow .owl-carousel .owl-item img {
    height: auto; } }

.paragraphs-item-news-quote blockquote {
  padding: 0;
  margin: 0 auto;
  border: 0; }

.paragraphs-item-news-quote .field-name-field-news-quote {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase; }

.paragraphs-item-news-quote .field-name-field-news-quote-author {
  color: #888;
  font-family: "Roboto Slab", georgia, serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  margin-top: 26px;
  text-align: center; }

@media screen and (max-width: 768px) {
  .paragraphs-item-news-quote {
    width: 100%; }
    .paragraphs-item-news-quote .field-name-field-news-quote {
      font-size: 20px;
      line-height: 24px; } }

.paragraphs-item-news-divider {
  max-width: 100%;
  width: 100%; }
  .paragraphs-item-news-divider hr {
    background-color: #c8c8c8;
    width: 100%;
    height: 1px;
    margin: 20px auto;
    display: none; }
  .paragraphs-item-news-divider:after {
    background: #c8c8c8;
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    transform: skewY(-1.75deg);
    overflow-x: hidden; }

@media screen and (max-width: 768px) {
  .field-name-field-paragraphs .paragraphs-item-news-divider {
    margin-left: -30px; } }

.news-pager {
  display: flex;
  min-height: 540px;
  position: relative; }
  .news-pager .pager-item {
    display: none; }
  .news-pager > div {
    flex: 0 33.3333%;
    background-size: cover;
    background-position: center center; }
    .news-pager > div a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #fff;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
      font-size: 24px;
      font-weight: 800;
      text-transform: uppercase; }
      .news-pager > div a:hover, .news-pager > div a:focus {
        color: #fff; }
  .news-pager .pager-index {
    background-image: url(../images/banner-news.jpg); }
  .news-pager:after {
    background: #fff;
    bottom: auto;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 1;
    -webkit-backface-visibility: hidden; }
  .news-pager:after {
    top: -260px; }

@media screen and (max-width: 768px) {
  .news-pager {
    flex-wrap: wrap;
    min-height: 465px; }
    .news-pager .pager-prev,
    .news-pager .pager-next,
    .news-pager .pager-index {
      transform: skewY(-1.75deg); }
      .news-pager .pager-prev a,
      .news-pager .pager-next a,
      .news-pager .pager-index a {
        font-size: 20px;
        transform: skewY(1.75deg); }
    .news-pager .pager-prev,
    .news-pager .pager-next {
      position: relative;
      top: 35px;
      order: 1;
      flex: 0 50%;
      z-index: 1; }
    .news-pager .pager-index {
      position: relative;
      margin-top: 25px;
      order: 2;
      flex: 0 100%;
      z-index: 2; } }

#call-to-action-news {
  min-height: 360px;
  display: flex;
  justify-content: center; }
  #call-to-action-news .content {
    position: relative;
    text-align: center;
    z-index: 2; }
    #call-to-action-news .content h2, #call-to-action-news .content .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote #call-to-action-news .content p {
      margin-top: 80px;
      font-size: 42px;
      font-weight: 800; }
    #call-to-action-news .content p {
      font-size: 16px;
      font-weight: 700; }
    #call-to-action-news .content .border-button, #call-to-action-news .content .node-article.node-teaser .links a, .node-article.node-teaser .links #call-to-action-news .content a,
    #call-to-action-news .content .node-news.node-teaser .links a, .node-news.node-teaser .links #call-to-action-news .content a {
      margin-top: 30px;
      border-color: #262626;
      color: #262626; }

@media screen and (max-width: 767px) {
  #call-to-action-news {
    min-height: 250px; }
    #call-to-action-news .content h2, #call-to-action-news .content .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote #call-to-action-news .content p {
      margin-top: 40px;
      font-size: 28px; }
    #call-to-action-news .content p {
      font-size: 14px; } }

#above_content {
  position: relative;
  background: #fff;
  padding-bottom: 40px; }

@media (min-width: 1200px) {
  #above_content .container {
    width: 1020px; }
    #above_content .container > .row {
      margin-top: -200px; } }

@media screen and (max-width: 767px) {
  #above_content .region-above-content {
    margin: 0 20px; }
  #above_content .container > .row {
    margin-top: -140px; } }

.health-callout {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  height: 380px; }
  .health-callout h3 {
    font-size: 20px;
    color: #fff;
    text-transform: none;
    text-align: center;
    line-height: 32px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: 600;
    font-style: normal; }
  .health-callout h6 {
    margin: 0;
    font-size: 12px;
    color: #fff;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 100; }
  .health-callout p {
    font-size: 14px;
    color: #fff;
    line-height: 24px;
    text-align: center;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    max-width: 220px;
    margin: 0 auto; }
    .health-callout p img {
      width: 100%; }
  .health-callout input {
    color: #fff;
    background: #35a4bd;
    border: 0;
    height: 50px;
    font-size: 14px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: 600;
    font-style: normal; }
    .health-callout input::placeholder {
      color: #fff; }
  .health-callout .health-callout__wrap img {
    padding-top: 60px;
    max-width: 220px;
    margin: 0 auto; }
  .health-callout .form-item {
    margin: 0; }
  .health-callout .webform-component--name {
    margin-top: 24px; }
  .health-callout .form-actions {
    max-width: 150px;
    margin: 0 auto; }
  .health-callout input.form-submit {
    margin: 0;
    width: 100%;
    background: #6dc8dc;
    border-radius: 25px;
    padding: 7px 20px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-weight: 700;
    font-style: normal; }
  .health-callout .owl-carousel {
    height: 380px; }
  .health-callout .owl-nav {
    position: absolute;
    text-align: right;
    width: 100%;
    bottom: 35px;
    right: 30px; }
  .health-callout .owl-dots {
    display: none; }
  .health-callout .owl-carousel .owl-nav button {
    outline: 0; }
  .health-callout .owl-carousel .owl-nav .arrow-left {
    display: block;
    height: 15px;
    width: 9px;
    background: url(../images/arrow-right@2x.png) no-repeat 0 0 transparent;
    background-size: 9px 15px; }
  .health-callout .owl-carousel .owl-nav .arrow-right {
    margin-right: 30px;
    display: block;
    height: 15px;
    width: 9px;
    background: url(../images/arrow-left@2x.png) no-repeat 0 0 transparent;
    background-size: 9px 15px; }

@media screen and (min-width: 1200px) {
  .health-callout .webform-component--title {
    max-width: 220px;
    margin: 0 auto; }
  .health-callout .webform-component--name,
  .health-callout .webform-component--email-address {
    max-width: 260px;
    margin: 0 auto; } }

.health-callout__award h6, .health-callout__award p {
  color: #000; }

.health-callout__award .health-callout__wrap {
  height: 380px;
  width: 100%; }

.health-callout__clients .health-callout__wrap {
  height: 380px;
  width: 100%; }

.health-callout__clients .owl-carousel .owl-nav .arrow-left {
  background: url(../images/arrow-right-white@2x.png) no-repeat 0 0 transparent;
  background-size: 9px 15px; }

.health-callout__clients .owl-carousel .owl-nav .arrow-right {
  background: url(../images/arrow-left-white@2x.png) no-repeat 0 0 transparent;
  background-size: 9px 15px; }

.health-callout__newsletter {
  background: #3BB5D0; }
  .health-callout__newsletter .content {
    padding: 30px; }
  .health-callout__newsletter h6 {
    position: relative;
    top: 0;
    left: 0; }

@media screen and (max-width: 767px) {
  #above_content {
    padding-bottom: 0; }
  .health-callout {
    height: 280px; }
    .health-callout h3 {
      font-size: 16px;
      line-height: 24px; }
    .health-callout h6 {
      font-size: 10px;
      top: 20px;
      left: 20px; }
    .health-callout p {
      font-size: 12px;
      line-height: 18px;
      padding: 10px 18px; }
    .health-callout img {
      padding: 0 18px; }
    .health-callout .owl-carousel {
      height: 280px; }
    .health-callout .owl-nav {
      bottom: 16px;
      right: 20px; }
    .health-callout .health-callout__wrap {
      height: 280px; }
      .health-callout .health-callout__wrap img {
        padding-top: 40px; }
    .health-callout input {
      margin-bottom: 16px;
      height: 44px; }
    .health-callout .webform-component--name {
      margin-top: 16px; }
  .health-callout__newsletter .content {
    padding: 20px; }
  .health-callout__newsletter h6 {
    top: 0;
    left: 0; } }

.lightbox {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.88);
  opacity: 0;
  transition: opacity 0.5s;
  overflow-y: scroll; }
  .lightbox h1 {
    color: #000;
    font-size: 32px;
    font-family: "Gotham Bold", sans-serif;
    font-weight: 700;
    text-transform: none;
    margin: 14px 0 24px; }
  .lightbox p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px; }
  .lightbox strong {
    color: #000;
    font-family: "Gotham Medium", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px; }
  .lightbox a {
    font-family: "Gotham Medium", sans-serif;
    font-weight: 500;
    color: #535353; }
    .lightbox a:hover, .lightbox a:focus {
      text-decoration: underline; }
    .lightbox a.close {
      float: none;
      font-size: inherit;
      line-height: inherit;
      text-shadow: none;
      opacity: 1; }
      .lightbox a.close:focus, .lightbox a.close:hover {
        color: inherit; }
  .lightbox .box {
    font-size: 12px;
    font-family: "Gotham Book", sans-serif;
    color: #535353;
    font-weight: 300; }
    .lightbox .box > div:first-of-type {
      padding: 20px 30px 140px;
      background-color: #fff; }
  .lightbox .owl-carousel {
    margin-top: -100px; }
    .lightbox .owl-carousel img,
    .lightbox .owl-carousel div {
      width: 295px; }
    .lightbox .owl-carousel img {
      margin: 0 auto;
      display: block;
      position: relative;
      height: auto;
      max-width: 100vw; }
    .lightbox .owl-carousel button.owl-dot {
      background-image: url("../images/dot.svg");
      width: 6px;
      height: 6px;
      margin: 0 5px;
      opacity: 0.5; }
      .lightbox .owl-carousel button.owl-dot.active {
        opacity: 1; }
  .lightbox .owl-stage-outer,
  .lightbox .owl-dots {
    margin: 0 auto; }
  .lightbox .owl-dots {
    text-align: center;
    top: -35px;
    position: relative; }
  .lightbox span.close {
    display: block;
    position: absolute;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: url(../images/icon-close-ribbon-black.png) center center no-repeat;
    background-size: contain;
    right: 20px;
    top: 20px;
    opacity: 0.6;
    transition: opacity 0.1s linear; }
    .lightbox span.close:hover {
      opacity: 1; }
  .lightbox p.close {
    font-family: "Gotham Medium", sans-serif;
    font-weight: 500;
    color: #fff;
    margin: 35px 0 45px;
    transition: color 0.1s linear;
    text-align: center;
    opacity: 1;
    text-shadow: none;
    float: none; }
    .lightbox p.close:hover {
      color: #fffa; }
  @media screen and (min-width: 768px) {
    .lightbox h1 {
      margin-top: 0; }
    .lightbox .box {
      margin: 100px 142px 100px; }
      .lightbox .box > div:first-of-type {
        padding: 60px 60px 140px; }
    .lightbox .owl-carousel {
      margin-top: -80px; }
      .lightbox .owl-carousel img,
      .lightbox .owl-carousel div {
        width: 430px; }
    .lightbox span.close {
      position: fixed;
      background-image: url(../images/icon-close-ribbon.png);
      right: 35px;
      top: 35px;
      opacity: 1; }
      .lightbox span.close:hover {
        opacity: 0.6; }
    .lightbox p.close {
      display: none; } }
  @media screen and (min-width: 1200px) {
    .lightbox .box {
      margin: 0; }
      .lightbox .box > div:first-of-type {
        padding: 60px;
        padding-right: 140px;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-450px, -50%);
        width: 550px;
        height: 550px; }
    .lightbox .owl-carousel {
      position: absolute;
      margin-top: 0;
      top: 50%;
      left: 50%;
      transform: translate(20px, -49%); }
      .lightbox .owl-carousel img,
      .lightbox .owl-carousel div {
        width: 471px; }
      .lightbox .owl-carousel img {
        margin: 0; }
    .lightbox .owl-stage-outer,
    .lightbox .owl-dots {
      margin: 0; }
    .lightbox .owl-dots {
      width: 471px; }
    .lightbox span.close {
      right: calc(calc(50% - 50vw) - 140px);
      top: calc(calc(50% - 50vh) + 35px); } }

body.front.lb-show {
  overflow: hidden; }
  body.front.lb-show .lightbox {
    opacity: 1; }

.node-type-sampling-page .work-with-us-form,
.node-type-sampling-page .paragraphs-item-multi-icon-cta,
.node-type-sampling-page .paragraphs-item-fullwidth-cta,
.node-type-sampling-page .paragraphs-item-two-column-cta {
  transform: skewY(-2.5deg); }

.node-type-sampling-page .paragraphs-item-two-column-cta .cta-content > * {
  transform: skewY(2.5deg); }

.node-type-sampling-page .paragraphs-item-two-column-cta .cta-image {
  position: relative;
  overflow: hidden; }
  .node-type-sampling-page .paragraphs-item-two-column-cta .cta-image:after {
    content: '';
    position: absolute;
    height: 105%;
    width: 105%;
    transform: translate(-50%, -50%) skewY(2.5deg);
    top: 50%;
    left: 50%;
    background-position: center;
    background-size: cover; }

.node-type-sampling-page .paragraphs-item-multi-icon-cta > .content {
  transform: skewY(2.5deg); }

.node-type-sampling-page .paragraphs-item-fullwidth-cta .cta-content {
  transform: skewY(2.5deg); }
  @media only screen and (min-width: 769px) {
    .node-type-sampling-page .paragraphs-item-fullwidth-cta .cta-content {
      transform: translate(-50%, -50%) skewY(2.5deg); } }

@media only screen and (max-width: 768px) {
  .node-type-sampling-page .paragraphs-item-fullwidth-cta .cta-image {
    position: relative;
    overflow: hidden; }
    .node-type-sampling-page .paragraphs-item-fullwidth-cta .cta-image:after {
      content: '';
      position: absolute;
      height: 105%;
      width: 105%;
      transform: translate(-50%, -50%) skewY(2.5deg);
      top: 50%;
      left: 50%;
      background-position: center;
      background-size: cover; } }

@media only screen and (min-width: 769px) {
  .node-type-sampling-page .paragraphs-item-fullwidth-cta {
    position: relative;
    overflow: hidden; }
    .node-type-sampling-page .paragraphs-item-fullwidth-cta:after {
      content: '';
      position: absolute;
      height: 105%;
      width: 105%;
      transform: translate(-50%, -50%) skewY(2.5deg);
      top: 50%;
      left: 50%;
      background-position: center;
      background-size: cover; } }

@media only screen and (max-width: 768px) {
  .node-type-sampling-page .paragraphs-items-field-sections {
    margin-top: -20px; } }

.node-type-sampling-page .work-with-us-form > * {
  transform: skewY(2.5deg); }

.node-type-sampling-page #page-wrapper {
  background-color: #fff; }

.node-type-sampling-page .node-sampling-page {
  padding: 0; }

.node-type-sampling-page #main-wrapper:after {
  display: none; }

.node-type-sampling-page #main {
  padding: 0; }

.node-type-sampling-page #above-footer {
  padding: 200px 35px 150px;
  color: #fff;
  font-weight: 500;
  position: relative;
  z-index: -1; }
  .node-type-sampling-page #above-footer:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #26ac62;
    position: absolute;
    top: 0;
    left: 0;
    transform: skewY(-2.5deg);
    z-index: -2; }
  .node-type-sampling-page #above-footer p {
    font-size: 10px; }
    @media only screen and (min-width: 768px) {
      .node-type-sampling-page #above-footer p {
        font-size: 12px; } }

.node-type-sampling-page > svg:last-of-type {
  display: none; }

.node-type-sampling-page .work-with-us-form {
  background: #fff;
  padding: 50px 35px 130px; }
  @media only screen and (min-width: 769px) {
    .node-type-sampling-page .work-with-us-form {
      padding: 120px 35px 160px; } }
  @media only screen and (min-width: 1200px) {
    .node-type-sampling-page .work-with-us-form {
      padding: 200px 35px; } }
  .node-type-sampling-page .work-with-us-form .captcha {
    padding: 20px 0; }
    @media only screen and (min-width: 769px) {
      .node-type-sampling-page .work-with-us-form .captcha {
        padding: 25px 10px; } }
  .node-type-sampling-page .work-with-us-form:before {
    display: none; }
  .node-type-sampling-page .work-with-us-form h2, .node-type-sampling-page .work-with-us-form .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-type-sampling-page .work-with-us-form p {
    margin: 0 0 20px;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px; }
    @media only screen and (min-width: 769px) {
      .node-type-sampling-page .work-with-us-form h2, .node-type-sampling-page .work-with-us-form .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-type-sampling-page .work-with-us-form p {
        font-size: 36px;
        line-height: 46px; } }
    @media only screen and (min-width: 1200px) {
      .node-type-sampling-page .work-with-us-form h2, .node-type-sampling-page .work-with-us-form .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .node-type-sampling-page .work-with-us-form p {
        font-size: 50px;
        line-height: 60px; } }
  .node-type-sampling-page .work-with-us-form .form-item {
    padding: 0; }
  .node-type-sampling-page .work-with-us-form .webform-component {
    margin: 0 0 20px; }
  .node-type-sampling-page .work-with-us-form .webform-component--body {
    font-weight: 300; }
    .node-type-sampling-page .work-with-us-form .webform-component--body p:first-child {
      margin-bottom: 40px; }
    .node-type-sampling-page .work-with-us-form .webform-component--body .required {
      margin-bottom: 50px; }
  .node-type-sampling-page .work-with-us-form #edit-submitted-industry {
    max-width: initial; }
  .node-type-sampling-page .work-with-us-form .form-actions {
    padding: 0;
    margin-top: 50px;
    margin-bottom: 0; }
    .node-type-sampling-page .work-with-us-form .form-actions .submit-wrapper {
      margin: 0 auto;
      padding: 14px 40px 18px;
      background-color: #2acc71;
      border-radius: 28px;
      cursor: pointer;
      max-width: none; }
      @media only screen and (max-width: 768px) {
        .node-type-sampling-page .work-with-us-form .form-actions .submit-wrapper {
          display: inline-block;
          left: 50%;
          transform: translateX(-50%);
          position: relative; } }
    .node-type-sampling-page .work-with-us-form .form-actions .webform-submit {
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
      width: auto;
      padding: 0; }
      @media only screen and (min-width: 1200px) {
        .node-type-sampling-page .work-with-us-form .form-actions .webform-submit {
          font-size: 16px; } }
    .node-type-sampling-page .work-with-us-form .form-actions .icon {
      position: relative;
      display: inline-block;
      transform: none;
      right: auto; }
  .node-type-sampling-page .work-with-us-form .resizable-textarea .grippie {
    background: url("../images/grip.svg") no-repeat;
    cursor: se-resize;
    width: 8px;
    right: 0;
    position: absolute;
    bottom: 0;
    margin: 5px;
    height: 8px; }
  @media only screen and (min-width: 769px) {
    .node-type-sampling-page .work-with-us-form .webform-component-textfield:nth-child(2n),
    .node-type-sampling-page .work-with-us-form .webform-component-phone {
      margin-right: 20px; }
    .node-type-sampling-page .work-with-us-form .webform-component-textarea {
      margin-bottom: 0; }
    .node-type-sampling-page .work-with-us-form .form-item.flex-50 {
      flex: 1 calc(50% - 10px); }
    .node-type-sampling-page .work-with-us-form .form-item.flex-40 {
      flex: 1 calc(40% - 10px); }
    .node-type-sampling-page .work-with-us-form .form-item.flex-60 {
      flex: 1 calc(60% - 10px); }
    .node-type-sampling-page .work-with-us-form .form-actions {
      margin-top: 35px; } }

.page-node-282 #main,
.page-node-86 #main,
.page-node-87 #main {
  padding-bottom: 36px; }

.page-node-282 .thank-you-alt-logo,
.page-node-86 .thank-you-alt-logo {
  width: 100%; }
  .page-node-282 .thank-you-alt-logo img,
  .page-node-86 .thank-you-alt-logo img {
    display: block;
    margin: 0 auto;
    position: relative;
    top: 50%; }

.interior-page-banner.nid-246,
.interior-page-banner.nid-261 {
  max-height: 785px; }
  .interior-page-banner.nid-246 h1,
  .interior-page-banner.nid-261 h1 {
    color: #fff;
    font-size: 44px;
    font-weight: 600;
    text-transform: none;
    line-height: 40px; }
  .interior-page-banner.nid-246 .banner-copy,
  .interior-page-banner.nid-261 .banner-copy {
    max-width: 640px;
    margin-top: 14px;
    padding: 0;
    color: #fff; }
    .interior-page-banner.nid-246 .banner-copy p,
    .interior-page-banner.nid-261 .banner-copy p {
      font-size: 14px;
      line-height: 26px; }
  @media screen and (max-width: 600px) {
    .interior-page-banner.nid-246,
    .interior-page-banner.nid-261 {
      max-height: 82vh; }
      .interior-page-banner.nid-246 h1,
      .interior-page-banner.nid-261 h1 {
        margin-left: 40px;
        margin-right: 40px;
        font-size: 30px;
        line-height: 38px; }
      .interior-page-banner.nid-246 .banner-copy,
      .interior-page-banner.nid-261 .banner-copy {
        margin-left: 25px;
        margin-right: 25px; }
      .interior-page-banner.nid-246 .banner-copy p,
      .interior-page-banner.nid-261 .banner-copy p {
        font-size: 13px;
        line-height: 23px; } }

.page-node-246 {
  color: #000; }
  .page-node-246 #main-wrapper {
    background: #fff;
    position: relative; }
    .page-node-246 #main-wrapper:after {
      background: #26ac62;
      bottom: -160px;
      content: '';
      height: 300px;
      overflow: visible;
      position: absolute;
      transform: skewY(-1.75deg);
      width: 100%;
      overflow-x: hidden;
      z-index: 0;
      -webkit-backface-visibility: hidden; }
  .page-node-246 #main {
    padding-top: 80px; }
  .page-node-246 .interior-page-banner p {
    margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    .page-node-246.not-front .node {
      padding: 0; } }

#paragraph-id-6 {
  position: relative;
  padding-top: 180px;
  padding-bottom: 100px; }
  #paragraph-id-6:before {
    background: #f8f8f8;
    left: 0;
    bottom: calc(100% - 200px);
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  #paragraph-id-6:before {
    height: 120px;
    bottom: calc(100% - 80px); }
  @media screen and (max-width: 768px) {
    #paragraph-id-6 {
      padding-top: 160px; } }

#paragraph-id-21 {
  margin-top: 10px;
  padding-bottom: 80px; }
  #paragraph-id-21:after {
    background: none !important; }
  #paragraph-id-21 h2, #paragraph-id-21 .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote #paragraph-id-21 p {
    font-size: 44px;
    font-weight: 700;
    text-transform: none;
    margin-top: 80px; }
    @media (max-width: 768px) {
      #paragraph-id-21 h2, #paragraph-id-21 .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote #paragraph-id-21 p {
        font-size: 32px; } }

#paragraph-id-131 {
  position: relative;
  padding-top: 80px;
  padding-bottom: 120px;
  background: #f8f8f8; }
  #paragraph-id-131:before {
    background: #f8f8f8;
    left: 0;
    bottom: calc(100% - 200px);
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  @media screen and (max-width: 600px) {
    #paragraph-id-131 {
      padding-top: 60px;
      padding-bottom: 30px; } }
  #paragraph-id-131:before {
    height: 120px;
    bottom: calc(100% - 80px); }
  #paragraph-id-131:after {
    content: none; }

#paragraph-id-351 {
  position: relative;
  background: #f8f8f8;
  padding: 40px 0 180px 0;
  margin-top: 200px; }
  #paragraph-id-351:before {
    background: #f8f8f8;
    left: 0;
    bottom: calc(100% - 200px);
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  #paragraph-id-351::after {
    content: none; }

#paragraph-id-356 {
  position: relative;
  padding: 100px 0 180px 0; }
  #paragraph-id-356:before {
    background: #fff;
    left: 0;
    bottom: calc(100% - 200px);
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  #paragraph-id-356::before {
    height: 120px;
    bottom: calc(100% - 80px); }
  #paragraph-id-356::after {
    content: none; }

.webform-client-form-266 .webform-component-radios .form-radios > .form-item {
  clear: both;
  display: block;
  overflow: hidden;
  margin-bottom: 30px; }
  .webform-client-form-266 .webform-component-radios .form-radios > .form-item input {
    width: 30px;
    margin-top: 0; }
  .webform-client-form-266 .webform-component-radios .form-radios > .form-item label {
    position: relative !important;
    margin-left: 0;
    padding-left: 0; }

.webform-client-form-266 .submit-wrapper {
  position: relative;
  max-width: 150px;
  background-color: #888;
  margin-top: 10px; }

.webform-client-form-266 .form-submit {
  width: 100%;
  position: relative;
  margin: 0;
  padding: 8px 18px;
  border: 0;
  background-color: #888;
  color: #fff;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  z-index: 1; }

.not-front.page-node-261 #client-logo-section {
  margin-top: -240px; }

.not-front.page-node-261 #client-logo-list-grid {
  margin-top: 0; }

.not-front.page-node-261 #client-logo-list-header {
  display: block; }
  .not-front.page-node-261 #client-logo-list-header h1 {
    font-size: 44px;
    text-transform: capitalize; }
  .not-front.page-node-261 #client-logo-list-header h3 {
    font-size: 14px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    color: #000; }

.not-front.page-node-261 #client-logo-list-header:after {
  transform: skewY(-1.75deg); }

.not-front.page-node-261 #header:after {
  background: #fff !important; }

.not-front.page-node-261 #page-wrapper,
.not-front.page-node-261.parachute-active #page-wrapper {
  background: #fff !important; }

@media only screen and (min-width: 768px) {
  .not-front.page-node-261 #client-logo-section {
    margin-top: -360px; } }

.interior-page-banner .interior-page-banner-title {
  top: 48%; }

.interior-page-banner a.work-with-us {
  color: #000;
  font-size: 14px;
  height: 50px;
  border-radius: 25px;
  display: block;
  padding: 12px 38px;
  margin: 0 auto;
  max-width: 230px;
  text-align: left;
  background: url(../images/icon_heartbeat_white@2x.png) no-repeat top 16px right 40px #fff;
  background-size: 34px 17px; }

@media screen and (max-width: 767px) {
  .interior-page-banner.nid-246 .banner-copy p {
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 18px; }
  .interior-page-banner.nid-246 .banner-copy a.work-with-us {
    height: 44px;
    border-radius: 22px;
    font-size: 13px;
    max-width: 220px;
    background-position: top 13px right 40px; } }

.node-type-news .user-picture {
  display: inline-block;
  float: left; }
  .node-type-news .user-picture img {
    border-radius: 50%;
    width: 60px;
    height: 60px; }

.node-type-news .author-info {
  max-width: 855px;
  margin-left: auto;
  margin-right: auto;
  color: #626262; }
  .node-type-news .author-info .author {
    color: #262626;
    font-size: 16px; }

/*
Marketing Landing page
*/
.node-type-marketing-page #main-wrapper {
  position: relative; }
  .node-type-marketing-page #main-wrapper:after {
    background: #26ac62;
    bottom: -160px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .node-type-marketing-page #main-wrapper::after {
    height: 200px; }

.node-type-marketing-page .work-with-us-form {
  position: relative; }
  .node-type-marketing-page .work-with-us-form:before {
    background: #f8f8f8;
    left: 0;
    bottom: calc(100% - 200px);
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }

.node-type-marketing-page #main {
  padding-top: 0;
  padding-bottom: 0; }

.node-type-marketing-page .node-marketing-page {
  padding: 0; }

/*
Marketing Landing page banner
*/
.marketing-banner {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  position: relative;
  padding: 260px 0 180px;
  color: #fff;
  text-align: center; }
  .marketing-banner h1 {
    font-size: 44px;
    line-height: 62px;
    margin-bottom: 80px;
    text-transform: none; }

.marketing-banner--cta a {
  font-weight: 800;
  display: inline-block;
  color: #fff;
  border: 3px solid #fff;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 14px 40px; }

/*
Paragraph: Marketing Content
*/
.paragraphs-item-marketing-content {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  padding: 80px 15px 130px;
  position: relative; }
  .paragraphs-item-marketing-content:before {
    background: #e8e8e8;
    left: 0;
    bottom: 0px;
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .paragraphs-item-marketing-content h2, .paragraphs-item-marketing-content .paragraphs-item-quote .field-name-field-quote p, .paragraphs-item-quote .field-name-field-quote .paragraphs-item-marketing-content p {
    text-align: center; }
    .paragraphs-item-marketing-content h2 + p, .paragraphs-item-marketing-content .paragraphs-item-quote .field-name-field-quote p + p, .paragraphs-item-quote .field-name-field-quote .paragraphs-item-marketing-content p + p {
      text-align: center;
      max-width: 780px;
      margin: 0 auto; }
  .paragraphs-item-marketing-content h3 {
    text-transform: none;
    margin-bottom: 30px; }
  .paragraphs-item-marketing-content h4 {
    font-size: 18px;
    line-height: 28px;
    text-transform: initial; }
  .paragraphs-item-marketing-content p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
    color: #626262; }
  .paragraphs-item-marketing-content p strong {
    font-size: 16px;
    line-height: 26px;
    color: #262626; }
  .paragraphs-item-marketing-content ul {
    padding-left: 20px;
    color: #626262; }
    .paragraphs-item-marketing-content ul li {
      padding-bottom: 4px; }
  .paragraphs-item-marketing-content .container {
    max-width: 880px; }
  .paragraphs-item-marketing-content.has-bg-green {
    position: relative;
    background: #26ac62;
    color: #fff;
    text-align: center; }
    .paragraphs-item-marketing-content.has-bg-green:before {
      background: #26ac62;
      left: 0;
      bottom: calc(100% - 200px);
      content: '';
      height: 300px;
      overflow: visible;
      position: absolute;
      transform: skewY(-1.75deg);
      width: 100%;
      overflow-x: hidden;
      z-index: 0;
      -webkit-backface-visibility: hidden; }
    .paragraphs-item-marketing-content.has-bg-green .container {
      max-width: 780px;
      margin: 0 auto; }
    .paragraphs-item-marketing-content.has-bg-green h4 {
      font-weight: 400; }
  .paragraphs-item-marketing-content.has-bg-white {
    position: relative;
    background: #fff; }
    .paragraphs-item-marketing-content.has-bg-white:before {
      background: #fff;
      left: 0;
      bottom: calc(100% - 200px);
      content: '';
      height: 300px;
      overflow: visible;
      position: absolute;
      transform: skewY(-1.75deg);
      width: 100%;
      overflow-x: hidden;
      z-index: 0;
      -webkit-backface-visibility: hidden; }
  .paragraphs-item-marketing-content.has-bg-gray {
    position: relative;
    background: #f8f8f8; }
    .paragraphs-item-marketing-content.has-bg-gray:before {
      background: #f8f8f8;
      left: 0;
      bottom: calc(100% - 200px);
      content: '';
      height: 300px;
      overflow: visible;
      position: absolute;
      transform: skewY(-1.75deg);
      width: 100%;
      overflow-x: hidden;
      z-index: 0;
      -webkit-backface-visibility: hidden; }
  .paragraphs-item-marketing-content.has-bg-green::before, .paragraphs-item-marketing-content.has-bg-white::before, .paragraphs-item-marketing-content.has-bg-gray::before {
    height: calc(0.030552763298588862 * 100vw) !important;
    top: 0px !important;
    transform: translateY(-50%) skewY(1.75deg); }
  .paragraphs-item-marketing-content .flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .paragraphs-item-marketing-content .flex-wrapper .flex-item-3 {
      flex: 0 0 100%; }
      .paragraphs-item-marketing-content .flex-wrapper .flex-item-3 br {
        display: none; }
      @media (min-width: 992px) {
        .paragraphs-item-marketing-content .flex-wrapper .flex-item-3 {
          flex: 0 0 31%; }
          .paragraphs-item-marketing-content .flex-wrapper .flex-item-3 br {
            display: inline; } }

.node-marketing-page .paragraphs-item-logo-grid {
  padding: 80px 15px 130px;
  text-align: center;
  position: relative; }
  .node-marketing-page .paragraphs-item-logo-grid:before {
    background: #fff;
    left: 0;
    bottom: calc(100% - 200px);
    content: '';
    height: 300px;
    overflow: visible;
    position: absolute;
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    -webkit-backface-visibility: hidden; }
  .node-marketing-page .paragraphs-item-logo-grid::before {
    height: calc(0.030552763298588862 * 100vw) !important;
    top: 0px !important;
    transform: translateY(-50%) skewY(1.75deg); }
  .node-marketing-page .paragraphs-item-logo-grid .field-name-field-title {
    margin-bottom: 10px;
    font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 44px;
    font-weight: 700; }
  .node-marketing-page .paragraphs-item-logo-grid .field-name-field-body {
    max-width: 640px;
    margin: 0 auto; }

.node-marketing-page .paragraphs-item-logo-grid .field-name-field-grid {
  max-width: 1160px;
  margin: 70px auto 0;
  padding-bottom: 120px; }
  .node-marketing-page .paragraphs-item-logo-grid .field-name-field-grid > .field-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto; }
    .node-marketing-page .paragraphs-item-logo-grid .field-name-field-grid > .field-items > .field-item {
      align-self: center;
      flex: 0 25%; }
      @media (max-width: 1000px) {
        .node-marketing-page .paragraphs-item-logo-grid .field-name-field-grid > .field-items > .field-item {
          flex: 0 33%; } }
      @media (max-width: 768px) {
        .node-marketing-page .paragraphs-item-logo-grid .field-name-field-grid > .field-items > .field-item {
          flex: 0 50%; } }
      .node-marketing-page .paragraphs-item-logo-grid .field-name-field-grid > .field-items > .field-item img {
        width: 100%;
        max-width: 100%;
        height: auto; }
  .node-marketing-page .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo {
    position: relative;
    overflow: hidden;
    height: 120px;
    margin: 0;
    padding: 20px; }
    .node-marketing-page .paragraphs-item-logo-grid .field-name-field-grid .paragraphs-item-logo .field-type-image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
