.lightbox {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.88);
  opacity: 0;
  transition: opacity 0.5s;
  overflow-y: scroll;

  h1 {
    color: #000;
    font-size: 32px;
    font-family: "Gotham Bold", sans-serif;
    font-weight: 700;
    text-transform: none;
    margin: 14px 0 24px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  strong {
    color: #000;
    font-family: "Gotham Medium", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
  }

  a {
    font-family: "Gotham Medium", sans-serif;
    font-weight: 500;
    color: #535353;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &.close {
      float: none;
      font-size: inherit;
      line-height: inherit;
      text-shadow: none;
      opacity: 1;

      &:focus,
      &:hover {
        color: inherit;
      }
    }
  }

  .box {
    font-size: 12px;
    font-family: "Gotham Book", sans-serif;
    color: #535353;
    font-weight: 300;

    > div:first-of-type {
      padding: 20px 30px 140px;
      background-color: #fff;
    }
  }

  .owl-carousel {
    margin-top: -100px;

    img,
    div {
      width: 295px;
    }

    img {
      margin: 0 auto;
      display: block;
      position: relative;
      height: auto;
      max-width: 100vw;
    }

    button.owl-dot {
      background-image: url('../images/dot.svg');
      width: 6px;
      height: 6px;
      margin: 0 5px;
      opacity: 0.5;

      &.active {
        opacity: 1;
      }
    }
  }

  .owl-stage-outer,
  .owl-dots {
    margin: 0 auto;
  }

  .owl-dots {
    text-align: center;
    top: -35px;
    position: relative;
  }

  span.close {
    display: block;
    position: absolute;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: url(../images/icon-close-ribbon-black.png) center center no-repeat;
    background-size: contain;
    right: 20px;
    top: 20px;
    opacity: 0.6;
    transition: opacity 0.1s linear;

    &:hover {
      opacity: 1;
    }
  }

  p.close {
    font-family: "Gotham Medium", sans-serif;
    font-weight: 500;
    color: #fff;
    margin: 35px 0 45px;
    transition: color 0.1s linear;
    text-align: center;
    opacity: 1;
    text-shadow: none;
    float: none;

    &:hover {
      color: #fffa;
    }
  }

  @media screen and (min-width: 768px) {
    h1 {
      margin-top: 0;
    }

    .box {
      margin: 100px 142px 100px;

      > div:first-of-type {
        padding: 60px 60px 140px;
      }
    }

    .owl-carousel {
      margin-top: -80px;

      img,
      div {
        width: 430px;
      }
    }

    span.close {
      position: fixed;
      background-image: url(../images/icon-close-ribbon.png);
      right: 35px;
      top: 35px;
      opacity: 1;

      &:hover {
        opacity: 0.6;
      }
    }

    p.close {
      display: none;
    }
  }

  @media screen and (min-width: 1200px) {
    .box {
      margin: 0;

      > div:first-of-type {
        padding: 60px;
        padding-right: 140px;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-450px, -50%);
        width: 550px;
        height: 550px;
      }
    }

    .owl-carousel {
      position: absolute;
      margin-top: 0;
      top: 50%;
      left: 50%;
      transform: translate(20px, -49%);

      img,
      div {
        width: 471px;
      }

      img {
        margin: 0;
      }
    }

    .owl-stage-outer,
    .owl-dots {
      margin: 0;
    }

    .owl-dots {
      width: 471px;
    }

    span.close {
      right: calc(calc(50% - 50vw) - 140px);
      top: calc(calc(50% - 50vh) + 35px);
    }
  }
}

body.front.lb-show {
    overflow: hidden;

  .lightbox {
    opacity: 1;
  }
}
