.paragraphs-item-multi-icon-cta {
  padding: 50px 35px 120px;

  .field-name-field-cta {
    .field-items {
      display: flex;
    }

    .field-item,
    a {
      display: block;
      float: left;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      margin-top: 85px;
    }
  }
}

@media only screen and (min-width: 769px) {
  .paragraphs-item-multi-icon-cta {
    padding: 120px 80px 150px;
    text-align: center;

    > .content {
      max-width: 864px;
      margin: 0 auto;
    }

    .field-name-field-body {
      margin-bottom: 56px;
      text-align: justify;
      text-align-last: center;
    }

    .field-name-field-icons {
      > .field-items {
        display: flex;

        > .field-item {
          flex: 1 0 0px;

          &:nth-last-child(n + 2) {
            margin-right: 42px;
          }
        }
      }
    }
  
    .field-name-field-cta {
      .field-item,
      a {
        margin-top: 65px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .paragraphs-item-multi-icon-cta {
    padding: 200px 80px;

    > .content {
      max-width: 1100px;
    }

    .field-name-field-body {
      margin-bottom: 86px;
    }

    .field-name-field-icons {
      > .field-items {
        > .field-item {
          &:nth-last-child(n + 2) {
            margin-right: 80px;
          }
        }
      }
    }
  
    .field-name-field-cta {
      .field-item,
      a {
        margin-top: 85px;
      }
    }
  }
}
