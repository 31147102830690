.interior-page-banner-title .about-us-subtitle {
  font-weight: bold;
  margin-top:23px;
  font-size:16px;
  display: block;
}

/* -------------------- layout/about us -------------------- */
.field-name-body {
  .col-sm-6 p:last-child {
    color:#626262;
  }
  .meet-the-team {
    font-weight:bold;
    text-align: center;
    margin-top:80px;
    cursor: pointer;
    margin-bottom: 110px;
    p:after {
      margin:auto;
      position:relative;
      top:50px;
      content:'';
      display: block;
      width:50px;
      height:2px;
      background:mos-color('black');
    }
  }
}

#block-views-about-us-team-block {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e8e8e8+0,171717+2,171717+100,171717+100 */
  background: #171717; /* Old browsers */
  background: -moz-linear-gradient(top,  rgba(232,232,232,1) 0%, rgba(23,23,23,1) 2%, rgba(23,23,23,1) 100%, rgba(23,23,23,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(232,232,232,1) 0%,rgba(23,23,23,1) 2%,rgba(23,23,23,1) 100%,rgba(23,23,23,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(232,232,232,1) 0%,rgba(23,23,23,1) 2%,rgba(23,23,23,1) 100%,rgba(23,23,23,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e8e8', endColorstr='#171717',GradientType=0 ); /* IE6-9 */

  transform: skewY(-1.75deg);
  backface-visibility: hidden;
  outline:1px solid transparent;
  overflow: hidden;
  .views-row {
    position: relative;
    height: 365px;
    overflow: hidden;
    backface-visibility: hidden;
    transform-style:preserve-3d;
    //transform:translate3d(0,0,0);
    outline:1px solid transparent;
    .image {
      position: absolute;
      top: -10%;
      left: -10%;
      height: 120%;
      width: 120%;
      transform: skewY(1.75deg);
      backface-visibility: hidden;
      .image-inner {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        img {
          width: 100%;
          height: auto !important;
          backface-visibility: hidden;
          transform: translateZ(0);
          image-rendering:pixelated;
        }
      }
    }
    .copy {
      position: absolute;
      top:-3%;
      left:-3%;
      padding: 20px;
      height: 106%;
      width: 106%;
      background: #000;
      background: rgba(8,8,8,0.62);
      text-align: center;
      color: #fff;
      opacity:0;
      transition: opacity 300ms ease;
      .copy-inner {
        opacity: 0;
        position: relative;
        top:50%;
        transform: skewY(1.75deg) translateY(-50%);
        transition: opacity 300ms ease;
        transition-delay: 200ms;
        cursor:default;
      }
      h4 {
        font-family: "Roboto Slab", georgia, serif;
        text-transform: none;
      }
    }
    &:hover .copy, &:active .copy, &:focus .copy {
      opacity:1;
      .copy-inner {
        opacity: 1;
      }
    }
    &:last-of-type {
      a {
        width:100%;
        height:100%;
      }
      .copy-inner {
        cursor:pointer;
        .name:after {
          content:'?';
        }
      }
    }
  }
  .view-footer{
    position:relative;
    top:23px;
    clear:both;
    padding:230px 0;
    transform: skewY(1.75deg);
    background:#171717;
    h1{
      text-align: center;
      cursor:pointer;
      a{
        color: mos-color('white');
        &:after {
          margin:auto;
          position:relative;
          top:30px;
          content:'';
          display: block;
          width:26px;
          height:3px;
          background:mos-color('white');
        }
      }
    }
  }
}


.is-android #block-views-about-us-team-block {
  transform: none;
  .views-row {
    .image {
      transform: none;
    }
    .copy {
      .copy-inner {
        transform: translateY(-50%);
      }
    }
  }
  .view-footer{
    transform: none;
  }
}

.page-node-97{
  #above-footer{
    margin-top:-100px;
    padding-top: 200px;
  }
}

@include max-screen(1250px) {
  #block-views-about-us-team-block .views-row .image .image-inner img {
    width:auto;
    height:375px !important;
    position:relative;
    left:50%;
    transform:translateX(-50%);
  }
}

@include max-screen(767px) {
  #block-views-about-us-team-block .views-row {
    margin-top:0;
    /*.copy {
      top:0;
      left:0;
      height:100%;
    }*/
    .image .image-inner img {
      height:400px !important;
    }
  }
}

@include max-screen(425px) {
  #block-views-about-us-team-block .views-row {
    height:275px !important;
    .image .image-inner img {
      height:300px !important;
    }
  }
}

/* IE10+ specific styles go here */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
  #block-views-about-us-team-block {
    transform: none;
    .views-row{
      .image{
        transform: none;
      }
      .copy{
        .copy-inner{
          transform: none;
          top: 37%;
        }
      }
    }
  }
}