// media queries

$mobile: 600px;
$tablet: 767px;
$desktop: 1024px;



@mixin min-screen($min) {
	@media screen and (min-width: $min) {
		@content;
	}
}

@mixin max-screen($max) {
	@media screen and (max-width: $max) {
		@content;
	}
}

@mixin screen($min, $max) {
	@media screen and (min-width: $min) and (max-width: $max) {
		@content;
	}
}


@mixin mobile {
	@include max-screen($tablet) {
		@content;
	}
}

@mixin tablet {
	@include screen(#{$tablet}, #{$desktop}) {
		@content;
	}
}

@mixin desktop {
	@include min-screen(#{$desktop}) {
		@content;
	}
}