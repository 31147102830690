// angled sections


@mixin angled-section($color: mos-color('white'), $bottom: -200px, $angle: -1.75deg, $zIndex: 0) {
	position: relative;

	&:after {
		background: $color;
		bottom: $bottom;
		content: '';
		height: 300px;
		overflow: visible;
		position: absolute;
		transform: skewY($angle);
		width: 100%;
		overflow-x:hidden;
		z-index: $zIndex;
		-webkit-backface-visibility: hidden;
	}
}

@mixin angled-top-section($color: mos-color('white'), $bottom: calc(100% - 200px), $angle: -1.75deg, $zIndex: 0) {
	position: relative;

	&:before {
		background: $color;
		left:0;
		bottom: $bottom;
		content: '';
		height: 300px;
		overflow: visible;
		position: absolute;
		transform: skewY($angle);
		width: 100%;
		overflow-x:hidden;
		z-index: $zIndex;
		-webkit-backface-visibility: hidden;
	}
}


@mixin beforeColor($color) {
  &:before {
    content:'';
    display: block;
    width:10000px;
    height:100%;
    position:absolute;
    right:100%;
    background:$color;
    top:0;
  }
}

@mixin afterColor($color) {
  &:after {
    content:'';
    display: block;
    width:10000px;
    height:100%;
    position:absolute;
    left:100%;
    background:$color;
    top:0;
  }
}

@mixin clearfix(){
	&:before,
	&:after {
    content: " ";
    display: table;
	}

	&:after {
    clear: both;
	}
}

@include mobile {

	html body.admin-menu {
		margin-top: 0 !important;
	}

	#admin-menu {
		display: none;
	}
}



// horizontal rule

hr {
	background-color: #cdcdcd;
	border: none;
	height: 4px;
	margin: 85px auto 80px;
	width: 135px;
}
