/* -------------------- Project page -------------------- */
.border-button,
.border-button:visited {
  display: inline-block;
  margin: 0 auto;
  padding: 12px 38px;
  border: 3px solid #888;
  font-size: 14px;
  color: #888;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  -webkit-transition: color 300ms ease, border-color 300ms ease, background-color 300ms ease;
  -moz-transition: color 300ms ease, border-color 300ms ease, background-color 300ms ease;
  -ms-transition: color 300ms ease, border-color 300ms ease, background-color 300ms ease;
  -o-transition: color 300ms ease, border-color 300ms ease, background-color 300ms ease;
  transition: color 300ms ease, border-color 300ms ease, background-color 300ms ease;
  &:hover {
    color: #aaa;
    border-color: #aaa;
  }
}

.node-type-project {

  .interior-page-banner{
    min-height:680px;
    max-height:950px;
  }

  .interior-page-banner-title{
    top:52%;
    left:50%;
    transform: translate(-50%, -50%);

    .about-us-subtitle{
      line-height: 26px;
    }
    h1,
    .about-us-subtitle{
      max-width: 790px;
      margin-left: auto;
      margin-right: auto;
      color: #f8f8f8;
    }
  }

  .project-container {
    padding-left:0;
    padding-right:0;
  }
  .node-project {
    padding:0;
  }
  .krumo-root {
    position: relative;
    z-index: 2;
    width: 90%;
    top:10%;
    left:50%;
    transform:translateX(-50%);
  }
  #site-messages {
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
  }
  #main {
    padding-top:0;
  }
  #main-wrapper:after {
    display: none;
  }
  .container, .container-fluid {
    //padding:0;
  }

  .above-footer-wrap {
    padding-top:150px;
  }

}

.node-project {
  .field-name-field-client-logo-image {
    min-height:625px;
    background:#f8f8f8;
    transform:skewY(-1.75deg);
    position:relative;
    margin-top: -41px;

    // center logos
    .field-items {
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) skewY(1.75deg);
      width:100%;
      .field-item {
        display: inline-block;
        max-width: 500px;
        padding: 0 20px;
        img {
          max-width: 100%;
          height:auto !important;
          max-height: 380px;
          backface-visibility:hidden;
        }
      }

      // 1 logo
      .field-item:first-child:nth-last-child(1) {
        width: 100%;
      }
      // 2 logos
      .field-item:first-child:nth-last-child(2),
      .field-item:first-child:nth-last-child(2) ~ .field-item {
        width: 50%;
      }
    }
  }

  .field-name-body {
    .col-sm-6.left-col {
      padding-right:50px;
      margin-top: 100px;
      margin-bottom: 190px;
    }
    .col-sm-6.right-col {
      padding-left:50px;
      margin-top: 100px;
      margin-bottom: 190px;
    }
    h2{
      margin-bottom: 70px;
    }
    p{
      font-size: 14px;
      line-height: 1.65em;
      strong{
        font-size: 16px;
      }
    }
  }



  #project-details {
    background-size:cover;
    background-position:center;
    //-- color actually getting set in node--project.tpl.php using inline css
    @include angled-section(grey, 60px, -1.75deg);
    >.container{
      width: 100%;
      max-width: 1260px;
      padding:0 120px;
    }
    .container.no-padding {
      padding:0;
    }
    .field-name-field-title-subtitle {
      max-width: 800px;
      margin:0 auto;
      color:#f8f8f8;
      padding:293px 0 0;
      margin-bottom: -70px;
      text-align: center;
      h2 {
        margin-bottom: 20px;
        font-size:60px;
        font-weight: 800;
        color: #f8f8f8;
      }
      p {
        font-weight:bold;
      }
    }
    #project-group {
      margin:310px auto -60px;
      padding:0;
      //-- color actually getting set in node--project.tpl.php using inline css
      @include angled-section(grey, -200px, -1.75deg);
      z-index: 2;
      #pg-wrapper {
        max-width:1380px;
        height: 700px;
      }
      #large-bg {
        height:100%;
        background-size:cover;
        background-position:center center;
        padding:0;
      }
      #stack {
        height:100%;
        padding:0;
        #brand-color {
          height:398px;
          position:relative;
          z-index: 1;

          //-- color actually getting set in node--project.tpl.php using inline css
          @include angled-section(grey, -5px, -1.75deg);
          &:after {
            height: 15px;
          }
          .field-name-field-description {
            position:absolute;
            top:54%;
            left:60px;
            right:60px;
            transform:translateY(-50%);
            h3, p {
              margin:0;
              padding:0;
              color:#f8f8f8;
            }
            h3{
              margin-bottom: 37px;
              font-size: 32px;
            }
            p{
              font-size:14px;
              line-height: 24px;
            }
          }
        }
        #small-bg {
          height:50%;
          background-size:cover;
          background-position:center center;
          z-index: 0;
          @include max-screen(768px){
            display:none;
          }
        }
      }

      &.sixty-fourty {
        #stack {
          float:right;
        }
      }
    }
  }

  #project-brand {
    margin-top:-335px;
    min-height: 380px;
    z-index: 1;
    @include angled-section(#f8f8f8, -240px);
    &:after {
      z-index: 10;
    }
    .font-blurb {
      margin:-20px auto 50px;
      display: block;
      max-width: 826px;
      width:100%;
    }
    #font-previews {
      max-width: 890px;
      margin:145px auto 120px;
      span {
        display: inline-block;
        float:left;
        img {
          display: block;
          margin:auto;
          max-width: 380px;
          height:auto;
          width:100%;
        }
      }
      // 1 font image
      span:first-child:nth-last-child(1) {
        width: 100%;
        padding:0 15px;
      }
      // 2 font images
      span:first-child:nth-last-child(2),
      span:first-child:nth-last-child(2) ~ span {
        width: 50%;
        padding:0 15px;
      }
    }
    .font-preview-angle {
      @include angled-section(#f8f8f8,-180px, -1.75deg, 1);
      &:after {
        position:fixed;
        bottom:auto;
        left:0;
        height:150px;
        margin-top:50px;
        @include max-screen(768px){
          position:absolute;
          width:100vw;
          margin-left:-24px;
        }
      }
    }

    #brand-colors {
      // @include angled-top-section(#f8f8f8);
      margin-top: 150px;
      .color {
        height:calc(25vw + 100px);
        width:25%;
        max-height: 400px;
        display: inline-block;
        position:relative;
        color:#fff;
        text-align: center;
        &.secondary-color{
          .color-text-wrap{
            top:49%;
          }
        }
        &.third-color{
          .color-text-wrap{
            top:48%;
          }
        }
        &.fourth-color {
          //-- color actually getting set in node--project.tpl.php using inline css
          //@include afterColor(grey);
          .color-text-wrap{
            top:47%;
          }
        }
        .color-text-wrap {
          position:absolute;
          top:50%;
          left:50%;
          transform:translate(-50%,-50%);
          .hex {
            font-weight: bold;
          }
        }
      }

    }
  }

  .field-name-field-image-and-summary {
    padding-top: 60px;
  }

  .field-name-field-chart-data {
    position: relative;
    .chartKey{
      position:absolute;
      max-width:250px;
      margin:50px 0 0 120px;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
      font-size: 14px;
      font-weight: 800;
      .keyItem{
        padding-bottom:5px;
        line-height: 1.5em;
        text-transform: uppercase;
        span{
          content:'';
          display: block;
          float:left;
          width:18px;
          height:8px;
          margin: 6px 0 6px -30px;
          border-radius: 10px;
        }
      }
    }
    #chartLegend {
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
      font-weight: bold;
      position: absolute;
      left: 0;
      bottom: 330px;
      width: 100%;
      font-weight: bold;
      font-size: 12px;
      line-height: 3em;
      padding: 20px 0;
      color: #FFFFFF;
    }
  }
}

.white-section-wrapper {
  background:#f8f8f8;
  position:relative;
  padding-top: 105px;
  padding-bottom:285px;
  z-index: 1;
  @include angled-section(#ebeced);
  &:after{
    //Fix for Firefox
    left:0;
  }
  .container{
    max-width: 1050px;
    padding-left:30px;
    padding-right:30px;
  }
  .field-type-text-long{
    text-align: center;
    margin-top: -100px;
    h2{
      padding-top: 50px;
      margin-bottom: 70px;
    }
    h3{
      font-family: $serif;
      font-size:16px;
      text-transform: none;
      margin-bottom: 30px;
    }
    p{
      color:#626262;
      font-size:14px;
    }
  }
  img {
    max-width:1170px;
    width:100%;
    height:auto;
    margin:32px auto 20px;
  }
  .field-name-field-summary{
    padding-left:10px;
    padding-right:10px;
  }
  .field-collection-container,
  .field-collection-view{
    padding:0;
    margin:0;
    border:none;
  }
  .field-collection-container .field-item{
    margin-bottom:0px;
  }
  .field-name-field-white-section-images .field-items:last-child {
    margin-bottom:200px;
  }
}

.grey-section-wrapper {
  padding-top:20px;
  padding-bottom:290px;
  background:#ebeced;
  z-index: 1;
  h3{
    font-family: $serif;
    font-size:16px;
    text-transform: none;
  }
  p{
    color:#626262;
    font-size:14px;
  }
  h2,h3,p{
    text-align: center;
  }
  @include angled-section(#262626);
  &:after{
    //Fix for Firefox
    left:0;
  }
  .field-name-field-grey-section-summary{
    max-width: 840px;
    margin: 0 auto;
  }
  .field-name-field-grey-section-images .field-item{
    width:33%;
    padding:50px;
    float:left;
    img {
      width:100%;
      height:auto;
      margin:0 auto;
    }
  }
  .field-name-field-grey-section-images-alt .field-item{
    width:50%;
    padding:24px 28px;
    float:left;
    img {
      width:100%;
      height:auto;
      margin:75px auto;
    }
  }
}

[about="/our-work/howard-leight"]{
  .grey-section-wrapper{
    h2{
      margin-top: 28px;
      margin-bottom: 70px;
      span{
        text-transform: none;
      }
    }
    h3{
      font-size: 16px;
      text-transform: none;
      margin-bottom: 30px;
    }
    p{
      margin-bottom: 70px;
    }
    .field-name-field-grey-section-images-alt .field-item{
      img{
        margin: 5px auto;
      }
    }
    @include angled-section(#171717);
  }
}

.black-section-wrapper {
  background:#262626;
  z-index: 1;
  @include angled-section(#262626, -40px);
  &:after {
    height:150px;
  }
  .field-item{
    display: inline-block;
  }
  img {
    width:100%;
    height:auto;
    margin:130px auto 135px;
  }
  .field-name-field-black-section-images .field-items:last-child {
    margin-bottom:-20px;
    text-align: center;
  }
}

[about="/our-work/howard-leight"]{
  .black-section-wrapper{
    background: #171717;
    img{
      margin-bottom: 260px;
    }
    &:after{
      background: #171717;
    }
  }
}

[about="/our-work/avanir-pharmaceuticals"]{
  .black-section-wrapper{
    img{
      margin-bottom: 100px;
    }
  }
}

.chart-intro-wrapper {
  padding-top: 43px;
  padding-bottom: 119px;
  background: #f8f8f8;
  z-index: 20;
  @include angled-section(#f8f8f8, -40px);
  @include angled-top-section(#f8f8f8);
  .col-sm-8 {
    float: none;
    position: relative;
    z-index: 2;
    padding-left:40px;
    padding-right:30px;
  }
  .field-name-field-chart-intro{
    max-width: 850px;
    margin:0 auto;
    padding-bottom: 25px;
    h2{margin-bottom:70px;}
    h3{
      margin-bottom: 30px;
      font-family: $serif;
      font-size:16px;
      text-transform: none;
    }
    p{
      color:#626262;
      margin-bottom: 12px;
      font-size: 14px;
      strong{
        font-size: 16px;
        color: #262626;
      }
    }
  }
  .border-button{
    padding-left: 62px;
    padding-right: 62px;
    &:hover{
      background-color: #626262;
      border-color: #626262;
      color: #fff;
    }
  }
}
.chart-section-wrapper {
  position: relative;
  padding-top:100px;
}

.case-study-social-wrapper {
  background-size:cover;
  background-position:center;
  margin-top: -60px;
  position: relative;
  z-index: 20;
  .container{
    padding-left:30px;
    padding-right:30px;
  }
  &.has-social-block {
    .case-study-wrapper{
      padding: 180px 10px 140px;
    }
  }
  &.no-social-block {
    margin-top: -220px;
    padding-top: 100px;
    .case-study-wrapper{
      padding: 400px 10px 180px;
    }
  }
  .field-collection-container {
    margin: 0;
    padding: 0;
    border: none;
  }
  .field-name-field-social-block  {
    padding: 0 0 50px;
    margin: -155px 0 0;
    & > .field-items > .field-item {
      width: 50%;
      float: left;
      position: relative;
      overflow: hidden;
      color: #fff;
      opacity: 0;
      transform: translateY(100px);
      transition: transform 600ms ease-in-out,opacity 600ms ease-in-out,-webkit-transform 600ms ease-in-out;
      .field-collection-view {
        padding: 0;
        margin: 0;
        border: none;
      }
      .entity-field-collection-item {
        height: 500px;
        background-position: center center;
        background-size: cover;
        background-color: #060606;
        &.instagram .social-logo {
          background: url(../images/instagram.corner.png) center center no-repeat;
        }
      }
      .shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+26,0.65+100 */
        background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 26%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 26%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 26%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
      }
      .social-description {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 10px 50px 50px;
        font-weight: 800;
        font-size: 14px;
        color: #f8f8f8;
        transform: translateY(-100%);
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 14px;
        }
      }
      a {
        color: #fff;
        display: block;
        position: absolute;
        z-index: 21;
        bottom: 0;
        right: 0;
        opacity: 0.7;
        transition: opacity 500ms ease;
        &:hover {
          opacity: 1;
        }
      }
      .social-logo {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 89px;
        height: 89px;
        z-index: 22;
        background: url(../images/facebook.corner.png) center center no-repeat;
      }
      p {
        margin: 10px 0;
        padding: 0;
      }
      &:nth-child(2){
        transition-delay: 200ms;
      }
    }
  }
  .case-study-wrapper {
    text-align: center;
    color: #f8f8f8;
    h2 {
      font-size: 60px;
    }
    p{
      font-weight: 600;
    }
    .border-button {
      margin-top: 38px;
      color: #f8f8f8;
      border-color: #f8f8f8;
      &:hover {
        color: #171717;
        background: #f8f8f8;
      }
    }
  }
}

.case-study-social-wrapper.in-view{
  .field-name-field-social-block .field-item{
    opacity:1;
    transform: translateY(0);
  }
}

.parachute-active .case-study-social-wrapper{
  .field-name-field-social-block{
    margin-top:0px;
  }
}


[about="/our-work/avanir-pharmaceuticals"]{
  .case-study-wrapper{
    padding: 125px 0 195px;
  }
}

.love-our-work-wrapper {
  padding:180px 0 0;
  background:#171717;
  text-align: center;
  @include angled-section(#171717);
  @include angled-top-section(#171717);
  h1, .about-us-subtitle, a {
    position:relative;
    z-index: 1;
    color:#f8f8f8;
  }
  .about-us-subtitle {
    font-weight: 800;
    margin: 25px 0 45px;
  }
  .border-button {
    color: #d8d8d8;
    border-color: #d8d8d8;
    &:hover {
      color: #171717;
      background: #d8d8d8;
      border-color: #d8d8d8;
    }
  }
  &:after {
    left:0;
  }
}

.project-nav-wrapper{
  position:relative;
  margin-top:-100px;
  background: #171717;
  -webkit-transform: skewY(-1.75deg);
  transform: skewY(-1.75deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
  overflow: hidden;
  z-index: 20;
  a {
    -webkit-transform: skewY(1.75deg);
    transform: skewY(1.75deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: block;
    font-size: 32px;
    @include clearfix();
  }
  .back-nav,
  .next-nav{
    background-size: cover;
    background-position: center center;
    height: calc(50vw - 20px);
    width:50%;
    max-height:700px;
    padding: 20px;
    cursor: pointer;
    float:left;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      background-size: cover;
      background-position: center center;
      color: #f8f8f8;
      text-align: center;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
      font-weight: 800;
      font-style: normal;
      text-transform: uppercase;
    }
  }
  .back-nav{
    background-image: url(../images/mos_banner_ourwork.jpg);
  }
}

.parachute-active {
  .interior-page-banner-title{
    left:0;
  }
  .node-project {
    #project-details {
      #project-group {
        &:after{
          height:100px;
          transition: bottom 600ms ease-in-out,height 600ms ease-in-out;
        }
        #large-bg,
        #small-bg,
        #brand-color{
          opacity:0;
          transition:transform 600ms ease-in-out,opacity 600ms ease-in-out,-webkit-transform 600ms ease-in-out;
        }
        &.in-view{
          &:after{
            bottom:10px;
            height:100px;
          }
          #large-bg,
          #small-bg,
          #brand-color{
            opacity:1;
            transform: translateY(-100px);
          }
          &.fourty-sixty{
            #small-bg{
              transition-delay: 100ms;
            }
            #large-bg{
              transition-delay: 200ms;
            }
          }
          &.sixty-fourty{
            #brand-color{
              transition-delay: 200ms;
            }
            #small-bg{
              transition-delay: 300ms;
            }
            #large-bg{
              transition-delay: 100ms;
            }
          }
        }
      }
    }
    .white-section-wrapper .container {
      padding-top: 0px;
      margin-bottom: -150px;
      .field-name-field-summary {
        padding-bottom: 75px;
      }
      .field-name-field-image {
        opacity:0;
        transform: translateY(100px);
        transition:transform 600ms ease-in-out,opacity 600ms ease-in-out,-webkit-transform 600ms ease-in-out;
      }
      .in-view .field-name-field-image {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    .grey-section-wrapper .container {
      margin-bottom: -70px;
      .field-item img {
        opacity:0;
        transform: translateY(100px);
        transition:transform 600ms ease-in-out,opacity 600ms ease-in-out,-webkit-transform 600ms ease-in-out;
      }
      .field-item:nth-child(3n + 1) img {
        @include triggerDelay(1);
      }
      .field-item:nth-child(3n + 2) img {
        @include triggerDelay(2);
      }
      .field-item:nth-child(3n + 3) img {
        @include triggerDelay(3);
      }
      .in-view.field-item img {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    .black-section-wrapper .container {
      .field-item img {
        opacity:0;
        transform: translateY(100px);
        transition:transform 600ms ease-in-out,opacity 600ms ease-in-out,-webkit-transform 600ms ease-in-out;
      }
      .in-view.field-item img {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
}

.mobile{
  #project-details{
    #project-group{
      &:after{
        height:60px;
        bottom:-50px;
      }
    }
  }

  .white-section-wrapper,
  .grey-section-wrapper,
  .black-section-wrapper,{
    padding-top:0;
    padding-bottom: 200px;
  }

  .white-section-wrapper{
    .field-type-text-long{
      margin-top:0;
    }
  }
  .black-section-wrapper{
    padding-bottom:100px;
  }
  .case-study-social-wrapper{
    .field-name-field-social-block{
      margin-top:-300px;
      > .field-items > .field-item{
        opacity: 1;
      }
    }
  }
}

//Desktop Resolution with parachute disabled
@media screen and (min-width: 769px){
  .mobile{
    .node-project{
      #project-details{
        // margin-bottom: 490px;
        .field-name-field-title-subtitle{
          padding: 200px 0;
          margin-bottom: 0;
        }
        &:after{
          bottom:-206px;
          height:512px;
        }
        #project-group{
          margin: 0 auto;
        }
      }

      #project-brand{
        margin-top:0 !important;
        .font-blurb{
          margin: 200px auto 50px;
        }
      }

      &#node-136{
        #project-brand{
          min-height: 200px;
        }
      }

      .case-study-social-wrapper.no-social-block {
        .case-study-wrapper {
          padding: 110px 10px 260px;
        }
      }
      .case-study-social-wrapper.has-social-block {
        .case-study-wrapper{
          padding: 110px 10px 260px;
        }

      }
    }
  }
  [about="/our-work/avanir-pharmaceuticals"] .black-section-wrapper img {
    margin-bottom: 260px;
  }
}

@include max-screen(900px){
  .node-project #project-details{
    height:890px;

    &:after{
      top:800px;
      bottom:auto;
      height:800px;
    }
    .field-name-field-title-subtitle{
      max-width:690px;
      padding-top:195px;
      transform: none !important;
      h2{
        margin-top:0;
      }
    }
    #project-group{
      margin:0 auto;
      top: 230px;
      transform: none !important;
      &:after{
        left:0;
        bottom:-10px;
        height:20px;
      }
      #stack{
        height:auto !important;
      }
      #brand-color .field-name-field-description{
        top:50%;
      }
      #large-bg{
        max-height:520px;
      }
      .col-sm-5,
      .col-sm-7{
        width:100%;
      }
    }
  }
  .node-project #project-brand{
    margin-top:0px;
    padding-top:800px;
  }
  .case-study-social-wrapper{
    &.no-social-block .case-study-wrapper{
      padding:100px 10px 300px;
    }
    &.has-social-block .case-study-wrapper{
      padding:180px 10px 200px;
    }
    .field-name-field-case-study-text h2{
      margin-top:0;
    }
  }
}

@include mobile {
  .node-type-project{
    #header:after{
      content: none;
    }
    .interior-page-banner{
      min-height: 0;
      height: calc(100vh - 140px);
    }
    .interior-page-banner-title {
      top:50%;
      padding: 0 40px;
      h1{
        margin-bottom: 15px;
        font-size: 28px;
        line-height: 1.25em;
      }
      .about-us-subtitle{
        margin-top:0;
        font-size: 14px;
        line-height: 1.55em;
      }
    }
    h2{
      font-size: 28px;
    }
    #main-wrapper{
      p{
        font-size: 14px;
      }
    }
  }
  .node-project {
    .field-name-field-client-logo-image {
      min-height:initial;
      margin-top: -20px;
      padding-bottom: 15px;
      .field-items {
        position: relative;
        top: inherit;
        transform: translateY(5px) skewY(1.75deg);
        .field-item {
          display: block;
          margin-top:0px;
          width:100% !important;
          float: none;
          padding:165px 65px;
          img {
            max-width: 100%;
            height: auto !important;
          }
          &:first-of-type{
            padding: 165px 65px 0 65px;
          }
          &:last-of-type{
            padding: 165px 65px 0 65px;
            padding-bottom: 165px
          }
        }
      }
    }

    .field-name-body{
      padding-left: 25px !important;
      padding-right: 25px !important;
      .col-sm-6.left-col{
        margin-bottom: 100px;
      }
      .col-sm-6.right-col{
        margin-bottom: 150px;
      }
    }

    .field-type-text-with-summary{
      strong{
        font-size: 16px;
      }
      line-height: 24px;
    }

    #project-details {
      height:665px;
      &:after{
        top:650px;
      }
      >.container{
        padding: 0 30px;
      }
      .field-name-field-title-subtitle {
        padding:125px 40px 208px 40px;
        h2{
          font-size:28px;
        }
      }

      #project-group {
        top: 0;
        margin: 0 auto;
        &:after {
          height: 20px;
          bottom: -10px;
        }
        #pg-wrapper {
          height:initial;
          #brand-color {
            height:auto;
            .field-name-field-description {
              padding: 60px 30px;
              position: relative;
              top: inherit;
              left: inherit;
              right: inherit;
              transform: translateY(0);
              h3{
                font-size: 28px;
              }
            }
            &:after{
              height: 25px;
              bottom: -11px;
            }
          }
          #small-bg {
            display: none;
            height:200px;
          }
          #large-bg {
            height:500px;
          }
        }
      }
    }

    #project-brand {
      min-height: 0;
      margin-top: 0;
      padding-top: 0;
      .font-blurb {
        margin-top:0;
        padding:0 15px;
      }
      #font-previews {
        margin:40px auto;
        span {
          display: block;
          margin-top:0px;
          width:100% !important;
          float: none;
          &:first-child {
            padding:40px 15px !important;
          }
        }
      }
      .font-preview-angle:after {
        height: 75px;
        bottom: -60px;
        width: 100vw;
        margin-left: -15px;
      }
      #brand-colors {
        margin-top:40px;
        max-width: 100vw;
        .color {
          width:50%;
          height:50vw;
          &.third-color,
          &.fourth-color{
            height:calc(50vw + 40px);
            margin-top:-5px;
            .color-text-wrap{
              top:40%;
            }
          }
        }
      }
    }

    .field-name-body {
      .col-sm-6.left-col, .col-sm-6.right-col {
        padding-right:0;
        padding-left:0;
      }
    }

    .white-section-wrapper {
      padding-top: 35px;
      padding-bottom: 205px;
      .field-type-text-long{
        h3, p{
          line-height: 1.65em;
        }
      }
      .field-name-field-image-and-summary {
        padding-top: 0;
        > .field-items > .field-item {
          padding: 20px 0;
          margin-bottom: -10px;
          h2{
            margin-bottom: 30px;
          }
          &:first-of-type{
            h2{
              padding-top:0;
            }
          }
        }
      }
      img {
        margin: 10px auto;
      }
    }

    .grey-section-wrapper {
      padding-top: 0 !important;
      padding-bottom: 205px;
      .field-name-field-grey-section-summary{
        h3, p{
          line-height: 1.65em;
        }
      }

      .field-name-field-grey-section-images .field-item,
      .field-name-field-grey-section-images-alt .field-item{
        width:50%;
        padding: 0 15px;
      }
      .field-item img {
        margin: 16px auto;
      }
    }

    .grey-section-wrapper + .chart-intro-wrapper{
      margin-top:-60px;
    }

    [about="/our-work/howard-leight"]{
      .grey-section-wrapper{
        .field-name-field-grey-section-images-alt .field-item{
          img{
            margin: 16px auto;
          }
        }
      }
    }

    .black-section-wrapper {
      padding-bottom:120px;
      &:after {
        display: none;
      }
      .field-item {
        width: 80%;
        padding: 0;
        img {
          margin: 10px auto;
        }
      }
    }

    .chart-intro-wrapper{
      padding-top: 35px;
      padding-bottom: 80px;
      &:before {
        height: 240px;
      }
      &:after {
        bottom: -10px;
      }
      p,h3{
        line-height: 1.65em;
      }
    }

    .field-name-field-chart-data .chartKey{
      position:relative;
      margin:0 0 0 60px;
    }

    .case-study-social-wrapper{
      .container{
        padding-left: 30px;
        padding-right: 30px;
      }
      margin-top:0;
      .case-study-wrapper{
        h2{
          font-size:30px;
        }
      }
      .field-name-field-social-block > .field-items > .field-item .social-description{
        padding: 0 25px 30px;
      }
      &.no-social-block{
        .case-study-wrapper{
          padding:0 0 160px;
        }
      }
    }

    #mosChart + .case-study-social-wrapper{
      margin-top: -220px;
      &.has-social-block{
        margin-top:0;
      }
    }

    .case-study-social-wrapper .field-name-field-social-block > .field-items > .field-item {
      width: 100%;
      float: none;
      opacity: 1;
      display: none;
      &:first-child{
        display: block;
      }
      .entity-field-collection-item{
        height:400px;
      }
    }

    //Birch Aquarium
    &#node-136{
      #project-brand{
        margin-top:calc(700px - 40%) !important;
      }
    }
  }

  .parachute-active .grey-section-wrapper {
    .field-name-field-grey-section-images .field-item{
      margin-top:-6%;
      padding:0 2%;
      img{
        margin:0 auto;
      }
    }
  }

  .project-nav-wrapper {
    position:relative;
    margin-top: -10px;
    z-index: 21;
    .back-nav,
    .next-nav {
      a {
        font-size: 18px;
        line-height: 1.25em;
      }
    }
  }
  .love-our-work-wrapper {
    padding:100px 0 50px;
    &:before { height: 230px; }
    &:after {
      height: 230px;
      bottom: -80px;
    }
  }
}

@media screen and (max-width: 450px){
  .node-project {
    #project-details {
      #project-group {
        #pg-wrapper {
          #large-bg {
            height:325px;
          }
        }
      }
    }
    &#node-131{
      .font-blurb{
        margin-top:80px;
      }
    }
  }
}
