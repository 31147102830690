/*
MOS styled accordion
*/
.panel-group-mos {
  .panel+.panel {
    margin: 0;
  }
}

// Panel
.panel-group-mos .panel-mos {
  border: 0;
  border-radius: 0;
  background: transparent;
  border-top: 2px solid #cdcdcd;
  box-shadow: none;

  &:last-child {
    border-bottom: 2px solid #cdcdcd;
  }
}

// Heading
.panel-mos>.panel-heading {
  background: transparent;
  border-radius: 0;
  border: 0;
  padding: 20px 0;

  h4 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: mos-color('green');
  }

  a {
    display: block;
  }
}

// Body
.panel-mos .panel-collapse>.panel-body {
  border: 0 !important;
  padding-top: 0;
}
