.paragraphs-item-icon {
  text-align: center;
  
  .field-name-field-icon {
    margin: 67px auto 25px;
    line-height: 144px;

    img {
      min-height: 54px;
      width: auto;
    }
  }
}

.paragraphs-items-field-icons .field-items > .field-item {
  opacity: 1;
  transition: opacity 0.75s ease-in-out;

  &:nth-child(1) {
    transition-delay: 0.5s;
  }
  &:nth-child(2) {
    transition-delay: 1s;
  }
  &:nth-child(3) {
    transition-delay: 1.5s;
  }
  &:nth-child(4) {
    transition-delay: 2s;
  }
  &:nth-child(5) {
    transition-delay: 2.5s;
  }
}

.not-visible .paragraphs-items-field-icons .field-items > .field-item {
  opacity: 0;
}

@media only screen and (min-width: 769px) {
  .paragraphs-item-icon {    
    .field-name-field-icon {
      margin: 0 auto 25px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .paragraphs-item-icon {    
    .field-name-field-icon {
      margin-bottom: 35px;
    }
  }
}
