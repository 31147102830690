/* -------------------- layout/client logos -------------------- */


// header

#client-logo-list-header {
	padding: 110px 0 220px;
	@include angled-section(mos-color('black'), -245px, -1.75deg);

	h1 {
		margin-top: 0;
		margin-bottom: 18px;
		text-align: center;
		font-size: 44px;
		font-weight: 700;
		text-transform: none;
	}

	h3 {
		color: #000000;
		text-align: center;
		text-transform: none;
		font-size: 14px;
		font-weight: 500;
	}
}


@include tablet {}

@include mobile {
	#client-logo-list-header {
		@include angled-section(mos-color('black'), -245px, 1.75deg);
		padding: 0 0 120px;

		h1 {
			font-size: 24px;
			margin-bottom: 10px;
		}

		h3 {
			font-size: 12px;
			margin-top: 10px;
		}
	}
}


.not-front #client-logo-list-header {
	display: none;
}


.not-front #main-wrapper {
	@include angled-section(mos-color('black'), -400px, 1.75deg);
}

@include tablet {}

@include mobile {

	.not-front #main-wrapper:after {
		bottom: -370px;
	}

}



// grid

#client-logo-list-grid {
	background: mos-color('black');
	padding: 100px 0 200px;

	.client-logo-list-grid-inner {
		padding-left: 140px;
		padding-right: 140px;
	}

	.client-logo-list-grid-inner > .row > div {
		padding-left: 0;
		padding-right: 0;

		img {
			opacity: 0.6;
			transition: opacity 300ms;

			&:hover {
				opacity: 1;
			}
		}
	}

	img {
		width: 100%;
	}

	.show-more img {
		display: block;
		height: 23px;
		margin: 40px auto 0;
		transform: rotate(0);
		width: 23px;
		@include easeOutQuint(rotate, 1000ms);

		&.active {
			transform: rotate(45deg);
		}
	}
}

#client-logo-list-grid {
	@include angled-section(mos-color('green'), -240px);
}


@include tablet {
	#client-logo-list-grid {
		.client-logo-list-grid-inner {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

@include mobile {

	#client-logo-list-grid {
		padding: 0 0 160px;

		.client-logo-list-grid-inner {
			padding-left: 30px;
			padding-right: 30px;
		}

		.col-xs-6 {
			margin-top: 0;
		}
	}

}


.not-front #client-logo-list-grid {
	margin-top: 160px;
}

@include tablet {}

@include mobile {
	.not-front #client-logo-list-grid {
		margin-top: 130px;
	}
}
