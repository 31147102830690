.page-node-282 #main,
.page-node-86 #main,
.page-node-87 #main {
	padding-bottom: 36px;
}


.page-node-282,
.page-node-86 {

	.thank-you-alt-logo {
		width: 100%;
		img {
			display: block;
			margin: 0 auto;
			position: relative;
			top: 50%;
			// transform: translate(0, -50%);
		}
	}

}
