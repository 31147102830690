.node-type-sampling-page {
  .work-with-us-form,
  .paragraphs-item-multi-icon-cta,
  .paragraphs-item-fullwidth-cta,
  .paragraphs-item-two-column-cta {
    transform: skewY(-2.5deg);
  }

  .paragraphs-item-two-column-cta {
    .cta-content > * {
      transform: skewY(2.5deg);
    }

    .cta-image {
      position: relative;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        height: 105%;
        width: 105%;
        transform: translate(-50%, -50%) skewY(2.5deg);
        top: 50%;
        left: 50%;
        background-position: center;
        background-size: cover;
      }
    }
  }

  .paragraphs-item-multi-icon-cta {
    > .content {
      transform: skewY(2.5deg);
    }
  }

  .paragraphs-item-fullwidth-cta {
    .cta-content {
      transform: skewY(2.5deg);

      @media only screen and (min-width: 769px) {
        transform: translate(-50%, -50%) skewY(2.5deg);
      }
    }

    @media only screen and (max-width: 768px) {
      .cta-image {
        position: relative;
        overflow: hidden;

        &:after {
          content: '';
          position: absolute;
          height: 105%;
          width: 105%;
          transform: translate(-50%, -50%) skewY(2.5deg);
          top: 50%;
          left: 50%;
          background-position: center;
          background-size: cover;
        }
      }
    }

    @media only screen and (min-width: 769px) {
      position: relative;
      overflow: hidden;
      
      &:after {
        content: '';
        position: absolute;
        height: 105%;
        width: 105%;
        transform: translate(-50%, -50%) skewY(2.5deg);
        top: 50%;
        left: 50%;
        background-position: center;
        background-size: cover;
      }
    }
  }

  .paragraphs-items-field-sections {
    @media only screen and (max-width: 768px) {
      margin-top: -20px;
    }
  }
  
  .work-with-us-form > * {
    transform: skewY(2.5deg);
  }

  #page-wrapper {
    background-color: #fff;
  }

  .node-sampling-page {
    padding: 0;
  }

  #main-wrapper {
    &:after {
      display: none;
    }
  }

  #main {
    padding: 0;
  }

  #above-footer {
    padding: 200px 35px 150px;
    color: #fff;
    font-weight: 500;
    position: relative;
    z-index: -1;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: #26ac62;
      position: absolute;
      top: 0;
      left: 0;
      transform: skewY(-2.5deg);
      z-index: -2;
    }

    p {
      font-size: 10px;

      @media only screen and (min-width: 768px) {
        font-size: 12px;
      }
    }
  }

  > svg:last-of-type {
    display: none;
  }

  .work-with-us-form {
    background: #fff;
    padding: 50px 35px 130px;

    @media only screen and (min-width: 769px) {
      padding: 120px 35px 160px;
    }

    @media only screen and (min-width: 1200px) {
      padding: 200px 35px;
    }

    .captcha {
      padding: 20px 0;

      @media only screen and (min-width: 769px) {
        padding: 25px 10px;
      }
    }
    
    &:before {
      display: none;
    }

    h2 {
      margin: 0 0 20px;
      font-size: 30px;
      font-weight: 700;
      line-height: 40px;

      @media only screen and (min-width: 769px) {
        font-size: 36px;
        line-height: 46px;
      }

      @media only screen and (min-width: 1200px) {
        font-size: 50px;
        line-height: 60px;
      }
    }

    .form-item {
      padding: 0;
    }

    .webform-component {
      margin: 0 0 20px;
    }

    .webform-component--body {
      font-weight: 300;

      p:first-child {
        margin-bottom: 40px;
      }
      .required {
        margin-bottom: 50px;
      }
    }
    
    #edit-submitted-industry {
      max-width: initial;
    }

    .form-actions {
      padding: 0;
      margin-top: 50px;
      margin-bottom: 0;

      .submit-wrapper {
        margin: 0 auto;
        padding: 14px 40px 18px;
        background-color: #2acc71;
        border-radius: 28px;
        cursor: pointer;
        max-width: none;

        @media only screen and (max-width: 768px) {
          display: inline-block;
          left: 50%;
          transform: translateX(-50%);
          position: relative;
        }
      }

      .webform-submit {
        font-weight: 700;
        font-size: 14px;
        line-height: 1;
        width: auto;
        padding: 0;

        @media only screen and (min-width: 1200px) {
          font-size: 16px;
        }
      }

      .icon {
        position: relative;
        display: inline-block;
        transform: none;
        right: auto;
      }
    }

    .resizable-textarea .grippie {
      background: url("../images/grip.svg") no-repeat;
      cursor: se-resize;
      width: 8px;
      right: 0;
      position: absolute;
      bottom: 0;
      margin: 5px;
      height: 8px;
    }

    @media only screen and (min-width: 769px) {
      .webform-component-textfield:nth-child(2n),
      .webform-component-phone {
        margin-right: 20px;
      }

      .webform-component-textarea {
        margin-bottom: 0;
      }

      .form-item {
        &.flex-50 {
          flex: 1 calc(50% - 10px);
        }

        &.flex-40 {
          flex: 1 calc(40% - 10px);
        }

        &.flex-60 {
          flex: 1 calc(60% - 10px);
        }
      }

      .form-actions {
        margin-top: 35px;
      }
    }
  }
}
