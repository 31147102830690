.parachute-active #page-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
  background:#e8e8e8;
}

.parachute-active #page {
	position: absolute;
	left: 0;
	width: 100%;
}

#fakeScrollContainer {}

// Basic Page
// Page Sections Field Styles
.page-full-width {
	padding-left: 0;
	padding-right: 0;
	.node-page {
		.field-name-body {
			max-width: 945px;
			margin: 0 auto;
			padding-bottom: 100px;
		}
	}
}

.field-name-field-page-sections {
	.entity-paragraphs-item {
		margin-left: -15px;
		margin-right: -15px;
		padding: 0;
		@include angled-section(mos-color('white'), -50px, -1.75deg);
		&:after {
			left: 0;
			height: 100px;
		}
	}
	.field-name-field-title {
		margin-bottom: 10px;
		font-family: $sans;
		font-size: 44px;
		font-weight: 700;
		@media screen and (max-width: $tablet + 1) {
		  font-size: 32px;
		}
	}
	.field-name-field-body {
		font-family: $sans;
		font-size: 14px;
		font-weight: 500;
		line-height: 26px;
		p {
			font-size: inherit;
      @media screen and (max-width: $tablet + 1) {
        font-size: 13px;
        line-height: 23px;
      }
		}
	}
}

.parachute-disabled .field-name-field-page-sections {
  .entity-paragraphs-item {
    margin-left: 0;
    margin-right: 0;
  }
}

.field-name-field-page-sections .paragraphs-item-logo-grid {
	text-align: center;
  > .content {
    @media screen and (max-width: $tablet + 1) {
      padding: 0 45px; 
    } 
  }
	.field-name-field-body {
		max-width: 640px;
		margin: 0 auto;
	}
	.field-name-field-logos,
	.field-name-field-grid {
		max-width: 1160px;
		margin: 70px auto 0;
		padding-bottom: 120px;
    @media screen and (max-width: $tablet + 1) {
      margin-top: 45px;
      margin-left: -30px;
      margin-right: -30px;
      padding-bottom: 50px;
    }
		> .field-items {
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			> .field-item {
				align-self: center;
				flex: 0 25%;
				// padding: 50px 67px;
				@media screen and (max-width: 1000px) {
				  flex: 0 33%;
				}
				@media screen and (max-width: $tablet + 1) {
				  flex: 0 50%;
				}
				img {
					width: 100%;
					max-width: 100%;
					height: auto;
				}

			}
		}
		.paragraphs-item-logo {
			position: relative;
			overflow: hidden;
			height: 120px;
      margin: 0;
      padding: 20px;
      @media screen and (max-width: $mobile) {
        height: 80px;
      }
			.field-name-field-link {
				a {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					text-indent: -9999px;
					overflow: hidden;
				}
			}
      &.hover-text {
        .field-name-field-hover-text {
          position: relative;
          padding-left: 50px;
          color: #888;
          font-family: $sans;
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          .field-item:after {
            content: '';
            display: inline-block;
            width: 35px;
            height: 10px;
            margin-left: 20px;
            background-image: url(../images/icon-arrow-grey.svg);
            background-repeat: no-repeat;
          }
        }
        .field-name-field-logo-image {
          transition: margin 500ms;
        }
        &:hover {
          .field-name-field-logo-image {
            margin-top: -40px;
          }
        }
      }
      .field-type-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      &.hover-image {

        .field-name-field-logo-image {
          transition: opacity 750ms;
        }
        .field-name-field-hover-image {
          opacity: 0;
          transition: opacity 500ms;
        }
        &:hover {
          .field-name-field-hover-image {
            opacity: 1;
          }
          .field-name-field-logo-image {
            opacity: 0;
          }
        }
      }
		}
		.entity-paragraphs-item:after {
			content: none;
		}
	}
}

// Basic Page Paragraph Styles

// Two Column Paragraph Styles
.field-name-field-page-sections .paragraphs-item-content {
  padding: 200px 0 100px;
  .content {
    max-width: 890px;
    margin: 0 auto;
    > .field {
      flex: 0 1 400px;
      margin: 0 90px 0 0;
      h2 {
        margin-bottom: 65px;
        font-weight: 700;
        text-transform: none;
        @media screen and (max-width: $mobile) {
          margin-bottom: 50px;
        }
      }
      h3 {
        margin-bottom: 30px;
        color: #000;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        text-transform: none;
        @media screen and (max-width: $mobile) {
          font-size: 15px;
          line-height: 25px;
        }
      }
      p {
        margin-bottom: 30px;
        color: #626262;
        line-height: 24px;
        font-family: $sans;
        font-size: 14px;
        @media screen and (max-width: $tablet + 1) {
          font-size: 13px;
        }
      }
      ul {
        padding-left: 17px;
        color: #626262;
        line-height: 24px;
        list-style-type: none;
      }
      ul li:before {
        content: '•';
        float: left;
        margin-left: -17px;
        font-size: 14px;
        color: #626262;

      }
      ul li p {
        margin-bottom: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .flex-wrapper {
        display: flex;
        flex-wrap: wrap;
        .flex-item {
          flex: 1 auto;
        }
      }
    }
  }
  @media screen and (max-width: $tablet + 1) {
    padding: 150px 0;
    .content {
      display: block;
      > .field {
        margin-bottom: 100px;
        padding: 0 45px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

// Two Column Paragraph Styles
.field-name-field-page-sections .paragraphs-item-two-column-text {
	padding: 200px 0 100px;
	.content {
		max-width: 890px;
		margin: 0 auto;
		display: flex;
		> .field {
      flex: 0 1 400px;
			margin: 0 90px 0 0;
			h2 {
				margin-bottom: 65px;
				font-weight: 700;
				text-transform: none;
			}
			p {
				margin-bottom: 30px;
				color: #626262;
				line-height: 24px;
				font-family: $sans;
				font-size: 14px;
			}
			p strong {
				color: #000;
				font-weight: 500;
        font-size: 16px;
			}
      &:last-child {
        margin-right: 0;
      }
		}
	}
	@media screen and (max-width: $tablet + 1) {
		padding: 150px 0;
		.content {
			display: block;
			> .field {
				margin-bottom: 100px;
				padding: 0 45px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

// Featured Paragraph Styles
.field-name-field-page-sections .paragraphs-item-featured {
	position: relative;
	padding: 0;
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
	> .content {
		background-size: 100%;
		background-position: center top;
		background-repeat: no-repeat;
	  @media screen and (max-width: $tablet + 1) {
	    background-size: 1000px;
	  }
	}
	.field-name-field-body {
		max-width: 640px;
		padding: 293px 0 0;
		margin: 0 auto;
		margin-bottom: -70px;
		color: #fff;
		text-align: center;
		h2 {
			margin-top: 0;
			font-size: 44px;
			font-weight: 700;
			line-height: 54px;
			text-transform: none;
		}
		@media screen and (max-width: 768px) {
			padding: 125px 50px 190px 50px;
		  transform: none !important;
		  h2 {
		  	font-size: 32px;
        line-height: 40px;
		  }
		}
	}
	.container {
		position: relative;
		width: 100%;
    max-width: 1260px;
    padding: 0 120px;
    z-index: 1;
    @media screen and (max-width: $tablet) {
      padding: 0 45px;
    }
	}
	.featured-group {
		position: relative;
		margin: 310px auto -60px;
		padding-bottom: 0;
		@media screen and (max-width: 900px) {
		  transform: none !important;
		}
		@media screen and (max-width: $tablet + 1) {
			top: 0;
			margin: 0 auto;
		}
		.wrapper {
			display: flex;
			max-width: 1380px;
	    height: 700px;
	    @media screen and (max-width: $tablet + 1) {
	      display: block;
	      height: initial;
	    }
		}
		&:after {
	    background: #f8f8f8;
	    bottom: -60px;
	    content: '';
	    height: 520px;
	    overflow: visible;
	    position: absolute;
	    -webkit-transform: skewY(-1.75deg);
	    transform: skewY(-1.75deg);
	    width: 100%;
	    overflow-x: hidden;
	    z-index: 0;
	    @media screen and (max-width: $tablet + 1) {
	      bottom: -10px;
        height: 20px;
	    }
		}
		.col-one > div,
		.col-two > div {
			background-size: cover;
		}
		&.fourty-sixty {
			.col-one {
				flex: 1 40%;
			}
			.col-two {
				flex: 1 60%;
			}
		}
		.field-name-field-description {
	    height: 398px;
	    position: relative;
	    z-index: 1;
			background: #000;
			color: #fff;
			font-family: $sans;
			@include angled-section(#000, -5px, -1.75deg);
			&:after {
				height: 20px;
			}
			@media screen and (max-width: $tablet + 1) {
			  height: auto;
			}
			.field-items {
				position: absolute;
		    top: 54%;
		    left: 60px;
		    right: 60px;
		    -webkit-transform: translateY(-50%);
		    transform: translateY(-50%);
				@media screen and (max-width: $tablet + 1) {
				  padding: 60px 30px;
			    position: relative;
			    top: inherit;
			    left: inherit;
			    right: inherit;
			    transform: translateY(0);
				}
			}
			h3 {
				margin-top: 0;
				margin-bottom: 30px;
				font-size: 32px;
				font-weight: 600;
        line-height: 1.3;
				text-transform: none;
			}
			p {
				margin-bottom: 30px;
				font-size: 14px;
				line-height: 24px;
			}
			a {
				color: inherit;
				font-weight: 600;
			}
			@media screen and (max-width: $tablet + 1) {
			  h3 {
			  	font-size: 24px;
			  }
        p {
          font-size: 13px;
          line-height: 23px;
        }
			  p:last-child {
		  		margin-bottom: 0;
			  }
			}
		}
		.small-bg {
			height: 50%;
	    background-size: cover;
	    background-position: center center;
	    z-index: 0;
		}
		.large-bg {
			width: 100%;
			height: 100%;
			@media screen and (max-width: $tablet + 1) {
			  height: 400px;
			}
		}
	}
	&:after {
		background: #fff;
    bottom: -50px !important;
    content: '';
    height: 530px !important;
    overflow: visible;
    position: absolute;
    -webkit-transform: skewY(-1.75deg);
    transform: skewY(-1.75deg);
    width: 100%;
    overflow-x: hidden;
    z-index: 0;
    @media screen and (max-width: $tablet + 1) {
      bottom: 200px !important;
    }
	}
}

// When Parachute isn't running
.parachute-disabled .paragraphs-item-featured {
  margin-left: 0;
  margin-right: 0;
  @media screen and (min-width: $tablet + 2) {
    padding-bottom: 150px;
    .featured-group {
      margin-top: 120px;
      &:after {
        height: 70px;
      }
    }    
  }
}

.field-name-field-page-sections .paragraphs-item-slideshow {
	padding: 80px 0 240px;
	@media screen and (max-width: $tablet + 1) {
    padding-bottom: 150px;
	  &:after {
	  	bottom: -20px;
	  }
	}
	/* Slider */
	.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
	}
	.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
	}
	.slick-slider .slick-track,
	.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
	}
	.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
	}
	.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
      float: right;
    }
    img {
      display: block;
    }
    &.slick-loading img {
      display: none;
    }
    &.dragging img {
      pointer-events: none;
    }

    .slick-initialized & {
      display: block;
    }

    .slick-loading & {
      visibility: hidden;
    }

    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
	}
	.slick-arrow {
		position: absolute;
		top: 50%;
		appearance: none;
		display: block;
		width: 28px;
		height: 28px;
		text-indent: -9999px;
		background-image: url(../images/icon-chevron.svg);
		background-color: transparent;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		border: none;
		overflow: hidden;
		transform: translateY(-50%);
		z-index: 1;
		&.slick-prev {
      top: 45%;
			left: 120px;
			transform: scaleX(-1);
		}
		&.slick-next {
			right: 120px;
		}
		&:focus {
			outline: none;
		}
		@media screen and (max-width: $tablet + 1) {
		  &.slick-prev {
		  	left: 20px;
		  }
		  &.slick-next {
		  	right: 20px;
		  }
		}
	}
	.slick-arrow.slick-hidden {
	    display: none;
	}
}

.field-name-field-page-sections .paragraphs-item-testimonial {
	> .content {
		max-width: 960px;
		width: 100%;
		margin: 0 auto;
		padding: 0 80px;
		text-align: center;
    @media screen and (max-width: $tablet + 1) {
      padding: 0 30px;
    }
	}
	.field-name-field-title {
		margin-bottom: 60px;
	}
	p {
		margin-bottom: 30px;
		font-weight: 500;
	}
	.field-name-field-author p{
		color: #626262;
    font-family: $sans;
    font-size: 14px;
		font-weight: 400;
    line-height: 26px;
    @media screen and (max-width: $tablet + 1) {
      font-size: 13px;
      line-height: 23px;
    }
	}
	&:after {
		content: none !important;
	}
}

// Parallax Animations
.parachute-active {
  .paragraphs-item-featured {
    .featured-group {
      &:after{
        height:100px;
        transition: bottom 600ms ease-in-out,height 600ms ease-in-out;
      }
      .large-bg,
      .small-bg,
     	.field-name-field-description {
        opacity:0;
        transition:transform 600ms ease-in-out,opacity 600ms ease-in-out,-webkit-transform 600ms ease-in-out;
      }
      &.in-view{
        &:after{
          bottom: -10px;
          height: 120px;
          @media screen and (max-width: $tablet + 1) {
            bottom: 90px;
            height: 30px;
          }
        }
        .large-bg,
        .small-bg,
        .field-name-field-description{
          opacity:1;
          transform: translateY(-100px);
        }
        &.fourty-sixty{
          .small-bg{
            transition-delay: 100ms;
          }
          .large-bg{
            transition-delay: 200ms;
          }
        }
        &.sixty-fourty{
          .field-name-field-description{
            transition-delay: 200ms;
          }
          .small-bg{
            transition-delay: 300ms;
          }
          .large-bg{
            transition-delay: 100ms;
          }
        }
      }
    }
  }
}
