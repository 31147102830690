/* -------------------- layout/header -------------------- */

#header {
  @include angled-section(mos-color('white'), -260px, -1.75deg);
}

@include max-screen(767px) {
  #header {
    &:after {
      background-clip:padding-box;
      border-top: 1px solid transparent;
    }
  }
}

.page-node-56 #header {
  @include angled-section(mos-color('white'), -260px, 1.75deg);
}


// MOS LOGO
#mos-logo {
  right: 50px;
  opacity: 1;
  position: fixed;
  top: 50px;
  transform: translateX(0px);
  transition: transform 800ms ease-in-out,
              opacity 800ms ease-in-out;
  z-index: 400;

  img {
    width: 130px;
  }
}

.admin-menu #mos-logo {
  top: 80px;
}

.logo-hide:not(.mos-menu-open) #mos-logo {
  opacity: 0;
  transition: transform 700ms ease-in-out,
              opacity 400ms ease-in-out;
}

.mos-menu-open #mos-logo {
  opacity: 0.6;
  // transform: translateX(10px);
}

@include screen(769px, 1023px) {}

@include max-screen(783px) {
  .mos-menu-open #mos-logo {
    opacity: 0;
    transform: translateX(0px);
  }
}

@include mobile {
  #mos-logo {
    right: 30px;
    top: 30px;
    img {
      width: 110px;
    }
  }

  .admin-menu #mos-logo {
    top: 30px;
  }
}


// MENU ICON
.menu-icon {
  position: fixed;
  left: 50px;
  top: 54px;
  z-index: 1000;

  img {
    width: 45px;
  }

  .menu-icon__open {
    opacity: 1;
    position: absolute;
    // right: 0;
    transition: opacity 400ms;
  }

  .menu-icon__close {
    opacity: 0;
    position: absolute;
    top: -6px;
    left: 5px;
    // right: 0;
    transition: opacity 400ms;

    img {
      width: 35px;
    }
  }

  &.active {
    .menu-icon__open {
      opacity: 0;
    }
    .menu-icon__close {
      opacity: 1;
    }
  }
}

.backgroundblendmode.not-android .menu-icon {
  mix-blend-mode:difference;
}

.admin-menu .menu-icon {
  top: 80px;
}

.menu-icon__wrapper {
  height: 23px !important;
  right: 50px !important;
}

@include tablet {}

@include mobile {
  .admin-menu .menu-icon {
    top: 30px;
  }
  .menu-icon {
    top: 30px;
    left: 30px;
  }
  .front.logo-hide:not(.mos-menu-open) .menu-icon,
  .front.logo-hide:not(.mos-menu-close) .menu-icon{
    top: 30px;
    transition: top 0.5s ease-in-out;
  }
}

.menu-icon + .secondary-menu {
  position: fixed;
  top: 52px;
  left: 100px;
  z-index: 1000;
  mix-blend-mode: difference;
  h2 {
    display: none;
  }
  .menu {
    padding-left: 0;
    list-style-type: none;

    li {
      display: inline-block;
      margin: 0 0 0 45px;
    }
    li a {
      color: #fff;
      font-size: 12px;
      font-family: $sans;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.admin-menu .menu-icon + .secondary-menu {
  top: 78px;
}

#scroll-icon {
  position: absolute;
  bottom: 80px;
  margin: 0 auto;
  width: 100%;

  img {
    display: block;
    margin: 0 auto;
  }
}

@media screen and (max-width: $tablet) {
  .page-node-246 #scroll-icon {
    display: none;
  }
}

// Awards Ribbon
#header-ribbon{
  position:fixed;
  bottom: -100px;
  width: 100%;
  z-index: 2;
  color: #e7f8ed;
  background-color: #2acc71;
  opacity: 1;
  text-align: center;
  padding: 8px 40px;
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
  font-weight: 500;
  min-height: 36px;
  transition: bottom 500ms ease;
  span{
    color: #e7f8ed;
    font-weight: bold;
  }
  span.dismiss {
    display: block;
    position: absolute;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: url(../images/icon-close-ribbon.png) center center no-repeat;
    background-size: contain;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}
body.show-ribbon #header-ribbon {
  bottom: 0;
}
@media screen and (max-width: $tablet) {
  #header-ribbon {
    text-align: left;
    padding-left: 16px;
    font-size: 13px;
  }
}
@media screen and (max-width: $mobile) {
  #header-ribbon {
    font-size: 11px;
  }
}
