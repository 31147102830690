.paragraphs-item-fullwidth-cta {
  @media only screen and (max-width: 768px) {
    background-image: none !important;

    .cta-wrapper {
      background-color: #fff;
    }
  }

  @media only screen and (min-width: 769px) {
    background-color: #171717;
    background-size: cover !important;
    background-repeat: none;
    background-position: center;

    &.not-visible {
      opacity: 0;
    }
    transition: opacity 1.5s ease-in-out;
    
    .cta-image {
      background: none !important;
    }

    > div {
      min-height: 560px;
    }

    .cta-wrapper {
      position: relative;
    }

    .cta-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: auto;
      padding: 35px 50px 50px;
    }
  }

  @media only screen and (min-width: 1200px) {
    > div {
      min-height: 780px;
    }

    .cta-content {
      padding: 40px 60px 70px;
    }
  }
}

@media only screen and (min-width: 769px) {
  .even > .paragraphs-item-fullwidth-cta {
    &.not-visible {
      .cta-content {
        transform: translate(-150%, -50%);
      }
    }
  }

  .odd > .paragraphs-item-fullwidth-cta {
    &.not-visible {
      .cta-content {
        transform: translate(50%, -50%);
      }
    }
  }
}
