.paragraphs-item-multi-icon-cta,
.paragraphs-item-fullwidth-cta,
.paragraphs-item-two-column-cta {
  color: #000;
  font-size: 14px;
  font-weight: 300;
  line-height: 26px;
  font-family: sans-serif !important;
  background-color: #fafafa;

  &.not-visible {
    .cta-image,
    .cta-content {
      opacity: 0;
    }
  }

  .cta-content,
  .cta-image {
    transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
  }

  small {
    color: #989898;
    font-size: 10px;
    line-height: 16px;
  }

  .field-name-field-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .field-name-field-subtitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 10px;
  }

  .field-name-field-cta a {
    font-weight: 700;
    color: #fff;
    padding: 15px 40px;
    background-color: #2acc71;
    border-radius: 28px;
    display: inline;
    font-size: 14px;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #26B866;
    }
  }
}

.paragraphs-item-fullwidth-cta,
.paragraphs-item-two-column-cta  {
  .cta-content {
    background-color: #fff;
    z-index: 1;
    position: relative;
    padding: 50px 35px 80px;
  }
  
  .cta-image {
    min-height: 375px;
    color: #fff;
    background-color: #171717;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
  }

  .field-name-field-content {
    margin-bottom: 36px;
  }
}

.even > .paragraphs-item-two-column-cta,
.even > .paragraphs-item-fullwidth-cta {
  &.not-visible {
    .cta-content {
      transform: translateX(-100%);
    }
  }
}

.odd > .paragraphs-item-two-column-cta,
.odd > .paragraphs-item-fullwidth-cta {
  &.not-visible {
    .cta-content {
      transform: translateX(100%);
    }
  }
}

@media only screen and (min-width: 769px) {
  .paragraphs-item-multi-icon-cta,
  .paragraphs-item-fullwidth-cta,
  .paragraphs-item-two-column-cta {
    .field-name-field-title {
      margin-bottom: 32px;
      font-size: 36px;
      line-height: 46px;
    }

    .field-name-field-subtitle {
      line-height: 36px;
      font-size: 30px;
      margin-bottom: 20px;
    }
  }

  .paragraphs-item-two-column-cta,
  .paragraphs-item-fullwidth-cta {
    display: flex;

    > div {
      flex: 1 0 50%;
      max-width: 50%;
    }

    .cta-content {
      padding: 60px;
      max-width: 700px;
      margin: auto;
    }
  
    .field-name-field-content {
      margin-bottom: 50px;
    }
  }

  .even {
    > .paragraphs-item-two-column-cta,
    > .paragraphs-item-fullwidth-cta {
      .cta-image {
        order: 1;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .paragraphs-item-multi-icon-cta,
  .paragraphs-item-fullwidth-cta,
  .paragraphs-item-two-column-cta {
    font-size: 16px;
    line-height: 28px;

    small {
      font-size: 12px;
      line-height: 18px;
    }

    .field-name-field-title {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 42px;
    }
  
    .field-name-field-cta a {
      padding: 16px 50px;
      border-radius: 30px;
      font-size: 16px;
    }
  }
}
