/* -------------------- base/type -------------------- */


$sans: 'Gotham SSm A', 'Gotham SSm B', 'Helvetica Neue', helvetica, arial, sans-serif;
$serif: 'Roboto Slab', georgia, serif;


$mos-fonts: (

	'sans': $sans,
	'serif': $serif

);


@function mos-font-family($key: 'sans') {
	@return map-get($mos-fonts, $key);
}


@mixin mos-font($font: 'sans', $weight: 400) {
	font-family: mos-font-family($font);
	font-weight: $weight;
	font-style: normal;
}



// base fonts styles

body {
	@include mos-font('serif');
	color: #262626;
	background-color: #e8e8e8;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

a,
a:visited {
	color: mos-color('green');
	text-decoration: none;

	&:hover {
		color: darken(mos-color('green'), 10%);
		text-decoration: none;
	}
}


// headings

h1,
h2,
h3,
h4,
h5 {
	@include mos-font('sans', 800);
	text-transform: uppercase;
}

h1 {
	font-size: 60px;
}

h2 {
	font-size: 32px;
}

h3 {
	font-size: 24px;
}

h4 {
	font-size: 16px;
}

h5 {
	@include mos-font('serif', 700);
	font-size: 16px;
}


@include tablet {

	h1 {
		font-size: 60px;
	}

	h2 {
		font-size: 32px;
	}

	h3 {
		font-size: 24px;
	}

	h4, h5 {
		font-size: 16px;
	}

}


@include mobile {

	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 16px;
	}

	h4, h5 {
		font-size: 14px;
	}

}



p {
	font-size: 16px;
}


@include tablet {

	p {}

}


@include mobile {

	p {}

}
