// global page overrides
.node-type-news #page-wrapper,
.node-type-news #header:after {
  background: #fff;
}

.node-type-news {

  #main-wrapper:after {
    content: none;
  }

  .field-name-field-paragraphs .field-item:first-child .entity-paragraphs-item {
    padding-top: 0;
  }

  .field-name-field-paragraphs .field-item:last-child .entity-paragraphs-item {
    padding-bottom: 0;
  }

  #scroll-icon {
    display: none;
  }
  #above-footer {
    @include angled-section(mos-color('green'), auto, -1.75deg);
    &:after {
      top: -60px;
    }
    .above-footer-wrap {
      position: relative;
      z-index: 1;
    }
  }
}

@media screen and (max-width: $tablet + 1) {
  .node-type-news {
    .node-news {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

// news page banner
.news-page-banner {
  color: #fff;
  position: relative;
  min-height: 440px;
  text-align: center;

  .news-page-header {
    margin: 0 auto;
    padding: 50px 0;
    max-width: 855px;

    h1 {
      font-size: 48px;
    }
  }

  .news-page-subheader {
    padding-top: 60px;
    span {
      @include mos-font('sans', 800);
      font-size: 18px;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: $tablet + 1) {
  .news-page-banner {
    .news-page-header {
      padding-left: 40px;
      padding-right: 40px;
    }
    .news-page-header h1 {
      font-size: 28px;
    }
  }
}

// global news page paragraphs
.paragraphs-item--news-body,
.paragraphs-item-news-slideshow,
.paragraphs-item-news-quote,
.paragraphs-item-news-divider {
  padding: 65px 0 55px;
  max-width: 855px;
}

@media screen and (max-width: $tablet + 1) {
  .paragraphs-item--news-body,
  .paragraphs-item-news-slideshow,
  .paragraphs-item-news-quote,
  .paragraphs-item-news-divider {
    padding: 40px 0;
  }
}

// news page paragraph -- body
.paragraphs-item--news-body {
  @include mos-font('serif', 400);
  color: #626262;

  h1, h2, h3, h4, h5, h6 {
    @include mos-font('serif', 700);
    color: #262626;
    text-transform: none;
  }

  h1 {
    @include mos-font('sans', 800);
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 6px;
  }

  h4, h5, h6, p {
    font-size: 16px;
    margin-bottom: 6px;
  }

  p {
    line-height: 24px;
    margin-bottom: 28px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: $tablet + 1) {
  .paragraphs-item--news-body {
    width: 100%;
    h2 {
      font-size: 18px;
      line-height: 24px;
    }
    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
}

// news page paragraph -- slideshow
.paragraphs-item-news-slideshow {
  max-width: 920px;
  width: 100%;

  .field-name-field-news-slideshow-image {
    .field-item {
      display: none;
    }
    .field-item:first-child {
      display: block;
    }
    .owl-dots {
      position: absolute;
      bottom: 40px;
      margin: 0 auto;
      width: 100%;

      .owl-dot {
        outline: 0;
      }

      .owl-dot.active span {
        background: #fefefe;
        opacity: 1;
      }

      span {
        height: 8px;
        width: 8px;
        background: #fefefe;
        opacity: 0.35;
      }
    }

  }

  .field-name-field-news-slideshow-caption {
    color: #a8a8a8;
    font-size: 12px;
    margin-top: 28px;
    text-align: center;
  }
}

@media screen and (max-width: $tablet + 1) {
  .paragraphs-item-news-slideshow {
    > .content.row {
      margin-left: -30px;
      margin-right: -30px;      
    }
    .col-sm-12 {
      padding: 0;
    }
    .owl-carousel {
      width: 100vw;
    }
    .owl-carousel .owl-item {
      // width: 100vw !important;
    }
    .owl-carousel .owl-item img {
      height: auto;
    }
  }
}

// news page paragraph -- quote
.paragraphs-item-news-quote {
  blockquote {
    padding: 0;
    margin: 0 auto;
    border: 0;
  }

  .field-name-field-news-quote {
    @include mos-font('sans', 800);
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    text-transform: uppercase;
  }

  .field-name-field-news-quote-author {
    color: #888;
    @include mos-font('serif', 700);
    font-size: 14px;
    margin-top: 26px;
    text-align: center;
  }
}

@media screen and (max-width: $tablet + 1) {
  .paragraphs-item-news-quote {
    width: 100%;
    .field-name-field-news-quote {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

// news page paragraph -- divider
.paragraphs-item-news-divider {
  max-width: 100%;
  width: 100%;

  hr {
    background-color: #c8c8c8;
    width: 100%;
    height: 1px;
    margin: 20px auto;
    display: none;
  }

  &:after {
    background: #c8c8c8;
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    transform: skewY(-1.75deg);
    overflow-x: hidden;
  }
}

@media screen and (max-width: $tablet + 1) {
  .field-name-field-paragraphs .paragraphs-item-news-divider {
    margin-left: -30px;
  }
}

// news pager
.news-pager {
  display: flex;
  min-height: 540px;
  .pager-item {
    display: none;
  }

  > div {
    flex: 0 33.3333%;
    background-size: cover;
    background-position: center center;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #fff;
      font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", helvetica, arial, sans-serif;
      font-size: 24px;
      font-weight: 800;
      text-transform: uppercase;
      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }
  .pager-index {
    background-image: url(../images/banner-news.jpg);
  }
  @include angled-section(#fff, auto, -1.75deg, 1);
  &:after {
    top: -260px;
  }
}

@media screen and (max-width: $tablet + 1) {
  .news-pager {
    flex-wrap: wrap;
    min-height: 465px;
    .pager-prev,
    .pager-next,
    .pager-index {
      transform: skewY(-1.75deg);
      a {
        font-size: 20px;
        transform: skewY(1.75deg);
      }
    }
    .pager-prev,
    .pager-next {
      position: relative;
      top: 35px;
      order: 1;
      flex: 0 50%;
      z-index: 1;
    }
    .pager-index {
      position: relative;
      margin-top: 25px;
      order: 2;
      flex: 0 100%;
      z-index: 2;
    }
  }
}

// Call to action block
#call-to-action-news {
  min-height: 360px;
  display: flex;
  justify-content: center;
  .content {
    position: relative;
    text-align: center;
    z-index: 2;
    h2 {
      margin-top: 80px;
      font-size: 42px;
      font-weight: 800;
    }
    p {
      font-size: 16px;
      font-weight: 700;
    }
    .border-button {
      margin-top: 30px;
      border-color: #262626;
      color: #262626;
    }
  }
}
@media screen and (max-width: $tablet) {
  #call-to-action-news {
    min-height: 250px;
    .content {
      h2 {
        margin-top: 40px;
        font-size: 28px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
