/* -------------------- layout/menu -------------------- */

a:focus {outline:none;}
a::-moz-focus-inner {border:0;}

#svg-menu-wrap {
	background: transparent;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 0%;
	overflow: hidden;
	z-index: 200;
}


#svg-menu {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 200;
}


#offscreen-menu {
	height: 100%;
	margin-top: 114px;
	margin-left: 60px;
	position: relative;
	z-index: 300;
}

@media (max-height: 768px) and (min-width: 767px) {
  #offscreen-menu {
    margin-top: 33px;
  }
}

.offscreen-menu-inner,
.offscreen-menu-inner > .row,
.offscreen-menu-inner > .row > div,
.offscreen-menu-inner > .row > div > div {
	position: relative;
	height: 100%;
}

.admin-menu #offscreen-menu {
	margin-top: 144px;
}

@include screen(769px, 1023px) {}

@include max-screen(783px) {
	#offscreen-menu {
		margin-top: 50px;
		margin-left: 30px;
	}

	.admin-menu #offscreen-menu {
		margin-top: 80px;
	}
}

@include mobile {
	#offscreen-menu {
		// margin-top: 30px;
		margin-top: 44px;
	}
}


#offscreen-menu-overlay {
	// background: mos-color('black');
	background: transparent;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	transition: opacity 600ms;

	&.active {
		opacity: 0.4;
	}
}



// about us blurb

.menu-about-us-blurb {
	color: mos-color('gray');
	display: none;
	float: left;
	line-height: 26px;
	margin-top: 8px;
	width: 180px;

	a,
	a:visited {
		color: mos-color('gray');

		&:hover {
			color: darken(mos-color('white'), 10%);
		}
	}

	img {
		margin-bottom: 20px;
	}

	p {
		font-size: 14px;
		font-weight: 700;
		line-height: 24px;
		margin-bottom: 14px;
	}

	p:first-child {
		margin-bottom: 10px;
	}
}

@include tablet {}

@include max-screen(783px) {
	.menu-about-us-blurb  {
		display: none !important;
	}
}



// mos main menu

.mos-main-menu {
	@include mos-font('sans', 800);
	display: none;
	float: left;
	font-size: 36px;
	margin-left: 60px;
	margin-top: -4px;
	text-transform: uppercase;

	a {
		color: mos-color('white');
		text-decoration: none;

		&:hover {
			color: darken(mos-color('white'), 20%);
			text-decoration: none;
		}

		&.active {
			color: mos-color('white');
			text-decoration: none;
		}
		&.svg-applied{
			display: inline-block;
			text-indent: -9999px;
			svg{
				float:left;
				fill: mos-color('white');
			}
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		padding-right: 50px;
	}

	li.leaf {
		list-style: none;
		list-style-image: none;
		list-style-type: none;
		margin: 0;
		padding: 4px 0 5px 0;
		text-align: left;
	}
}

@include screen(769px, 1024px) {
	.mos-main-menu {
		margin-left: 60px;
	}
}


// mobile
@include max-screen(783px) {
	.mos-main-menu {
		font-size: 24px;
		margin-top: 40px;
		margin-left: 70px;

		ul {
			// padding-right: 20px;
		}

		li.leaf {
			padding-bottom: 10px;
			padding-top: 0;
			text-align: left;
		}
		a{
			&.svg-applied{
				text-indent: 0;
				svg{
					display: none;
				}
			}
		}
	}
}

// mos sub menu
.mos-sub-menu {
	margin-top: 44px;
	margin-left: 70px;
	display: none;
	float: left;
	clear: left;
	&:before {
		content: "";
		height: 2px;
		width: 35px;
		background: #888;
		display: block;
		position: relative;
		top: -25px;
		left: 0;
	}
	ul {
		padding-left: 0;
		li {
			list-style-type: none;
			list-style-image: none;
			padding: 0;
			margin: 0;
			margin-bottom: 8px;
			a {
				color: #ffffff;
				@include mos-font('sans', 500);
				font-size: 18px;
				font-weight: 500;

        &:hover {
          color: #fff;
        }
			}
		}
	}
}

@include max-screen(783px) {
	.mos-sub-menu {
		margin-left: 70px;
	}
}


// circa block

.mos-circa {
	display: none;
	position: fixed;
	bottom: 47px;

	p {
		color: #989898;
		font-size: 10px;
		font-weight: 700;
	}
}



@include max-screen(783px) {
	.mos-circa {
		display: none !important;
	}
}


.circa-mobile {
	font-size: 14px;

	a,
	a:visited {
		color: #989898 !important;
	}

}

@include screen(769px, 1023px) {}

@include min-screen(784px) {
	.circa-mobile {
		display: none !important;
	}
}

@include max-screen(783px) {
	.circa-mobile {
		display: none;
		padding-top: 0px;
		padding-left: 0;
		position: absolute;
		left: 10px;
		top: 350px;
		text-align: right;

		p {
			color: mos-color('gray');
			font-size: 12px;

			&:last-child {
				font-size: 10px;
				opacity: 0.5;
			}
		}
	}
}


// MENU OVERLAY
.menu-overlay-click-area {
	position: fixed;
	width: 100%;
	height: 3000px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 600;
	// background: rgba(0,0,0,0.3);
	transform: translateX(647px) rotate(7.5deg);
	display: none;
	opacity: 0;
}

.mos-menu-open {
	.menu-overlay-click-area {
		opacity: 1;
		display: block;
	}
}

@include screen(769px, 1023px) {
	.menu-overlay-click-area {
		transform: translateX(431px) rotate(7.5deg);
	}
}

@include max-screen(768px) {
	.menu-overlay-click-area {
		transform: translateX(114px) rotate(4.8deg);
	}
}

.menu-overlay {
	display: none;
	opacity: 0;
	background: rgba(0,0,0,0.3);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 150;
	transition: opacity 600ms ease-in-out;
}

.mos-menu-open {
	.menu-overlay {
		display: block;
		opacity: 1;
	}
}
