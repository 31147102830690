/* -------------------- layout/banner -------------------- */

// home

.home-page-banner-image {
  height: calc(100vh - 63px);
  min-height: 622px;
  max-height: 950px;
  position: relative;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  color: #e1e1e1;
  background-position: 0 0 !important;

  h1 {
    font-size: 50px;
  }

  .mos-logomark-alt {
    padding-bottom: 40px;
  }

  .green {
    margin: 18px 0 35px 0;
    font-size: 32px;
    font-weight: bold;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .border-button {
    cursor: pointer;
    border-color: #fff;
    color: #fff;

    &:hover {
      color: #171717;
      background: #e8e8e8;
      border-color: #e8e8e8;
    }

    &:first-of-type {
      margin-right: 45px;
    }
  }

  video {
    // position:relative;
    position: absolute;
    // top: 49%;
    // left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    // transform: translateX(-50%) translateY(-50%);
    background-size: cover;
  }

  #acquia-foundation {
    position: absolute;
    bottom: 110px;
    right: 48px;
    max-width: 255px;
  }
}

@include tablet {}

@include mobile {
  .home-page-banner-image {
    min-height: 0;

    h1 {
      font-size: 40px;
    }

    .green {
      font-size: 16px;
    }

    .mos-logomark-alt {
      padding-bottom: 20px;
      width: 80px;
    }

    #acquia-foundation {
      bottom: 70px;
      right: 30px;
      width: 21px;
    }
  }
}

@media screen and (max-width:499px) {
  .home-page-banner-image {
    h1 {
      font-size: 32px;
    }

    .border-button {
      display: block;
      max-width: 300px;
      margin: 20px auto !important;

      &:first-of-type {
        margin-right: inherit;
      }
    }
  }
}

.home-page-banner-message {
  color: #f8f8f8;
  position: absolute;
  text-align: center;
  // top: 48%;
  top: 40%;
  transform: translate(0%, -50%);
  width: 100%;
}

@media screen and (max-width:424px) {
  .home-page-banner-message {
    top: 40%;
  }

  .home-page-banner-image {
    h1 {
      font-size: 24px;
    }
  }
}

@include tablet {}

@include mobile {}



.scroll-animation {
  background: url(/sites/all/themes/motionstrand/assets/images/down-arrow-frames-9.png) left center;
  width: 4px;
  height: 60px;
  animation: play 2s steps(60) infinite;
  margin: 0 auto;
}

@keyframes play {
  100% {
    background-position: -240px;
  }
}



// interior

.interior-page-banner {
  color: #f8f8f8;
  height: calc(100vh - 63px);
  min-height: 622px;
  max-height: 950px;
  position: relative;
  width: 100%;
  z-index: 0;
  overflow: hidden;

  video {
    position: relative;
    top: 49%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
  }
}

.interior-page-banner-title {
  position: absolute;
  text-align: center;
  top: 54%;
  transform: translate(0, -50%);
  width: 100%;
  opacity: 0;
  transition: opacity 600ms;

  h1 {
    margin: 0;
    line-height: 1em;
  }

  .banner-subtitle {
    font-weight: bold;
    margin-top: 20px;
    font-size: 16px;
    display: block;
  }

  .banner-copy {
    max-width: 600px;
    margin: 20px auto;
    padding: 0 15px;
    font-family: $sans;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;

    p {
      margin-bottom: 20px;
    }

    a {
      color: inherit;
      font-weight: 700;
    }
  }
}

.mobile .interior-page-banner-title,
.parachute-active .interior-page-banner-title {
  opacity: 1;
}

@include max-screen(768px) {
  .interior-page-banner {
    height: calc(100vh - 50px);
    min-height: 0;
  }

  .interior-page-banner-title {
    top: 50%;
  }
}

// News
#block-block-21,
#taxonomy-term-page-banner {
  background: url(/sites/all/themes/motionstrand/assets/images/banner-news.jpg) #777 center center no-repeat;
  background-size: cover;
  color: #f8f8f8;
  height: calc(100vh - 63px);
  min-height: 622px;
  max-height: 950px;
  position: relative;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
    line-height: 1em;
  }

  .banner-subtitle {
    font-weight: bold;
    margin-top: 20px;
    font-size: 16px;
    display: block;
  }
}


// thank you banner
.view-thank-you-interior-page-banner {
  height: 600px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;

  .thank-you-alt-logo {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  // .views-field-field-banner-image {
  // 	img {
  // 		width: 100%;
  // 	}
  // }
}

@include min-screen(1200px) {
  .views-field-field-banner-image {
    img {
      width: 100%;
    }
  }
}



// contact page

.page-node-56 #banner {
  // display: none;
  max-height: 780px;
}

#banner-map {
  height: 600px;
  width: 100%;
}

@include tablet {}

@include mobile {
  #banner-map {
    height: 540px;
  }
}



#map {
  height: 100%;
  width: 100%;
}

.gm-style {
  p {
    @include mos-font('serif', 700);
    color: mos-color('gray');
    font-size: 14px;
    line-height: 1.2em;
    text-align: center;
  }

  h4 {
    line-height: 1.2em;
    margin: 0 0 14px 0;
    text-align: center;
  }
}

// RSVP page
.interior-page-banner.nid-291 {
  .banner-copy {
    max-width: 945px;
    padding: 0;
  }

  .interior-page-banner-title {
    top: 35%
  }
}
