// Colors

$mos-colors: (
	
	'green': #26ac62,
	'red': #ee352e,
	'blue': #3bb5d0,
	
	'black': #171717,
	'white': #e8e8e8,
	'gray': #989898
	
);


@function mos-color($key: 'primary') {
	@return map-get($mos-colors, $key);
}