/* -------------------- components/form -------------------- */

form {

	input,
	textarea {
		background: #dedede;
		border: 2px solid #dedede;
		//border-bottom: 1px solid #b8b8b8;
		color: mos-color('black');
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 20px;
		outline: none;
		padding: 8px 0;
		width: 100%;
		padding: 13px 25px 13px 25px;
	}
	label {
		font-size: 16px;
	  font-weight: normal;
	  color: #888;
	  padding: 20px 25px 0 25px;
	  cursor:text;
	}

	&:-ms-input-placeholder {
		color: mos-color('black');
		font-size: 24px;
	}

	&::-moz-placeholder {
		color: mos-color('black');
		font-size: 24px;
	}

	&::-webkit-input-placeholder {
		color: mos-color('black');
		font-size: 24px;
	}

	&:focus {
		&:-ms-input-placeholder {
			color: #aaa;
		}

		&::-moz-placeholder {
			color: #aaa;
		}

		&::-webkit-input-placeholder {
			color: #aaa;
		}
	}

	.button-primary {
		border: 1px solid #b8b8b8;
		margin-top: 20px;
		padding: 10px 20px;
		width: auto;
	}

}

@include tablet {}

@include mobile {
	form {

		input,
		textarea {
			font-size: 18px;
			line-height: 24px;
			//padding: 4px 0;
		}

		.button-primary {
			width: 100%;
		}

	}
}

#webform-client-form-55 {
	padding: 0 80px;
}

@include tablet {}

@include mobile {
	#webform-client-form-55 {
		padding: 0 15px;
	}
}


.page-node-56.not-front .node {
	padding-bottom: 40px;
	text-align: center;

	p {
		color: mos-color('gray');
		font-size: 25px;
		line-height: 1.25em;
		margin-top: 20px;
	}
}

@include tablet {}

@include mobile {
	.page-node-56.not-front .node {
		p {
			font-size: 16px;
		}
	}
}
