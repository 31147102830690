.node-type-news {
  .user-picture {
    display: inline-block;
    float: left;
    // margin-right: 10px;
    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
  }
  .author-info {
    max-width: 855px;
    margin-left: auto;
    margin-right: auto;
    color: #626262;
    .author {
      color: #262626;
      font-size: 16px;
    }
  }
}
