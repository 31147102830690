/* -------------------- layout/featured work -------------------- */


// FEATURED WORK - HEADER
#featured-work-header {
	padding: 64px 0 136px;
	text-align: center;

	h3 {
		@include mos-font('serif', 400);
		color: mos-color('gray');
		margin-top: 20px;
		text-transform: none;
	}
}

@include tablet {}

@include mobile {
	#featured-work-header {
		padding: 54px 0 50px;
		
		h1 {
			margin-bottom: 8px;
		}
		h3 {
			margin-top: 8px;
		}
	}
}


// FEATURED WORK
.featured-work {
	padding-top: 280px;
	padding-bottom: 380px;
	position: relative;
	
	&:first-child {
		padding-top: 0;
	}
	
	.lrg {
		overflow: hidden;
		position: relative;
		
		.work-desc {
			color: mos-color('white');
			padding: 0 50px;
			position: absolute;
			text-align: center;
			top: 42%;
			width: 100%;
			@include easeOutQuint(top, 800ms);
			
			.desc {
				opacity: 0;
				@include easeOutQuint(opacity, 800ms);
			}
			
			&.active {
				.desc {
					opacity: 1;
				}
			}
		}
	}
	
	.rec,
	.sm,
	.sm-logo {
		position: relative;
	}
}


// FEATURED WORK - WRAPPER
.featured-work__wrapper {
	position: relative;
}


// FEATURED WORK - COLLAGE
.featured-work__collage {
	position: relative;
	z-index: 1;
}
@include tablet {}
@include mobile {
	.featured-work__collage {
		padding-top: 40px;
	}
}

.featured-work__collage-wrapper {
	margin: 0 auto;
	max-width: 1380px;
	padding: 0 120px;
}

@include mobile {
	.featured-work__collage-wrapper {
		padding: 0 30px;
	}
}

///
.parachute-active .featured-work__collage {
	top: 100px;
}

#featured-work-1 .featured-work__collage {
	// padding-top: 0;
}

////
.parachute-active {
	.lrg,
	.rec,
	.sm,
	.sm-logo {
		opacity: 0;
		top: 100px;
		transform: translateY(0);
		transition: transform 600ms ease-in-out, opacity 600ms ease-in-out;
	}
	
	.featured-work.in-view {
		.lrg,
		.rec,
		.sm,
		.sm-logo {
			opacity: 1;
			transform: translateY(-100px);
		}
	}
	
	#featured-work-1.in-view {
		.lrg {}
		
		.rec {
			@include triggerDelay(1);
		}
		
		.sm {
			@include triggerDelay(2);
		}
		
		.sm-logo {
			@include triggerDelay(3);
		}
	}
	
	#featured-work-2.in-view {
		.sm {}

		.lrg {
			@include triggerDelay(1);
		}
		
		.rec {
			@include triggerDelay(2);
		}
		
		.sm-logo {
			@include triggerDelay(3);
		}
	}
	
	#featured-work-3.in-view {
		.rec {}
		
		.lrg {
			@include triggerDelay(1);
		}
		
		.sm {
			@include triggerDelay(2);
		}
		
		.sm-logo {
			@include triggerDelay(3);
		}	
	}
}

//// 
@include tablet {
	.featured-work {
		padding-bottom: 400px;
	}
}

////
@include mobile {
	.featured-work {
		padding-bottom: 220px;
		
		&:last-child {
			padding-bottom: 280px;
		}
		
		.lrg {
			top: 0;
		}
		
		.lrg.mobile-active {
			.title {
				display: none;
			}
			.desc {
				display: block;
			}
		}
		
		.lrg .work-desc {
			height: 100%;
			position: absolute;
			top: 0;
			transform: none;
			width: 100%;
			
			.title {
				position: absolute;
				left: 0;
				text-align: center;
				top: 49%;
				width: 100%;
				@include easeOutQuint(opacity, 800ms);
			}
			
			.desc {
				padding: 0 20px;
				position: absolute;
				left: 0;
				text-align: center;
				top: 50%;
				width: 100%;
				@include easeOutQuint(opacity, 800ms);
				 
				p {
					font-size: 12px;
				}
				
				&.active {
					opacity: 1;
				}
			}
			
			&.active {
				.title {
					opacity: 0;
				}
				
				.desc {
					opacity: 1;
				}
			}
		}
	}
	.parachute-active .featured-work {
		.lrg {
			top: 100px;
		}
	}
}


// FEATURED ALTERNATE DESCRIPTION 
.featured-work__alt-description {
	color: mos-color('white');
	margin: 80px 0 60px;
	position: absolute;
	text-align: center;
	top: 560px;
	width: 100%;
	z-index: 2;
	
	h3,
	h4 {
		@include mos-font('serif', 700);
		text-transform: none;
	}
	
	a,
	a:visited {
		color: mos-color('white');
		text-decoration: none;
		
		&:hover {
			color: darken(mos-color('white'), 15%);
		}
	}
}
@include tablet {}
@include mobile {
	.featured-work__alt-description {
		padding: 0 15px;
		margin: 40px 0 60px;
	}
}

.parachute-active .featured-work__alt-description {
	top: 660px;
}


// FEATURED WORK - BG
.featured-work__background {
	height: 700px;
	position: absolute;
	top: 50%;
	width: 100%;
}
@include tablet {}
@include mobile {
	.featured-work__background {
		height: 500px;
	}
}


// #1
#featured-work-1 {
	.col-md-6 {
		float: left;
		padding: 0;
	}
	
	.lrg,
	.rec,
	.rec-alt {
		width: 100%;
	}
	
	.sm,
	.sm-logo {
		float: left;
		width: 50%;
	}
	
	img {
		width: 100%;
	}
	
	.rec-tablet {
		display: none;
	}
}

#featured-work-1 {
	@include angled-section(mos-color('white'), -260px, 1.75deg);
}

@include screen(768px, 991px) {
	#featured-work-1 {
		.sm,
		.sm-logo {
			width: 100%;
		}
	}
}

@include tablet {
	#featured-work-1 {
		.col-sm-8,
		.col-sm-4 {
			float: left;
			padding: 0;
		}
		
		.col-sm-12 {
			padding: 0;
		}
		
		.sm,
		.sm-logo {
			// width: 100%;
		}
	}	
}

// fix for bootstrap's grid breakpoint
@include screen(992px, 1024px) {
	#featured-work-1 {
		.sm,
		.sm-logo {
			width: 50%;
		}
	}
}

@include mobile {
	#featured-work-1 {
		@include angled-section(mos-color('white'), -280px, 1.75deg);
		// padding-bottom: 280px;
	}
}


// #2
#featured-work-2 {
	.col-md-3,
	.col-md-6 {
		padding: 0;
	}
	
	img {
		width: 100%;
	}
	
	.sm-alt {
		padding: 0;
		
		.sm,
		.sm-logo {
			float: left;
			width: 50%;
		}
	}
}

#featured-work-2 {
	@include angled-section(mos-color('white'), -260px, -1.75deg);
}


@include tablet {
}

@include mobile {
	
	#featured-work-2 {
		@include angled-section(mos-color('white'), -280px, -1.75deg);
		padding-top: 70px;
	}
}


// #3
#featured-work-3 {	
	.col-md-6 {
		float: right;
		padding: 0;
	}
	
	.lrg,
	.rec,
	.rec-alt {
		width: 100%;
	}
	
	.sm,
	.sm-logo {
		float: left;
		width: 50%;
	}
	
	img {
		width: 100%;
	}
	
	.rec-tablet {
		// display: none;
	}
}

#featured-work-3 {
	@include angled-section(mos-color('white'), -260px, 1.75deg);
}


@include tablet {
	#featured-work-3 {
		
		.col-sm-8,
		.col-sm-4 {
			float: right;
		}
		
		.col-sm-12 {
			padding: 0;
		}
		
		.sm,
		.sm-logo {
			width: 100%
		}
	}
}

// fix for bootstrap's grid breakpoint
@include screen(992px, 1024px) {
	#featured-work-3 {
		.sm,
		.sm-logo {
			width: 50%;
		}
	}
}

@include mobile {
	#featured-work-3 {
		@include angled-section(mos-color('white'), -220px, 1.75deg);
		padding-top: 70px;
	}
}


@include max-screen(991px) {
	.featured-work__background {
		height: 840px;
	}
}

@include max-screen(767px) {
	.featured-work__background {
		height: 680px;
	}
}