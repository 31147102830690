#block-views-our-work-block,
#block-views-our-work-archive{
  padding-bottom:250px;
  > h2{
    position:relative;
    padding-top: 60px;
    padding-bottom: 90px;
    text-align: center;
  }
  .content{
    transform: translateY(-10px);
  }
  .view{
    position:relative;
    transform: translateY(-350px);
    margin:0 auto 0;
    width: 100%;
    max-width: 1380px;
    padding: 0 120px;    
  }
  .view-content{
    position:relative;
    background: #171717;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    overflow: hidden;
    transform: skewY(-1.75deg);
    z-index: 0;
  }

  .text-blocks,
  .views-row{
    .copy{}

  }


  //.text-blocks is used only for desktop
  //This is to fix the blurry text
  .text-blocks{
    position:absolute;
    top:0;
    max-width: 1020px;
    margin:0 auto;
    z-index: 1;
    .copy{
      width:33%;
      height:100%;
      text-align: center;
      cursor:pointer;
      .copy-inner {
        opacity: 0;
        position: relative;
        top:50%;
        transform: translateY(-50%);
        transition: opacity 300ms ease;
        transition-delay: 200ms;
        padding:0 18%;
        color:#f8f8f8;
      }
      h2{
        margin-bottom: 60px;
        line-height: 1em;
        a{
          color:#f8f8f8;
        }
      }
      h4 {
        font-family: $serif;
        font-size: 14px;
        font-weight: 600;
        text-transform: none;
      }
      &:before{
        content: '';
        position: absolute;
        top:-2.5%;
        left:0;
        height:100%;
        width:100%;
        opacity:0;
        background:rgb(8, 8, 8);
        transform: skewY(-1.75deg);
        transition: opacity 300ms ease;
      }
      @include max-screen(990px){
        &:before{
          top:-1.35%;
        }
      }
      &:hover{
        &:before{
          opacity:0.62;
        }
        .copy-inner{
          opacity: 1;
        }
      }
    }
  }

  .views-row {
    position: relative;
    height: 360px;
    padding:0;
    overflow: hidden;
    background:#e8e8e8;
    backface-visibility: hidden;
    transform-style:preserve-3d;
    outline:1px solid transparent;
    .image {
      position: absolute;
      top: -10%;
      left: -10%;
      height: 120%;
      width: 120%;
      backface-visibility: hidden;
      .image-inner {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        img {
          width: 100%;
          height: auto !important;
          backface-visibility: hidden;
          transform: translateZ(0);
        }
      }
    }
    .copy {
      position: absolute;
      top:-3%;
      left:-3%;
      padding: 0;
      height: 106%;
      width: 106%;
      background: #000;
      background: rgba(8,8,8,0.62);
      text-align: center;
      color: #fff;
      opacity:0;
      transition: opacity 300ms ease;
      .copy-inner {
        opacity: 0;
        position: relative;
        max-width: 80%;
        margin:0 auto;
        top:47%;
        transform: skewY(1.75deg) translateY(-50%);
        transition: opacity 300ms ease;
        transition-delay: 200ms;
        cursor:default;
      }
      h2{
        line-height: 1.25em;
      	a{
      		color:#f8f8f8;
      	}
      }
      h4 {
      	font-family: $serif;
      	font-size: 14px;
      	font-weight: 600;
        text-transform: none;
      }
    }
    .link-overlay{
      position:absolute;
      top:0;
      left:0;
      display: block;
      width:100%;
      height:100%;
    }
    &:hover .copy, &:active .copy, &:focus .copy {
      opacity:1;
      .copy-inner {
        opacity: 1;
      }
    }
    &:last-of-type {
      a {
        width:100%;
        height:100%;
      }
      .copy-inner {
        cursor:pointer;
        .name:after {
          content:'?';
        }
      }
    }
  }
}

.parachute-active{
  #block-views-our-work-block,
  #block-views-our-work-archive{
    > h2{
      top:40px;
    }
  }
}

#block-views-our-work-block{
  position:relative;
  .view{
    transition: transform 1000ms ease-in-out;
  }
}

.move-work{
  #block-views-our-work-block .view{
    transform: translateY(0);
  }
}

#block-views-our-work-archive{
  @include angled-section(#171717);
  @include angled-top-section();
  background-color:#e8e8e8;
  .view-footer{
    position:relative;
    top:23px;
    clear:both;
    padding:230px 0;
    background:#171717;
    h1{
      text-align: center;
      cursor:pointer;
      a{
        color: mos-color('white');
        &:after {
          margin:auto;
          position:relative;
          top:30px;
          content:'';
          display: block;
          width:26px;
          height:3px;
          background:mos-color('white');
        }
      }
    }
  }
}

.page-node-106{  
  background:#f8f8f8;
  .interior-page-banner-title{
    color: #f8f8f8;
  }
  #scroll-icon{
    bottom: 160px;
  }
  #page-wrapper{
    background:#f8f8f8;
  }
  #header{
    &:after{
      background:#f8f8f8;
    }
  }
  #main-wrapper{
    &:after{
      content:none;
    }
    #main{
      padding-top:0;
      padding-bottom:0;
    }
  }
}

@include mobile {
  #block-views-our-work-block,
  #block-views-our-work-archive{
    > h2{
      padding-top:20px;
      padding-bottom:10px;
    }

    .view{
      top:50px;
      padding:0 30px;
      transform: translateY(-200px);
    }
    .views-row{
      height: auto;
      margin-top:0;
      .image{
        top:0;
        .image-inner{
          height:100%;
          img{
            position:relative;
            left:50%;
            width:auto;
            height:calc(50vw + 5px) !important;
            transform: translateX(-50%);
          }
        }
      }
      .copy,
      .copy-inner{
        padding:0 !important;
      }
      .copy{
        background: rgba(8,8,8,0.25);
        h2{
          font-size: 18px;
        }
        h4{
          font-size: 12px;
        }
      }
    }
  }
  #block-views-our-work-block{
  }
  .page-node-106{
    #scroll-icon{
      bottom:80px;
    }
  }
}

.mobile{
  .text-blocks{
    display: none;
  }
}

/* IE10+ specific styles go here */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { 
  #block-views-our-work-block{
    .views-row{
      background: transparent;
    }    
    .view-content{
      transform: none;
    }
    .text-blocks{
      .copy{
        .copy-inner{
          transform: none;
          top: 30%;
        }
        &:before{
          transform: none;
          top: 0;
        }
      }
    } 
  } 
  #block-views-our-work-archive{
    .views-row{
      background: transparent;
    }    
    .view-content{
      transform: none;
    }
    .text-blocks{
      .copy{
        &:before{
          transform: none;
          top: 0;
        }
      }
    }
  }
}
/* Edge specific styles go here */
@supports (-ms-ime-align:auto) {
  #block-views-our-work-block{
    .views-row{
      background: transparent;
    }
    .view-content{
      transform: none;
    }
    .text-blocks{
      .copy{
        &:before{
          transform: none;
          top: 0;
        }
      }
    } 
  } 
  #block-views-our-work-archive{
    .views-row{
      background: transparent;
    }
    .view-content{
      transform: none;
    }
    .text-blocks{
      .copy{
        &:before{
          transform: none;
          top: 0;
        }
      }
    }
  }
}