.paragraphs-item-two-column-cta {
  .cta-wrapper {
    background-color: #fff;
  }

  @media only screen and (min-width: 1200px) {
    .cta-content {
      padding: 180px 120px;
    }

    > div {
      min-height: 500px;
    }
  }
}
